import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AuthFetcher from '../../fetchers/Auth.fetcher';
import { IResetPasswordPayload, ISendEmailPayload } from '../../interfaces/interfaces';
import UserModel from '../../models/user/User.model';
import ToastUtil from '../../utils/ToastUtils';
import UserStore from '../user/User.store';
import RegistrationDetails from '../../models/auth/RegistrationDetails.model';
import LoginCredentials from '../../models/auth/LoginCredentials.model';
import { CErrors } from '../../constants/constants';

class AuthStore {
    @observable
    accessToken: string = '';

    @observable
    isLoggedIn: boolean = false;

    @observable
    isLoading: boolean = false;

    private userStore: UserStore;

    constructor(userStore: UserStore) {
        makeObservable(this);
        makePersistable(this, {
            name: 'AuthStore',
            properties: ['accessToken', 'isLoggedIn'],
            storage: window.localStorage,
        });
        this.userStore = userStore;
    }

    @action
    setAccessToken = (accessToken: string) => (this.accessToken = accessToken);

    @action
    setIsLoggedIn = (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    registration = async (payload: RegistrationDetails) => {
        try {
            this.setIsLoading(true);
            await AuthFetcher.registration(payload);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    login = async (payload: LoginCredentials) => {
        try {
            this.setIsLoading(true);
            const { data }: { data: { access_token: string; user: UserModel } } = await AuthFetcher.login(payload);
            this.setAccessToken(data.access_token);
            this.userStore.setUser(data.user);
            this.setIsLoggedIn(true);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    sendEmailforgotPassword = async (payload: ISendEmailPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await AuthFetcher.sendEmailforgotPassword(payload);
            ToastUtil.success(data.message);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    resetPassword = async (payload: IResetPasswordPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await AuthFetcher.resetPassword(payload);
            ToastUtil.success(data.message);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    logout = () => {
        this.setIsLoggedIn(false);
        this.userStore.setUser(new UserModel());
        this.accessToken = '';
    };
}

export default AuthStore;
