import ProductModel from './Product.model';

export default class ProductsCatalog {
    products: ProductModel[] = [];

    totalDocumentsWihtoutLimit: number = 0;

    constructor(productsCatalog?: ProductsCatalog) {
        if (productsCatalog) {
            this.products = productsCatalog.products;
            this.totalDocumentsWihtoutLimit = productsCatalog.totalDocumentsWihtoutLimit;
        }
    }
}
