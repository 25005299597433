import { Box, Collapse, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Column, RowData } from '../../interfaces/interfaces';
import collapsibleTableStyle from './CollapsibleTable.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDateOnly } from '../../utils/dateUtils';
import { Currency } from '../../enums/enums';

interface IProps {
    row: RowData;
    columns: Column[];
    collapsible?: boolean;
}

export const ITableRow = (props: IProps) => {
    const { row, columns, collapsible } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                className={collapsibleTableStyle.tableRowContainer}
                hover
                role='checkbox'
                tabIndex={-1}
                key={`row_${row._id}`}
                onClick={() => setOpen(!open)}
            >
                {columns.map((column) => {
                    let value = row[column.id];

                    if (column.props?.showCurrencySymbol) value = `${Currency.SHEKEL}${value}`;
                    if (column.props?.isDateString) value = formatDateOnly(value);

                    return (
                        <Tooltip key={`tooltip_${column.id}`} title={value} placement='top'>
                            <TableCell key={`table_cell_${column.id}`} align={column.align} className={collapsibleTableStyle.tableCell}>
                                {value}
                            </TableCell>
                        </Tooltip>
                    );
                })}
                {collapsible && (
                    <TableCell>
                        <IconButton aria-label='expand row' size='small'>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>

            {collapsible && row.details && (
                <TableRow key={`row_collapse_${row._id}`} className={collapsibleTableStyle.collapsibleRowDetails}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>{row.details}</Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
