import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import rootStores from '../../stores';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import { STORE_INFORMATION_STORE } from '../../stores/constants';
import '../commonComponents/popup/popupStyle.css';
import SearchIcon from '../search/SearchIcon';
import headerStyle from './Header.module.scss';
import HeaderActions from './headerActions/HeaderActions';
import HeaderAdds from './headerAdds/HeaderAdds';
import Sections from './sections/Sections';
import SideMenu from './sideMenu/SideMenu';
import Title from './title/Title';
import useScrollToTopHook from '../../hooks/useScrollToTopHook';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const Header = observer(() => {
    const { storeInformation } = storeInformationStore;
    const { logo, storeName } = storeInformation;
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });
    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const navigate = useNavigate();
    const onTitleClickHandler = () => navigate('/');

    useScrollToTopHook();
    return (
        <>
            <HeaderAdds />
            <div className={`${headerStyle.container}`}>
                <div className={headerStyle.actionsCon}>
                    {isMobileScreen && <SideMenu />}
                    <SearchIcon />
                    <Title onClick={onTitleClickHandler} logo={logo} storeName={storeName} />
                    <HeaderActions />
                </div>
                {isBigScreen && <Sections />}
            </div>
        </>
    );
});

export default Header;
