import React, { useRef, useState } from 'react';
import headerStyle from '../Header.module.scss';
import Category from '../../../models/topics/Category.model';
import { useNavigate } from 'react-router-dom';
import rootStores from '../../../stores';
import { SECTIONS_STORE } from '../../../stores/constants';
import SectionsStore from '../../../stores/sections/Sections.store';
import { observer } from 'mobx-react';
import Section from './Section';
import TopicModel from '../../../models/topics/Topic.model';

const categoriesStore = rootStores[SECTIONS_STORE] as SectionsStore;

const Sections = observer(() => {
    const [categoriesToShow, setCategoriesToShow] = useState<Category[]>([]);
    const [showPopup, setPopup] = useState<boolean>(false);
    const transitionRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const { sections, setSelectedTopic } = categoriesStore;

    const handleMouseEnter = (showPopup: boolean, sectionId: string, sectionName: string) => {
        setPopup(showPopup);

        if (!sectionId) return;
        const activeSection = sections.find((s) => s.sectionName === sectionName);
        setCategoriesToShow(activeSection?.categories ? activeSection.categories : []);
    };

    const handleMouseLeave = () => setPopup(false);

    const onCategoryClick = (sectionId: string, sectionName: string) => {
        setSelectedTopic(
            new TopicModel({
                topicId: sectionId,
                topicName: sectionName,
            })
        );
        navigate(`products-page/${sectionName}`);
    };

    return (
        <div key={'sections'} className={headerStyle.sections} onMouseLeave={handleMouseLeave}>
            {sections?.map((section) => {
                return (
                    !section.disabled && (
                        <Section
                            key={section.sectionId}
                            section={section}
                            handleMouseEnter={handleMouseEnter}
                            onCategoryClick={onCategoryClick}
                            transitionRef={transitionRef}
                            showPopup={showPopup}
                            categoriesToShow={categoriesToShow}
                        />
                    )
                );
            })}
        </div>
    );
});

export default Sections;
