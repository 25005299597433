const checkoutStepper = {
    next: 'הבא',
    prev: 'הקודם',
    payNow: 'תשלום',
    order: 'הזמנה',
    cart: 'עגלה',
    information: 'מידע',
    shipping: 'משלוח',
    payment: 'תשלום',
    confirmation: 'אישור',
};

export default checkoutStepper;
