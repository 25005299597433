const checkoutDeliveryOptions = {
    selfPickup: 'איסוף עצמי',
    delivery: 'משלוח - עד {{days}} ימי עסקים',
    change: 'שנה',
    pick: 'בחר',
    shipping: 'משלוח',
    addressNotFound: 'לא נבחרה כתובת למשלוח',
    storeNotFound: 'לא נבחרה חנות לאיסוף',
};

export default checkoutDeliveryOptions;
