const myProfile = {
    myAccount: 'החשבון שלי',
    logout: 'התנתק',
    editUser: 'עדכון פרטים אישיים',
    editUserTitle: 'עדכן פרטי משתמש',
    viewAddresses: 'צפה בכתובות',
    viewOrders: 'צפה בהזמנות',
    addressesTitle: 'הכתובות שלי',
    ordersTitle: 'הזמנות',
    addAddress: 'הוסף כתובת חדשה',
    defaultTitle: 'ברירת מחדל',
    accountDetails: 'פרטי חשבון',
    ordersDetails: 'פרטי הזמנות',
    myOrders: 'ההזמנות שלי',
    orderDetails: 'פרטי הזמנה',
    orderId: 'מספר הזמנה',
    date: 'תאריך',
    status: 'סטאטוס',
    user: 'משתמש',
    shippingAddress: 'כתובת למשלוח',
    products: 'מוצרים',
    quantity: 'כמות',
    paymentSummary: 'סיכום תשלום',
    total: 'סה״כ',
    productsTotalAmount: 'עלות מוצרים',
    shippingFee: 'עלות משלוח',
    totalAmount: 'סה״כ לתשלום',

    ordersHistory: {
        orderId: 'מספר הזמנה',
        date: 'תאריך',
        status: 'סטאטוס',
        total: 'סכום',
        orderSent: 'ההזמנה נשלחה בתאריך',
        repeatOrder: 'שכפול הזמנה',
        chargeWho: 'חיוב לקוח',
        paymentMethod: 'דרך תשלום',
        orderSentTo: 'ההזמנה נשלחה לכתובת',
        creditCard: 'כרטיס אשראי',
        payInStore: 'תשלום בחנות',
        deliveryMethod: 'שיטת משלוח',
        productsTitle: 'מוצרים',
        selfPickup: 'איסוף עצמי:',
        delivery: 'משלוח:',
        totalAmountSummary: 'סיכום תשלום',
        totalProductsAmount: 'סיכום ביניים (כולל מע"מ)',
        totalFeeAmount: 'דמי משלוח',
        totalOrderAmount: 'סה"כ',
        coupon: 'שימוש בקופון',
        discount: 'הנחה',
    },

    buttons: {
        edit: 'עדכן',
        delete: 'מחק',
        cancel: 'ביטול',
        saveAddress: 'שמירת כתובת',
        addAddress: 'הוסף כתובת חדשה',
        updateAddress: 'עדכן כתובת',
    },

    inputs: {
        addAddress: 'הוסף כתובת חדשה',
        updateAddress: 'עדכן כתובת',
        address: 'כתובת',
        address2: 'כתובת 2',
        city: 'עיר',
        zipCode: 'מיקוד',
        choose: 'בחר',
        noOptions: 'אין אופציות',
        deleteAddressValidate: 'האם למחוק כתובת מהמערכת?',

        errors: {
            address: 'חובה להזין כתובת',
            city: 'חובה להזין עיר',
            zipCode: 'חובה להזין מיקוד',
            onlyNumbers: 'מספרים בלבד',
        },
    },
};

export default myProfile;
