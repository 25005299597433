import AuthStore from './auth/Auth.store';
import CartStore from './cart/Cart.store';
import FiltersStore from './filters/Filters.store';
import ProductStore from './product/Product.store';
import ProductsStore from './products/Products.store';
import SectionsStore from './sections/Sections.store';
import StoreInformation from './storeInformation/StoreInformation.store';
import SystemStore from './system/System.store';
import UserStore from './user/User.store';

import {
    AUTH_STORE,
    CART_STORE,
    FILTERS_STORE,
    ORDERS_STORE,
    PRODUCT_STORE,
    PRODUCTS_STORE,
    SECTIONS_STORE,
    STORE_INFORMATION_STORE,
    SYSTEM_STORE,
    USER_STORE,
} from './constants';
import OrdersStore from './orders/Orders.store';

const sectionsStore = new SectionsStore();
const storeInformationStore = new StoreInformation();
const userStore = new UserStore();
const authStore = new AuthStore(userStore);
const filtersStore = new FiltersStore();
const productStore = new ProductStore();
const productsStore = new ProductsStore();
const cartStore = new CartStore();
const systemStore = new SystemStore(cartStore);
const ordersStore = new OrdersStore();

const rootStores = {
    [SECTIONS_STORE]: sectionsStore,
    [STORE_INFORMATION_STORE]: storeInformationStore,
    [AUTH_STORE]: authStore,
    [FILTERS_STORE]: filtersStore,
    [PRODUCT_STORE]: productStore,
    [PRODUCTS_STORE]: productsStore,
    [CART_STORE]: cartStore,
    [USER_STORE]: userStore,
    [SYSTEM_STORE]: systemStore,
    [ORDERS_STORE]: ordersStore,
};

// Debugging purpose
if (process.env.NODE_ENV !== 'production') {
    (window as any)['stores'] = rootStores;
}

export default rootStores;
