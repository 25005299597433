import React, { useState } from 'react';
import checkoutItemsCollapseStyle from './CheckoutItemsCollapse.module.scss';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CartItems from '../../cart/cartItems/CartItems';
import { useTranslation } from 'react-i18next';
import ProductInOrderModel from '../../../models/order/ProductInOrder';

interface IProps {
    products: ProductInOrderModel[];
}

const CheckoutItemsCollapse = (props: IProps) => {
    const { products } = props;
    const [isSectionCollapseOpen, setIsSectionCollapseOpen] = useState(false);
    const { t } = useTranslation();

    const onSectionCollapseClickHandler = () => {
        setIsSectionCollapseOpen(!isSectionCollapseOpen);
    };

    return (
        <>
            <ListItemButton className={checkoutItemsCollapseStyle.sectionCollapseContainer} onClick={onSectionCollapseClickHandler}>
                <ListItemText
                    className={checkoutItemsCollapseStyle.sectionCollapseText}
                    primary={isSectionCollapseOpen ? t('checkoutItemsCollapse.hideItems') : t('checkoutItemsCollapse.showItems')}
                />
                {isSectionCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isSectionCollapseOpen} timeout='auto' unmountOnExit>
                <CartItems products={products} isViewOnly={true} />
            </Collapse>
        </>
    );
};

export default CheckoutItemsCollapse;
