import { observer } from 'mobx-react';
import { useRef } from 'react';
import commonStyle from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import './RegistrationForm.customization.css';
import registrationStyle from './RegistrationForm.module.scss';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import { RoutesUrl } from '../../../router/RoutesUrls';
import Recaptcha from '../../commonComponents/recaptcha/Recaptcha';

import GetPickedDateWithoutOffset from '../../../actions/GetPickedDateWithoutOffset';
import rootStores from '../../../stores';
import SystemStore from '../../../stores/system/System.store';
import { SYSTEM_STORE } from '../../../stores/constants';
import IDatePicker from '../../commonComponents/datePicker/IDatePicker';
import ITextInput from '../../commonComponents/textInput/TextInput';
import RegistrationDetails from '../../../models/auth/RegistrationDetails.model';

import { hashPassword } from '../../../actions/hashPassword';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

interface IProps {
    registrationHandler: (payload: RegistrationDetails) => Promise<boolean>;
}

const RegistrationForm = observer((props: IProps) => {
    const { registrationHandler } = props;
    const {
        system: {
            legalcy: { minAgeForRegistration },
        },
    } = systemStore;
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('registrationForm.invalidEmail')).required(t('loginForm.requiredField')),
        firstName: yup.string().required(t('registrationForm.requiredField')),
        phoneNumber: yup
            .string()
            .required(t('registrationForm.requiredField'))
            .matches(/^(05\d{8}|9725\d{8}|97205\d{8})$/, { message: t('registrationForm.invalidPhoneNumber'), excludeEmptyString: true }),
        password: yup
            .string()
            .required(t('registrationForm.requiredField'))
            .min(8, t('registrationForm.minDigits', { length: 8 }))
            .matches(/[a-zA-Z]/, t('registrationForm.oneEnglishLetter')),
        dateOfBirth: yup
            .date()
            .nullable()
            .required(t('registrationForm.requiredField'))
            .test(function (value) {
                const currentDate = new Date();
                const userBirthDate = new Date(value);
                const age = currentDate.getFullYear() - userBirthDate.getFullYear();
                return minAgeForRegistration === 0 ? true : age >= minAgeForRegistration;
            }),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            phoneNumber: '',
            password: '',
            dateOfBirth: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let dateOfBirth = '';
            let captchaV2Token = '';
            if (values.dateOfBirth) {
                dateOfBirth = GetPickedDateWithoutOffset(values.dateOfBirth);
            }
            if (captchaRef.current?.getValue()) {
                captchaV2Token = captchaRef.current.getValue();
            }
            captchaRef.current?.reset();
            formik.resetForm();
            const isSuccess = await registrationHandler(
                new RegistrationDetails({
                    email: values.email,
                    password: await hashPassword(values.password),
                    firstName: values.firstName,
                    phoneNumber: values.phoneNumber,
                    dateOfBirth,
                    captchaV2Token,
                })
            );
            if (isSuccess) navigate(RoutesUrl.registrationConfirmation);
        },
    });

    return (
        <div className={registrationStyle.registrationFormContainer}>
            <div className={registrationStyle.registrationFormContext}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={registrationStyle.registrationFormTitle}>
                        <p>{t('registrationForm.createAccount')}</p>
                    </div>

                    <div className={registrationStyle.registrationFormInputContainer}>
                        <div className={registrationStyle.registrationFormInputFiled}>
                            <ITextInput
                                size='small'
                                inputLabel={t('registrationForm.firstName')}
                                value={formik.values.firstName}
                                handleOnChange={formik.handleChange('firstName')}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                                isFullWidth={true}
                                variant='outlined'
                            />
                        </div>
                    </div>

                    <div className={registrationStyle.registrationFormInputContainer}>
                        <div className={registrationStyle.registrationFormInputFiled}>
                            <ITextInput
                                size='small'
                                inputLabel={t('registrationForm.phoneNumber')}
                                value={formik.values.phoneNumber}
                                handleOnChange={formik.handleChange('phoneNumber')}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                isFullWidth={true}
                                variant='outlined'
                            />
                        </div>
                    </div>

                    <div className={registrationStyle.registrationFormInputContainer}>
                        <div className={registrationStyle.registrationFormInputFiled}>
                            <ITextInput
                                size='small'
                                inputLabel={t('registrationForm.email')}
                                value={formik.values.email}
                                handleOnChange={formik.handleChange('email')}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                isFullWidth={true}
                                variant='outlined'
                            />
                        </div>
                    </div>

                    <div className={registrationStyle.registrationFormInputContainer}>
                        <div className={registrationStyle.registrationFormInputFiled}>
                            <ITextInput
                                type='password'
                                size='small'
                                inputLabel={t('registrationForm.password')}
                                value={formik.values.password}
                                handleOnChange={formik.handleChange('password')}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                isFullWidth={true}
                                variant='outlined'
                            />
                        </div>
                    </div>

                    <div className={registrationStyle.registrationFormInputContainer}>
                        <div className={registrationStyle.registrationFormInputFiled}>
                            <IDatePicker
                                inputLabel={t('registrationForm.dateOfBirth')}
                                disableFuture
                                value={formik.values.dateOfBirth}
                                handleOnChange={(value) => formik.setFieldValue('dateOfBirth', value)}
                                helperText={
                                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                                        ? formik.values.dateOfBirth
                                            ? t('registrationForm.dateError')
                                            : t('registrationForm.required')
                                        : ''
                                }
                            />
                        </div>
                    </div>
                    <div className={`${registrationStyle.privacyPolicyContainer} row`}>
                        <span className={registrationStyle.privacyPolicyText}>{t('registrationForm.privacyPolicyText')} </span>
                        <span
                            className={registrationStyle.privacyPolicyLink}
                            onClick={() => {
                                navigate(RoutesUrl.privacyPolicy);
                            }}
                        >
                            {t('registrationForm.privacyPolicy')}{' '}
                        </span>
                    </div>

                    <div className={`${registrationStyle.recaptchaContainer} row`}>
                        <Recaptcha captchaRef={captchaRef} />
                    </div>

                    <div className={registrationStyle.registrationFormSignIn}>
                        <IButton className={`${commonStyle.defaultButton}`} title={t('registrationForm.create')} type={'submit'} />
                    </div>
                </form>
            </div>
        </div>
    );
});

export default RegistrationForm;
