import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react';
import termsOfUseModalStyle from './TermsOfUseModal.module.scss';
import commonStyles from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/button/IButton';
import rootStores from '../../stores';
import { SYSTEM_STORE } from '../../stores/constants';
import SystemStore from '../../stores/system/System.store';
import { useTranslation } from 'react-i18next';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const TermsOfUseModal = observer(() => {
    const { system, setUserAcceptTermsOfUse } = systemStore;
    const { t } = useTranslation();
    return (
        <div>
            <Modal open={true}>
                <div className={termsOfUseModalStyle.termsOfUseModallContainer}>
                    <div className={termsOfUseModalStyle.title}>
                        <p>
                            {t('termsOfUseModal.title')} {system.legalcy.minAgeForRegistration}
                        </p>
                    </div>
                    <div className={termsOfUseModalStyle.minAge}>
                        <p>{system.legalcy.minAgeForRegistration}+</p>
                    </div>
                    <div className={termsOfUseModalStyle.statement}>
                        <span>{system.legalcy.termsOfUse.statement}</span>
                    </div>
                    <div className={`${termsOfUseModalStyle.acceptButtonContainer} row`}>
                        <IButton
                            className={`${commonStyles.defaultButton} ${termsOfUseModalStyle.acceptButton}`}
                            disabled={false}
                            onClick={() => {
                                setUserAcceptTermsOfUse(true);
                            }}
                            title={t('termsOfUseModal.accept')}
                        />
                    </div>
                    <div className={`${termsOfUseModalStyle.notAcceptButtonContainer} row`}>
                        <IButton
                            className={`${commonStyles.defaultButton} ${termsOfUseModalStyle.notAcceptButton}`}
                            disabled={false}
                            onClick={() => {
                                window.location.href = 'https://www.google.co.il';
                            }}
                            title={t('termsOfUseModal.notAccept')}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default TermsOfUseModal;
