import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PersonIcon from '@mui/icons-material/Person';
import headerActionsStyle from './HeaderActions.module.scss';
import { useNavigate } from 'react-router-dom';
import CartDrawer from '../../cartDrawer/CartDrawer';

import rootStores from '../../../stores';
import { CART_STORE, AUTH_STORE } from '../../../stores/constants';
import AuthStore from '../../../stores/auth/Auth.store';
import CartStore from '../../../stores/cart/Cart.store';
import { observer } from 'mobx-react';
import { RoutesUrl } from '../../../router/RoutesUrls';

const cartStore = rootStores[CART_STORE] as CartStore;
const authStore = rootStores[AUTH_STORE] as AuthStore;

const HeaderActions = observer(() => {
    const navigate = useNavigate();

    const { isOpen, setCartIsOpen, products } = cartStore;
    const { isLoggedIn } = authStore;

    const handlePersonClick = () => navigate(isLoggedIn ? RoutesUrl.profile : RoutesUrl.auth);
    const handleCartClick = () => setCartIsOpen(!isOpen);

    return (
        <div className={headerActionsStyle.container}>
            <PersonIcon className={headerActionsStyle.personIcon} onClick={handlePersonClick} />
            <CartDrawer isCartOpen={isOpen} onClose={() => setCartIsOpen(false)} />
            {products.length === 0 && <ShoppingCartOutlinedIcon className={headerActionsStyle.shoppingBagIcon} onClick={handleCartClick} />}
            {products.length > 0 && (
                <div className={headerActionsStyle.fullShoppingCartContainer}>
                    <ShoppingCartIcon className={headerActionsStyle.shoppingBagIcon} onClick={handleCartClick} />
                    <div className={headerActionsStyle.shippingCartProductsNumber}>
                        <span>{products.length}</span>
                    </div>
                </div>
            )}
        </div>
    );
});

export default HeaderActions;
