import { ITermsOfUse } from '../../interfaces/interfaces';

export default class LegalcyModel {
    minAgeForRegistration: number = 0;

    termsOfUse: ITermsOfUse = {
        useTermsOfUseModal: false,
        statement: '',
    };

    constructor(legalcy?: LegalcyModel) {
        if (legalcy) {
            this.minAgeForRegistration = legalcy.minAgeForRegistration;
            this.termsOfUse = legalcy.termsOfUse;
        }
    }
}
