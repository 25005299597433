import CouponModel from '../order/CouponModel.model';

export default class CartCouponModel {
    isCouponValidForCart: boolean = false;
    invalidReason: string = '';
    coupon: CouponModel = new CouponModel();

    constructor(cartCoupon?: CartCouponModel) {
        if (cartCoupon) {
            this.isCouponValidForCart = cartCoupon.isCouponValidForCart;
            this.coupon = cartCoupon.coupon;
            this.invalidReason = cartCoupon.invalidReason;
        }
    }
}
