import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ClientConfig from '../../../config/index';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import SectionsStore from '../../../stores/sections/Sections.store';
import ProductStore from '../../../stores/product/Product.store';
import { CART_STORE, PRODUCT_STORE, SECTIONS_STORE } from '../../../stores/constants';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import SliderShow from '../../commonComponents/sliderShow/SliderShow';
import './ProductInformation.customization.css';
import productInformationStyle from './ProductInformation.module.scss';
import VariantRadioGroup from '../../commonComponents/variantRadioGroup/VariantRadioGroup';
import ProductsVariant from '../../../models/product/ProductVariant.model';
import { useState } from 'react';
import ProductModel from '../../../models/product/Product.model';
import { Currency } from '../../../enums/enums';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { RoutesUrl } from '../../../router/RoutesUrls';
import { useMediaQuery } from 'react-responsive';
import NotFoundMessage from '../../notFoundMessage/NotFoundMessage';

const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;
const cartStore = rootStores[CART_STORE] as CartStore;
const productStore = rootStores[PRODUCT_STORE] as ProductStore;

interface IProps {
    handleClose?: () => void;
}

const ProductInformation = observer((props: IProps) => {
    const { handleClose } = props;
    const [selectedVariantOptions, setSelectedVariantOptions] = useState<ProductsVariant[]>([]);
    const { addProduct, setCartIsOpen } = cartStore;
    const { selectedTopic } = sectionsStore;
    const { product } = productStore;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const onSelectVariantOptionHandler = (selectedVariant: ProductsVariant) => {
        const isVariantSelected = selectedVariantOptions.find((variant) => {
            return variant.type === selectedVariant.type;
        });

        //Sort Fuctonion here is to avoid case of duplicate product with same variants in a different order
        if (!isVariantSelected) {
            setSelectedVariantOptions((prevOptions) => [...prevOptions, selectedVariant].sort((a, b) => a.type.localeCompare(b.type)));
        } else {
            const isOptionSelected = isVariantSelected.options.find((option) => {
                return option.value === selectedVariant.options[0].value;
            });
            const updatedVariants = selectedVariantOptions.filter((variant) => variant.type !== selectedVariant.type);
            if (isOptionSelected) {
                setSelectedVariantOptions(updatedVariants.sort((a, b) => a.type.localeCompare(b.type)));
            } else {
                setSelectedVariantOptions([...updatedVariants, selectedVariant].sort((a, b) => a.type.localeCompare(b.type)));
            }
        }
    };

    const handleAddToCart = () => {
        const shouldOpenCart = true;
        const selectedProduct = new ProductModel(product);
        selectedProduct.variants = selectedVariantOptions;

        addProduct(selectedProduct, shouldOpenCart);
        if (handleClose) handleClose();
        setCartIsOpen(true);
    };

    const backButtonHandler = () => {
        selectedTopic?.topicName ? navigate(`/products-page/${selectedTopic?.topicName}`) : navigate(RoutesUrl.welcome);
    };

    return product._id ? (
        <div className={`${productInformationStyle.productInformationContainer} row`}>
            <div className='col-12 col-md-6'>
                {isMobileScreen && (
                    <div className={productInformationStyle.backButtonContainer}>
                        <BackIcon onClick={backButtonHandler} className={productInformationStyle.icon} />
                    </div>
                )}
                <div className={`${productInformationStyle.productTitleContainer} ${productInformationStyle.customColumn}`}>
                    <span>{product.name}</span>
                </div>
                {!ClientConfig.isProvider && (
                    <div className={`${productInformationStyle.productPriceContainer} ${productInformationStyle.customColumn}`}>
                        <span>
                            {Currency.SHEKEL}
                            {product.updatedPrice.toLocaleString()}
                        </span>
                        {product.updatedPrice !== product.price && (
                            <span className={`${productInformationStyle.productPrice} ${productInformationStyle.strikePrice}`}>
                                {Currency.SHEKEL}
                                {product.price.toLocaleString()}
                            </span>
                        )}
                    </div>
                )}
                <div className={`${productInformationStyle.dividerContainer} ${productInformationStyle.customColumn}`}>
                    <Divider className={productInformationStyle.customerDivider} variant='middle' />
                </div>
                <div className={`${productInformationStyle.inStockContainer} ${productInformationStyle.customColumn}`}>
                    {product.inStock ? (
                        <Chip
                            className={productInformationStyle.customChip}
                            label={t('productInformation.inStock')}
                            color='success'
                            variant='outlined'
                        />
                    ) : (
                        <Chip
                            className={productInformationStyle.customChip}
                            label={t('productInformation.outOfStock')}
                            color='warning'
                            variant='outlined'
                        />
                    )}
                </div>
                {product.variants?.map((variant, i) => (
                    <div key={i} className={productInformationStyle.radioGroupContainer}>
                        <div className={productInformationStyle.titleContainer}>
                            <span>{variant.label}:</span>
                        </div>
                        <VariantRadioGroup variant={variant} onSelectVariantOptionHandler={onSelectVariantOptionHandler} />
                    </div>
                ))}
                <div className={`${productInformationStyle.addToCartButtonContainer} ${productInformationStyle.customColumn}`}>
                    <IButton
                        className={`${commonStyles.defaultButton}`}
                        disabled={!product.inStock}
                        onClick={handleAddToCart}
                        title={t('productInformation.addToCart')}
                    />
                </div>
                <div className={`${productInformationStyle.productDescriptionContainer} ${productInformationStyle.customColumn}`}>
                    <p>{product?.description}</p>
                </div>
            </div>
            <div className={`${productInformationStyle.sliderColumn} col-12 col-md-6`}>
                <div className={productInformationStyle.gridImagesContainer}>
                    <SliderShow images={product.images} />
                </div>
            </div>
        </div>
    ) : (
        <NotFoundMessage />
    );
});

export default ProductInformation;
