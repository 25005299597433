import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../../router/RoutesUrls';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import UserStore from '../../../stores/user/User.store';
import StoreInformationStore from '../../../stores/storeInformation/StoreInformation.store';
import { CART_STORE, USER_STORE, STORE_INFORMATION_STORE } from '../../../stores/constants';
import Dropdown from '../../commonComponents/dropdown/Dropdown';
import checkoutCustomerInformationStyle from './CheckoutCustomerInformation.module.scss';
import { ISelectOption } from '../../../interfaces/interfaces';
import { DevliveryOptions, OurStoreServices } from '../../../enums/enums';
import AddAddressForm from '../../myProfile/addresses/AddAddressForm';
import { useEffect } from 'react';

const userStore = rootStores[USER_STORE] as UserStore;
const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;
const cartStore = rootStores[CART_STORE] as CartStore;

const CheckoutCustomerInformation = observer(() => {
    const { user, getUserAddressISelecet } = userStore;
    const { getStoresISelecet } = storeInformationStore;
    const { deliveryOption, setShippingAddress, selectedShippingAddress, setPickupStore, selectedPickupStore } = cartStore;

    const isDeliveryOption = deliveryOption === DevliveryOptions.DELIVERY;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const setAddressHandler = (address: ISelectOption) => {
        if (address.key === -1) {
            navigate(RoutesUrl.addresses);
        } else {
            setShippingAddress(address);
        }
    };

    const setPickupStoreHandler = (store: ISelectOption) => {
        setPickupStore(store);
    };

    const addNewAddressHandler = (address: string) => {
        setShippingAddress({
            key: 1,
            label: address,
        });
    };

    useEffect(() => {
        if (user.shippingAddresses.length === 0) {
            setShippingAddress({
                key: -2,
                label: '',
            });
        }
    }, []);

    return (
        <div className={checkoutCustomerInformationStyle.checkoutCustomerInformationContainer}>
            <div className={`${checkoutCustomerInformationStyle.titleContainer} row`}>
                <span>{t('checkoutCustomerInformation.customerInformation')}</span>
            </div>
            <div className={`${checkoutCustomerInformationStyle.userDetails} row`}>
                <span>{`${user.firstName} ${user.lastName}`}</span>
            </div>
            <div className={`${checkoutCustomerInformationStyle.shippingDetails} row`}>
                <span>
                    {`${t('checkoutCustomerInformation.deliveryMethod')}: `}
                    <span className={checkoutCustomerInformationStyle.deliveryMethod}>
                        {deliveryOption === '' ? t(`checkoutCustomerInformation.notFound`) : t(`checkoutCustomerInformation.${deliveryOption}`)}
                    </span>
                </span>
            </div>
            <div className={`${checkoutCustomerInformationStyle.addressDropdownContainer} row`}>
                {isDeliveryOption ? (
                    user.shippingAddresses?.length === 0 ? (
                        <div className={checkoutCustomerInformationStyle.addFormContainer}>
                            <AddAddressForm setSelectedAddress={addNewAddressHandler} />
                        </div>
                    ) : (
                        <Dropdown
                            selectedKey={selectedShippingAddress.key}
                            placeholder={t('checkoutCustomerInformation.chooseAddress')}
                            options={getUserAddressISelecet}
                            onSelect={setAddressHandler}
                        />
                    )
                ) : (
                    <Dropdown
                        selectedKey={selectedPickupStore.key}
                        placeholder={t('checkoutCustomerInformation.chooseStore')}
                        options={getStoresISelecet}
                        onSelect={setPickupStoreHandler}
                    />
                )}
            </div>
        </div>
    );
});

export default CheckoutCustomerInformation;
