import React from 'react';
import numberInputStyle from './NumberInput.module.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { observer } from 'mobx-react';
import Product from '../../../models/product/Product.model';

import rootStores from '../../../stores';
import { CART_STORE } from '../../../stores/constants';
import CartStore from '../../../stores/cart/Cart.store';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

interface IProps {
    product: Product;
    quantity: number;
}

const NumberInput = observer((props: IProps) => {
    const { product, quantity } = props;
    const { addProduct, removeProduct } = cartStoreStore;

    const handleIncrease = () => {
        const shouldOpenCart = false;
        addProduct(product, shouldOpenCart);
    };

    const handleDecrease = () => {
        removeProduct(product);
    };

    return (
        <div className={`${numberInputStyle.numberInputContainer} row`}>
            <div onClick={handleIncrease} className={`${numberInputStyle.numberInputPlusContainer} col-3`}>
                <AddIcon className={numberInputStyle.numberInputIcon} />
            </div>
            <div className={`${numberInputStyle.numberInputCounterContainer} col-3`}>{quantity}</div>
            <div onClick={handleDecrease} className={`${numberInputStyle.numberInputMinusContainer} col-3`}>
                <RemoveIcon className={numberInputStyle.numberInputIcon} />
            </div>
        </div>
    );
});

export default NumberInput;
