import categoriesSelectionStyle from './CategoriesSelection.module.scss';
import rootStores from '../../stores';
import { useNavigate } from 'react-router-dom';
import { SECTIONS_STORE } from '../../stores/constants';
import SectionsStore from '../../stores/sections/Sections.store';
import CategoryCard from '../../components/categoryCard/CategoryCard';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CategoryModel from '../../models/topics/Category.model';
import { RoutesUrl } from '../../router/RoutesUrls';

const categoriesStore = rootStores[SECTIONS_STORE] as SectionsStore;

const CategoriesSelection = () => {
    const [allSubCategories, setAllSubCategories] = useState<CategoryModel[]>([]);
    const { selectedSection, setSelectedTopic } = categoriesStore;
    const { sectionName, categories } = selectedSection;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate(`/products-page/${sectionName}`);
    };

    const backHomeHandler = () => {
        navigate(`${RoutesUrl.welcome}`);
    };

    useEffect(() => {
        const subCategories: CategoryModel[] = [];
        categories?.forEach((category) => {
            if (category.subCategories?.length === 0) {
                subCategories.push(category);
            } else {
                category.subCategories &&
                    category.subCategories.forEach((subCategory) => {
                        if (category.subCategories && category.subCategories.length > 0) {
                            subCategories.push(subCategory);
                        }
                    });
            }
        });

        setAllSubCategories(subCategories);
    }, [selectedSection]);

    return (
        <div className={categoriesSelectionStyle.categoriesSelectionContainer}>
            <div className={categoriesSelectionStyle.titleContainer}>
                <span>{sectionName}</span>
            </div>
            <div className={categoriesSelectionStyle.allProductsContainer}>
                <span
                    onClick={() => {
                        selectedSection.sectionId ? onClickHandler() : backHomeHandler();
                    }}
                >
                    {selectedSection.sectionId ? t('categoriesSelection.selectProducts') : t('categoriesSelection.back')}
                    {sectionName}
                </span>
            </div>
            <div className={categoriesSelectionStyle.allCategoriesCardsContainer}>
                {allSubCategories?.map((category, i) => (
                    <>
                        <CategoryCard key={i} category={category} setSelectedTopic={setSelectedTopic} />
                    </>
                ))}
            </div>
        </div>
    );
};

export default CategoriesSelection;
