export default class FilterProductsParamModel {
    topicId: string = '';

    page: number = 1;

    numberOfProducts: number = 16;

    visibleProducts: boolean = true;

    limit: boolean = true;

    sortByPrice: number | null = null;

    selectedFilters: { [key: string]: boolean } = {};

    constructor(filterProductsParam?: FilterProductsParamModel) {
        if (filterProductsParam) {
            this.topicId = filterProductsParam.topicId;
            this.page = filterProductsParam.page;
            this.numberOfProducts = filterProductsParam.numberOfProducts;
            this.limit = filterProductsParam.limit;
            this.sortByPrice = filterProductsParam.sortByPrice;
            this.selectedFilters = filterProductsParam.selectedFilters;
            this.visibleProducts = filterProductsParam.visibleProducts;
        }
    }
}
