import MyProfile from '../../components/myProfile/MyProfile';
import profileStyle from './Profile.module.scss';

const Profile = () => {
    return (
        <div className={profileStyle.profileContainer}>
            <MyProfile />
        </div>
    );
};

export default Profile;
