import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import sendEmailStyle from './SendEmail.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../../router/RoutesUrls';
import TextField from '@mui/material/TextField';
import rootStores from '../../../stores';
import AuthStore from '../../../stores/auth/Auth.store';
import { AUTH_STORE } from '../../../stores/constants';
import Spinner from '../../commonComponents/spinner/Spinner';
import EmailSentIcon from '../../../assets/icons/email-sent.png';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha from '../../commonComponents/recaptcha/Recaptcha';

const authStore = rootStores[AUTH_STORE] as AuthStore;

const SendEmail = observer(() => {
    const { sendEmailforgotPassword, isLoading } = authStore;
    const [emailSentSuccessfully, setEmailSentSuccessfully] = useState<boolean>(false);
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('sendEmail.invalidEmail')).required(t('sendEmail.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const captchaV2Token = captchaRef.current?.getValue();
            captchaRef.current?.reset();
            formik.resetForm();
            setEmailSentSuccessfully(
                await sendEmailforgotPassword({
                    email: values.email,
                    captchaV2Token,
                })
            );
        },
    });

    const backToLoginHandler = () => {
        navigate(RoutesUrl.auth);
    };
    return (
        <div className={`${sendEmailStyle.sendEmailContainer} ${isLoading ? 'blur-background' : ''}`}>
            {emailSentSuccessfully ? (
                <>
                    <div className={`${sendEmailStyle.iconContainer} row`}>
                        <img src={EmailSentIcon} alt='' />
                    </div>
                    <div className={`${sendEmailStyle.textContainer} row`}>
                        <span>{t('sendEmail.emailSentSuccessfully')}</span>
                    </div>
                </>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className={`${sendEmailStyle.titleContainer} row`}>
                        <span>{t('sendEmail.resetTitle')}</span>
                    </div>
                    <div className={`${sendEmailStyle.contextContainer} row`}>
                        <span>{t('sendEmail.emailExplanation')}</span>
                    </div>
                    <div className={`${sendEmailStyle.emailInputContainer} row`}>
                        <TextField
                            size='small'
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange('email')}
                            placeholder={t('sendEmail.youtEmail')}
                            variant='outlined'
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </div>
                    <div className={`${sendEmailStyle.recaptchaContainer} row`}>
                        <Recaptcha captchaRef={captchaRef} />
                    </div>
                    <div className={`${sendEmailStyle.submitButtonContainer} row`}>
                        <IButton className={`${commonStyles.defaultButton}`} title={t('sendEmail.send')} type={'submit'} />
                    </div>
                    <div className={`${sendEmailStyle.loginFormButton} row`}>
                        <IButton className={`${commonStyles.textButton}`} title={t('sendEmail.backToLogin')} onClick={backToLoginHandler} />
                    </div>
                </form>
            )}
            {isLoading && <Spinner />}
        </div>
    );
});

export default SendEmail;
