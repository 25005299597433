import ClientConfig from '../config/index';
import { IEditUserPayload } from '../interfaces/interfaces';
import UserModel from '../models/user/User.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const userPrefix = 'userManagement';

class UserFetchers extends BaseHttpFetcher {
    async addAddress(user: UserModel, address: string, address2: string, city: string, zipCode: string): Promise<any> {
        return await this.post(`${userPrefix}/addAddress`, {
            userId: user.id,
            address,
            address2,
            city,
            zipCode,
        });
    }

    async updateAddress(user: UserModel, oldAddress: string, address: string, address2: string, city: string, zipCode: string): Promise<any> {
        return await this.put(`${userPrefix}/updateAddress`, {
            userId: user.id,
            oldAddress,
            address,
            address2,
            city,
            zipCode,
        });
    }

    async deleteAddress(user: UserModel, address: string, city: string): Promise<any> {
        return await this.delete(`${userPrefix}/deleteAddress/${user.id}/${city}/${address}`);
    }

    async updateUserDetails(payload: IEditUserPayload): Promise<any> {
        return await this.put(`${userPrefix}/updateUserDetails`, payload);
    }
}

const storeInformationFetcherInstance = new UserFetchers('/', ClientConfig.apiBaseHost);
export default storeInformationFetcherInstance;
