import CategoryModel from '../../../models/topics/Category.model';
import commonStyles from '../../../styles/commonStyles.module.scss';
import headerStyle from '../Header.module.scss';

interface IProps {
    subCategory: CategoryModel;
    onCategoryClick: (sectionId: string, sectionName: string) => void;
}

const Category = (props: IProps) => {
    const { subCategory, onCategoryClick } = props;
    return (
        <div className={headerStyle.subCategoryContainer}>
            <span
                onClick={() => onCategoryClick(subCategory.categoryId, subCategory.categoryName)}
                className={`${headerStyle.subCategoryTitleName} ${commonStyles.animatedBottomBorder} ${commonStyles.fromRight} ${commonStyles.textAnimated}`}
            >
                {subCategory.categoryName}
            </span>
        </div>
    );
};

export default Category;
