import CouponModel from './CouponModel.model';
import PaymentAmountModel from './PaymentAmountModel.model';
import ProductInOrderModel from './ProductInOrder';
import ShippingDetailsModel from './ShippingDetailsModel.model';
import OrdersUser from './OrdersUser.model';
import PaymentInformation from './PaymentInformation.model';

export default class CreateOrder {
    user: OrdersUser = new OrdersUser();

    shippingDetails: ShippingDetailsModel = new ShippingDetailsModel();

    paymentInformation: PaymentInformation = new PaymentInformation();

    paymentAmount: PaymentAmountModel = new PaymentAmountModel();

    products: ProductInOrderModel[] = [];

    coupon: CouponModel = new CouponModel();

    captchaV2Token: string = '';

    constructor(createOrder?: CreateOrder) {
        if (createOrder) {
            this.user = createOrder.user;
            this.shippingDetails = createOrder.shippingDetails;
            this.paymentInformation = createOrder.paymentInformation;
            this.products = createOrder.products;
            this.paymentAmount = createOrder.paymentAmount;
            this.coupon = createOrder.coupon;
            this.captchaV2Token = createOrder.captchaV2Token;
        }
    }
}
