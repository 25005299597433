import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ordersStyle from './OrdersDetails.module.scss';

import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RoutesUrl } from '../../../router/RoutesUrls';
import OrdersStore from '../../../stores/orders/Orders.store';
import UserStore from '../../../stores/user/User.store';
import { ORDERS_STORE, USER_STORE } from '../../../stores/constants';
import rootStores from '../../../stores/index';

const orderStore = rootStores[ORDERS_STORE] as OrdersStore;
const userStore = rootStores[USER_STORE] as UserStore;

const OrdersDetails = observer(() => {
    const { orders, getOrdersByUserId } = orderStore;
    const { user } = userStore;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOnViewOrdersClick = () => navigate(RoutesUrl.orders);

    useEffect(() => {
        getOrdersByUserId(user.id);
    }, []);

    return (
        <div className={ordersStyle.ordersContainer}>
            <div className={`${ordersStyle.ordersTitleContainer} row`}>
                <span>{t('myProfile.ordersDetails')}</span>
            </div>
            <div className={ordersStyle.ordersDetails}>
                <span className={ordersStyle.viewOrdersText} onClick={handleOnViewOrdersClick}>
                    {t('myProfile.viewOrders') + ` (${orders?.length})`}
                </span>
            </div>
        </div>
    );
});

export default OrdersDetails;
