const footer = {
    aboutUs: 'קצת עלינו',
    contactUs: 'יצירת קשר',
    openingHours: 'שעות פעילות',
    mtdigital: 'MTDigital',
    copyright: '2024 Copyright ©',
    privacyPolicy: 'מדיניות פרטיות',
    deliveryPolicy: 'מדיניות משלוחים',
    usagePolicy: 'תנאי שימוש',
};

export default footer;
