import { observer } from 'mobx-react';
import ClientConfig from '../../config/index';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import Filter from '../../components/filter/Filter';
import ProductMiniCard from '../../components/product/productMiniCard/ProductMiniCard';
import SideFilters from '../../components/sideFilters/SideFilters';
import SortProductOptions from '../../components/sortProductOptions/SortProductOptions';
import { ISelectOption } from '../../interfaces/interfaces';
import rootStores from '../../stores';
import { PRODUCTS_STORE, SECTIONS_STORE } from '../../stores/constants';
import ProductsStore from '../../stores/products/Products.store';
import SectionsStore from '../../stores/sections/Sections.store';
import productsPageStyle from './ProductsPage.module.scss';
import { ReactComponent as NoProductsIcon } from '../../assets/icons/no-products.svg';
import footerStyle from '../../components/footer/Footer.module.scss';
import TopicModel from '../../models/topics/Topic.model';

const sectionStore = rootStores[SECTIONS_STORE] as SectionsStore;
const productsStore = rootStores[PRODUCTS_STORE] as ProductsStore;

const ProductsPage = observer(() => {
    const { sections, selectedTopic, setSelectedTopic, filterProductsParam, setNextPage, setSortByPrice, setSelectedFilters } = sectionStore;
    const { products, getProductsByFilters, isLoading } = productsStore;

    const numberOfavailableProducts = products?.products?.length;

    const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' });
    const isMobileScreen = useMediaQuery({ query: '(max-width: 992px)' });

    const { t } = useTranslation();
    const { sectionName } = useParams();

    const changeSortByPriceHandler = (option: ISelectOption) => {
        setSortByPrice(option.key);
    };

    const changeFiltersHandler = (selectedFilter: { [key: string]: boolean }) => {
        setSelectedFilters(selectedFilter);
    };

    const fetchProducts = async () => {
        const { selectedFilters, ...restFilterParams } = filterProductsParam;
        // in case of refreshing the page
        if (!restFilterParams.topicId) {
            restFilterParams.topicId = selectedTopic.topicId;
        }
        try {
            await getProductsByFilters({
                ...restFilterParams,
                ...selectedFilters,
            });
        } catch (error) {}
    };

    useEffect(() => {
        if (!selectedTopic.topicId) {
            const selectedSection = sections?.find((section) => section.sectionName === sectionName);
            selectedSection &&
                setSelectedTopic(
                    new TopicModel({
                        topicId: selectedSection?.sectionId,
                        topicName: selectedSection?.sectionName,
                    })
                );
        }
    }, [sections]);

    useEffect(() => {
        selectedTopic.topicId && fetchProducts();
    }, [filterProductsParam]);

    useEffect(() => {
        const { body } = document;

        const handleScroll = () => {
            const footerHeight = (document.querySelector(`.${footerStyle.footerContainer}`)?.clientHeight as number) || 0;

            const isAtBottom = body.scrollHeight - body.scrollTop - footerHeight <= body.clientHeight;

            const enabledPagenation =
                isAtBottom &&
                !isLoading &&
                numberOfavailableProducts >= filterProductsParam.numberOfProducts &&
                numberOfavailableProducts < products.totalDocumentsWihtoutLimit;
            if (enabledPagenation) {
                setNextPage();
            }
        };

        body.addEventListener('scroll', handleScroll);
        return () => {
            body.removeEventListener('scroll', handleScroll);
        };
    }, [fetchProducts]);

    return (
        <>
            <div className={`${isLoading ? 'blur-background' : ''}`}>
                <div className={productsPageStyle.collectionTitleContainer}>
                    <span>{selectedTopic.topicName}</span>
                </div>

                <div className={`row`}>
                    <div className={`${productsPageStyle.headerColumnContainer} col-12 col-lg-6`}>
                        <span>
                            {numberOfavailableProducts} {t('productsPage.products')}
                        </span>
                    </div>
                    {!ClientConfig.isProvider && (
                        <div className={`${productsPageStyle.headerColumnContainer} col-6`}>
                            <SortProductOptions selectedKey={filterProductsParam.sortByPrice} changeSortByPriceHandler={changeSortByPriceHandler} />
                        </div>
                    )}
                    {isMobileScreen && (
                        <div className={`${productsPageStyle.headerColumnContainer} col-6`}>
                            <SideFilters sectionId={selectedTopic.topicId} changeFiltersHandler={changeFiltersHandler} />
                        </div>
                    )}
                </div>

                <div className={`${productsPageStyle.collectionContainer} row`}>
                    {isBigScreen && (
                        <div className={`col-12 col-lg-2`}>
                            <Filter sectionId={selectedTopic.topicId} changeFiltersHandler={changeFiltersHandler} />
                        </div>
                    )}
                    <div className={`col-12 col-lg-10 row`}>
                        {numberOfavailableProducts === 0 && (
                            <div className={productsPageStyle.productsNotFoundContainer}>
                                <div className={`${productsPageStyle.productsNotFoundRow} row`}>
                                    <NoProductsIcon />
                                </div>
                                <div className={`${productsPageStyle.productsNotFoundRow} row`}>
                                    <span>{t('productsPage.noProductsFound')}</span>
                                </div>
                            </div>
                        )}
                        {numberOfavailableProducts !== 0 &&
                            products.products?.map((product, i) => {
                                return (
                                    <div key={i} className={`col-6 col-md-4 col-lg-3`}>
                                        <ProductMiniCard product={product} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            {isLoading && <Spinner />}
        </>
    );
});

export default ProductsPage;
