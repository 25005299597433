import EnvUtils, { EnvKeys } from '../utils/envUtils';

const env = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_ENV);
const isProvider = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_IS_PROVIDER) === 'true';
const hoverColor = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_HOVER_COLOR);
const primaryColor = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_PRIMARY_COLOR);
const PROD_SERVER = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_PROD_SERVER);

const LOCAL_PORT = '4000';

interface IConfigEnvironment {
    apiBaseHost: string;
    isProvider: boolean;
    palette: {
        primaryColor: string;
        hoverColor: string;
    };
}

export interface IClientConfig extends IConfigEnvironment {}

const local: IConfigEnvironment = {
    apiBaseHost: `http://localhost:${LOCAL_PORT}/api`,
    isProvider,
    palette: {
        primaryColor,
        hoverColor,
    },
};

const prod: IConfigEnvironment = {
    apiBaseHost: `https://${PROD_SERVER}/api`,
    isProvider,
    palette: {
        primaryColor,
        hoverColor,
    },
};

const envConfigs = {
    local,
    prod,
};

if (env !== 'prod') console.debug('Client started with env', env);

const ClientConfig: IClientConfig = {
    // Get all environment configurations
    ...envConfigs[env as keyof typeof envConfigs],
};

export default ClientConfig;
