import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IButton from '../../components/commonComponents/button/IButton';
import { RoutesUrl } from '../../router/RoutesUrls';
import commonStyles from '../../styles/commonStyles.module.scss';
import registrationConfirmationStyle from './RegistrationConfirmation.module.scss';

const RegistrationConfirmation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={registrationConfirmationStyle.confirmationContainer}>
            <div className={registrationConfirmationStyle.box}>
                <div className={`${registrationConfirmationStyle.title} row`}>
                    <span>{t('registrationConfirmation.title')}</span>
                </div>
                <div className={`${registrationConfirmationStyle.context} row`}>
                    <span>{t('registrationConfirmation.message')}</span>
                </div>
                <div className={`${registrationConfirmationStyle.navigationContainer} row`}>
                    <IButton
                        className={commonStyles.textButton}
                        title={t('registrationConfirmation.moveToLogin')}
                        onClick={() => navigate(RoutesUrl.auth)}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default RegistrationConfirmation;
