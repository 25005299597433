import { action, makeObservable, observable } from 'mobx';
import ProductModel from '../../models/product/Product.model';
import ProductFetcher from '../../fetchers/Product.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import { CErrors } from '../../constants/constants';

class ProductStore {
    @observable
    product: ProductModel = new ProductModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    setProduct = (p: ProductModel) => (this.product = p);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    getProductById = async (id: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await ProductFetcher.getProductById(id);
            this.setProduct(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default ProductStore;
