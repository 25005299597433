import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import headerAddsStyle from '../components/header/headerAdds/HeaderAdds.module.scss';

const useScrollToTopHook = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const headerTextContainer = document.querySelector(`.${headerAddsStyle.headerTextContainer}`);
        if (headerTextContainer) {
            setTimeout(() => {
                headerTextContainer.scrollIntoView(true);
            }, 100);
        }
    }, [pathname]);
};

export default useScrollToTopHook;
