import React from 'react';
import cartItemsStyle from './CartItems.module.scss';
import CartItem from '../cartItem/CartItem';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ProductInOrderModel from '../../../models/order/ProductInOrder';

interface IProps {
    products: ProductInOrderModel[];
    isViewOnly: boolean;
}

const CartItems = (props: IProps) => {
    const { products, isViewOnly } = props;
    const { t } = useTranslation();
    return (
        <div className={`${cartItemsStyle.productsColumnContainer}`}>
            {products?.length !== 0 ? (
                <>
                    {products?.map((product, i) => {
                        return (
                            <div className={cartItemsStyle.cartItemContainer} key={i}>
                                <CartItem product={product.product} quantity={product.quantity} isViewOnly={isViewOnly} />
                                {i !== products.length - 1 ? (
                                    <div className={`${cartItemsStyle.cartItemDividerContainer} row`}>
                                        <Divider className={`${cartItemsStyle.cartItemDivider}`} />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    })}
                </>
            ) : (
                <>
                    <div className={`${cartItemsStyle.cartEmptyContainer}`}>
                        <span>{t('cartItems.cartIsEmpty')}</span>
                    </div>
                </>
            )}
        </div>
    );
};

export default CartItems;
