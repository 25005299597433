import React, { useState } from 'react';
import sectionCollapseStyle from './SectionCollapse.module.scss';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import CategoryCollapse from '../categoryCollapse/CategoryCollapse';
import SectionModel from '../../../../models/topics/Section.model';
import { useTranslation } from 'react-i18next';
import TopicModel from '../../../../models/topics/Topic.model';
import { SECTIONS_STORE } from '../../../../stores/constants';
import rootStores from '../../../../stores';
import SectionsStore from '../../../../stores/sections/Sections.store';

interface IProps {
    section: SectionModel;
    onCloseMenu: () => void;
}

const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;

const SectionCollapse = (props: IProps) => {
    const { onCloseMenu, section } = props;
    const { setSelectedTopic, setSelectedSection } = sectionsStore;
    const [isSectionCollapseOpen, setIsSectionCollapseOpen] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSectionCollapseClickHandler = () => {
        setIsSectionCollapseOpen(!isSectionCollapseOpen);
    };

    const showAllProductsHandler = (section: SectionModel) => {
        setSelectedSection(section);
        setSelectedTopic(
            new TopicModel({
                topicId: section.sectionId,
                topicName: section.sectionName,
            })
        );
        onCloseMenu();
        navigate(`products-page/${section.sectionName}`);
    };

    return (
        <>
            <ListItemButton className={sectionCollapseStyle.sectionCollapseContainer} onClick={onSectionCollapseClickHandler}>
                <ListItemText className={sectionCollapseStyle.sectionCollapseText} primary={section.sectionName} />
                {isSectionCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isSectionCollapseOpen} timeout='auto' unmountOnExit>
                <>
                    <div onClick={() => showAllProductsHandler(section)} className={sectionCollapseStyle.selectAllProductsContainer}>
                        <span>{t('sideMenu.showAllProducts', { topicName: section.sectionName })}</span>
                    </div>
                    {section.categories?.map((category) => (
                        <CategoryCollapse onCloseMenu={onCloseMenu} key={category.categoryId} category={category} />
                    ))}
                </>
            </Collapse>
        </>
    );
};

export default SectionCollapse;
