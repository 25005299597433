import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ProductInformation from '../../components/product/productInformation/ProductInformation';
import productDetailsStyle from './ProductPage.module.scss';

import { useParams } from 'react-router-dom';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import rootStores from '../../stores';
import ProductStore from '../../stores/product/Product.store';
import { PRODUCT_STORE } from '../../stores/constants';
import ToastUtils from '../../utils/ToastUtils';

const productsStore = rootStores[PRODUCT_STORE] as ProductStore;

const ProductPage = observer(() => {
    const { product, getProductById, isLoading } = productsStore;
    const { productId } = useParams();

    useEffect(() => {
        if (!product._id) {
            if (!productId) ToastUtils.error('Something went wrong...');
            else getProductById(productId);
        }
    }, []);

    return <div className={productDetailsStyle.productDetailsContainer}>{isLoading ? <Spinner /> : <ProductInformation />}</div>;
});

export default ProductPage;
