const reset = {
    resetTitle: 'איפוס הסיסמא בחשבון',
    resetExplanation: 'הכנס סיסמא חדשה לחשבון',
    changePassword: 'צור סיסמא חדשה',
    requiredField: 'שדה חובה',
    enterPassword: 'הכנס סיסמא',
    reEnterPassword: 'חזור על הסיסמא',
    invalidRePassword: 'סיסמא אינה זהה',
    passwordResetSuccessfully: 'סיסמתך עודכנה בהצלחה',
    navigateTo: 'כעת תוכל להיכנס למערכת. עבור ל-',
    login: 'דף התחברות',
};

export default reset;
