const registrationForm = {
    createAccount: 'יצירת חשבון',
    firstName: 'שם פרטי',
    phoneNumber: 'מספר טלפון',
    minDigits: 'מינימום 8 תווים',
    oneEnglishLetter: 'מינימום אות אחת באנגלית',
    email: 'אימייל',
    password: 'סיסמא',
    create: 'יצירה',
    dateOfBirth: 'תאריך לידה',
    exist: 'קיים במערכת',
    requiredField: 'שדה חובה',
    invalidEmail: 'כתובת מייל אינה תקינה',
    minAge: 'הרשמה לאתר מגיל-',
    dateError: 'אינך בגיל התואם למדיניות השימוש',
    required: 'שדה חובה',
    privacyPolicy: 'במדיניות פרטיות',
    invalidPhoneNumber: 'טלפון אינו תקין',
    privacyPolicyText: 'אנחנו נשתמש בפרטים האישיים כדי להציע לך תמיכה בתהליך באתר זה, לנהל את הגישה לחשבון וכדי לבצע פעולות נוספות כפי שמפורט',
};

export default registrationForm;
