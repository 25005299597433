import { useTranslation } from 'react-i18next';
import ordersHistoryStyle from './OrdersHistory.module.scss';
import Chip from '@mui/material/Chip';
import ClientConfig from '../../../config/index';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { observer } from 'mobx-react';
import OrderModel from '../../../models/order/Order.model';
import { formatDateOnly } from '../../../utils/dateUtils';
import IDivider from '../../commonComponents/divider/IDivider';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';

import { Currency, DevliveryOptions, Discount, PaymentOptions } from '../../../enums/enums';
import { CDiscount } from '../../../constants/constants';

interface IProps {
    order: OrderModel;
}

const OrderRowDetails = observer((props: IProps) => {
    const { order } = props;
    const { t } = useTranslation();

    const isPayOnline = order?.paymentInformation?.selectedPayment === PaymentOptions.ONLINE;
    const deliveryMethodText =
        order?.shippingDetails?.deliveryMethod === DevliveryOptions.DELIVERY
            ? t('myProfile.ordersHistory.delivery')
            : t('myProfile.ordersHistory.selfPickup');

    return (
        <div className={ordersHistoryStyle.orderRowDetails}>
            <div className={ordersHistoryStyle.headerContainer}>
                <div className={ordersHistoryStyle.orderDateContainer}>
                    <span className={ordersHistoryStyle.orderSentText}>{t('myProfile.ordersHistory.orderSent') + ' - '}</span>
                    <span className={ordersHistoryStyle.orderDateText}>{formatDateOnly(order.dateAndTime)}</span>
                </div>
            </div>

            <IDivider className={ordersHistoryStyle.divider} />

            <div className={ordersHistoryStyle.orderDetails}>
                <div className={ordersHistoryStyle.chargeWhoTextContainer}>
                    <span className={ordersHistoryStyle.chargeWhoTitleText}>{t('myProfile.ordersHistory.chargeWho')}</span>
                    <span className={ordersHistoryStyle.chargeWhoText}>{`${order.user.firstName} ${order.user.lastName}`}</span>
                    <span className={ordersHistoryStyle.chargeWhoText}>{order.user.phoneNumber}</span>
                    <span className={ordersHistoryStyle.chargeWhoText}>{order.user.email}</span>
                </div>
                {!ClientConfig.isProvider && (
                    <div className={ordersHistoryStyle.chargeWhoTextContainer}>
                        <span className={ordersHistoryStyle.chargeWhoTitleText}>{t('myProfile.ordersHistory.totalAmountSummary')}</span>
                        <span className={ordersHistoryStyle.chargeWhoText}>{`${t(
                            'myProfile.ordersHistory.totalProductsAmount'
                        )}: ${order.paymentAmount.productsAmount.toLocaleString()}${Currency.SHEKEL}`}</span>
                        <span className={ordersHistoryStyle.chargeWhoText}>{`${t('myProfile.ordersHistory.totalFeeAmount')}: ${
                            order.paymentAmount.shippingAmount
                        }${Currency.SHEKEL}`}</span>
                        <span className={ordersHistoryStyle.chargeWhoText}>{`${t(
                            'myProfile.ordersHistory.totalOrderAmount'
                        )}: ${order.paymentAmount.totalAmount.toLocaleString()}${Currency.SHEKEL}`}</span>
                        <IDivider className={ordersHistoryStyle.divider} />
                        <span className={ordersHistoryStyle.chargeWhoTitleText}>{t('myProfile.ordersHistory.paymentMethod')}</span>
                        <div className={ordersHistoryStyle.paymentDetailsContainer}>
                            {isPayOnline ? <CreditCardIcon /> : <LocalAtmIcon />}
                            {isPayOnline ? (
                                <span>{t('myProfile.ordersHistory.creditCard')}</span>
                            ) : (
                                <span>{t('myProfile.ordersHistory.payInStore')}</span>
                            )}

                            <span>{`${Currency.SHEKEL}${order.paymentAmount.totalAmount.toLocaleString()}`}</span>
                        </div>
                        {order.coupon?.code && (
                            <>
                                <IDivider className={ordersHistoryStyle.divider} />
                                <span className={ordersHistoryStyle.chargeWhoTitleText}>{t('myProfile.ordersHistory.coupon')}</span>
                                <span>{order.coupon.code}</span>
                                <span>{`${order.coupon.discount.type === CDiscount.FIXED ? Discount.FIXED : Discount.PERCENTAGE}${
                                    order.coupon.discount.amount
                                } ${t('myProfile.ordersHistory.discount')}`}</span>
                            </>
                        )}
                    </div>
                )}
                <div className={ordersHistoryStyle.orderSentToTextContainer}>
                    {order.shippingDetails.deliveryMethod === DevliveryOptions.DELIVERY && (
                        <>
                            <span className={ordersHistoryStyle.orderSentToTitleText}>{t('myProfile.ordersHistory.orderSentTo')}</span>
                            <IDivider className={ordersHistoryStyle.divider} />
                        </>
                    )}

                    <div className={ordersHistoryStyle.delveryMethodContainer}>
                        {order.shippingDetails.deliveryMethod === DevliveryOptions.DELIVERY ? <LocalShippingIcon /> : <StoreIcon />}
                        <span>{deliveryMethodText}</span>
                        <span className={ordersHistoryStyle.sentToText}>{order.shippingDetails.address}</span>
                    </div>
                </div>
            </div>

            <div className={ordersHistoryStyle.orderProducts}></div>

            <IDivider className={ordersHistoryStyle.divider} />

            <div className={ordersHistoryStyle.orderProductsContainer}>
                <div className={ordersHistoryStyle.productsTitleTextContainer}>
                    <ShoppingBagIcon />
                    <span className={ordersHistoryStyle.productsTitleText}>{t('myProfile.ordersHistory.productsTitle')}</span>
                </div>

                <IDivider className={ordersHistoryStyle.divider} />

                <div className={ordersHistoryStyle.productsContainer}>
                    {order.products?.map((product, index) => (
                        <div key={product.product._id}>
                            <div className={ordersHistoryStyle.productContainer}>
                                <img className={ordersHistoryStyle.imageContainer} src={product.product.images[0]} alt='' />
                                <span className={ordersHistoryStyle.productsNameText}>{product.product.name}</span>
                                <span className={ordersHistoryStyle.productsAmountText}>{`${product.quantity}X`}</span>
                                {!ClientConfig.isProvider && (
                                    <span className={ordersHistoryStyle.productsPriceText}>
                                        {Currency.SHEKEL}
                                        {product.product.updatedPrice.toLocaleString()}
                                    </span>
                                )}
                            </div>
                            <div className={ordersHistoryStyle.variantsContainer}>
                                {product.product?.variants?.map((variant, i) => (
                                    <Chip
                                        label={
                                            <span>
                                                {variant.label}:{variant.options[0].value}
                                                {variant.options[0].extraPrice !== 0 && (
                                                    <>
                                                        <span> </span>
                                                        <span className={ordersHistoryStyle.variantExtraPrice}>
                                                            +{Currency.SHEKEL}
                                                            {variant.options[0].extraPrice.toLocaleString()}
                                                        </span>
                                                    </>
                                                )}
                                            </span>
                                        }
                                        variant='outlined'
                                    />
                                ))}
                            </div>
                            {index !== order.products.length - 1 && <IDivider className={ordersHistoryStyle.divider} />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default OrderRowDetails;
