import { observer } from 'mobx-react';
import ClientConfig from '../../../config/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column, RowData } from '../../../interfaces/interfaces';
import OrderModel from '../../../models/order/Order.model';
import { RoutesUrl } from '../../../router/RoutesUrls';
import { ORDERS_STORE } from '../../../stores/constants';
import rootStores from '../../../stores/index';
import OrdersStore from '../../../stores/orders/Orders.store';
import commonStyle from '../../../styles/commonStyles.module.scss';
import ICollapsibleTable from '../../collapsibleTable/ICollapsibleTable';
import IButton from '../../commonComponents/button/IButton';
import OrderRowDetails from './OrderRowDetails';
import ordersHistoryStyle from './OrdersHistory.module.scss';

const orderStore = rootStores[ORDERS_STORE] as OrdersStore;

const OrdersHistory = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { orders } = orderStore;

    const columns: Column[] = [
        { id: '_id', label: t('myProfile.ordersHistory.orderId'), align: 'center' },
        { id: 'date', label: t('myProfile.ordersHistory.date'), align: 'center', props: { isDateString: true } },
        { id: 'status', label: t('myProfile.ordersHistory.status'), align: 'center' },
        { id: 'total', label: t('myProfile.ordersHistory.total'), align: 'center', props: { showCurrencySymbol: true } },
    ];

    const providerColumns: Column[] = [
        { id: '_id', label: t('myProfile.ordersHistory.orderId'), align: 'center' },
        { id: 'date', label: t('myProfile.ordersHistory.date'), align: 'center', props: { isDateString: true } },
        { id: 'status', label: t('myProfile.ordersHistory.status'), align: 'center' },
    ];

    const rows: RowData[] = orders.map((order: OrderModel) => ({
        _id: order.shortId,
        date: order.dateAndTime,
        status: order.orderStatus.find((o) => o.active)?.label,
        total: order.paymentAmount.totalAmount.toLocaleString(),
        details: <OrderRowDetails order={order} />,
    }));

    return (
        <div className={ordersHistoryStyle.ordersPageContainer}>
            <div className={ordersHistoryStyle.titleContainer}>
                <span className={ordersHistoryStyle.ordersTitle}>{t('myProfile.myOrders')}</span>
            </div>
            <IButton className={`${commonStyle.textButton}`} title={t('editUserForm.goBack')} onClick={() => navigate(RoutesUrl.profile)} />
            <div className={ordersHistoryStyle.ordersContainer}>
                <ICollapsibleTable columns={!ClientConfig.isProvider ? columns : providerColumns} rows={rows} collapsible />
            </div>
        </div>
    );
});

export default OrdersHistory;
