import ClientConfig from '../../../config/index';
import { Currency } from '../../../enums/enums';
import Product from '../../../models/product/Product.model';
import NumberInput from '../../commonComponents/numberInput/NumberInput';
import cartItemStyle from './CartItem.module.scss';
import Chip from '@mui/material/Chip';

interface IProps {
    product: Product;
    quantity: number;
    isViewOnly: boolean;
}

const CartItem = (props: IProps) => {
    const { product, quantity, isViewOnly } = props;

    return (
        <div className={`${cartItemStyle.cartItemContainer}`}>
            <div className={`${cartItemStyle.titleContainer} row`}>
                <span>{product.name}</span>
            </div>
            <div className={`${cartItemStyle.context} row`}>
                <div className={`${cartItemStyle.imageColumnContainer} col-3`}>
                    <img src={product.images[0]} alt='IMG' />
                </div>
                <div className={`${cartItemStyle.descriptionColumnContainer} col-6`}>
                    <div>
                        {product.variants.map((variant, i) => (
                            <div key={i} className={`${cartItemStyle.variantsContainer} row`}>
                                <Chip
                                    label={
                                        <span>
                                            {variant.label}:{variant.options[0].value}
                                            {variant.options[0].extraPrice !== 0 && (
                                                <>
                                                    <span> </span>
                                                    <span className={cartItemStyle.variantExtraPrice}>
                                                        +{Currency.SHEKEL}
                                                        {variant.options[0].extraPrice.toLocaleString()}
                                                    </span>
                                                </>
                                            )}
                                        </span>
                                    }
                                    variant='outlined'
                                />
                            </div>
                        ))}
                        <div className={`${cartItemStyle.counterContainer} row`}>
                            {isViewOnly ? (
                                <div className={cartItemStyle.productQuantityCycleContainer}>
                                    <div className={cartItemStyle.productQuantityContainer}>{quantity}</div>
                                </div>
                            ) : (
                                <NumberInput product={product} quantity={quantity} />
                            )}
                        </div>
                    </div>
                </div>
                {!ClientConfig.isProvider && (
                    <div className={`${cartItemStyle.priceColumnContainer} col-3`}>
                        <div>
                            <span>
                                {Currency.SHEKEL}
                                {product.updatedPrice.toLocaleString()}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CartItem;
