import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react';
import cartStyle from './CartDrawer.module.scss';
import './CartDrawer.customization.css';
import { useNavigate } from 'react-router-dom';
import rootStores from '../../stores';
import { CART_STORE } from '../../stores/constants';
import CartStore from '../../stores/cart/Cart.store';
import CartTotalPrice from '../cart/cartTotalPrice/CartTotalPrice';
import { useTranslation } from 'react-i18next';
import { RoutesUrl } from '../../router/RoutesUrls';
import CartItems from '../cart/cartItems/CartItems';
import IButton from '../commonComponents/button/IButton';
import commonStyle from '../../styles/commonStyles.module.scss';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

interface IProps {
    isCartOpen: boolean;
    onClose: () => void;
}

const CartDrawer = observer((props: IProps) => {
    const { isCartOpen, onClose } = props;
    const { products } = cartStoreStore;
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        if (isCartOpen) {
            document.body.classList.add('cart-open');
        } else {
            document.body.classList.remove('cart-open');
        }
    }, [isCartOpen]);

    const cartNavigationHandler = () => {
        onClose();
        navigate(RoutesUrl.cart);
    };

    return (
        <Drawer title={t('cartDrawer.shoppingCart')} placement='left' onClose={onClose} open={isCartOpen}>
            <div className={`${cartStyle.cartContextContainer} row`}>
                {products?.length !== 0 ? (
                    <>
                        <div className={`${cartStyle.cartItemsContainer} row`}>
                            <CartItems products={products} isViewOnly={false} />
                        </div>
                        <div className={`${cartStyle.cartSummaryContainer}`}>
                            <CartTotalPrice />
                            <div className={`${cartStyle.cartButtonContainer} row`}>
                                <IButton
                                    title={t('cartDrawer.cart')}
                                    onClick={cartNavigationHandler}
                                    className={`${cartStyle.cartButton} ${commonStyle.defaultButton}`}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={`${cartStyle.cartEmptyContainer} row`}>
                        <span> {t('cartDrawer.cartIsEmpty')}</span>
                    </div>
                )}
            </div>
        </Drawer>
    );
});

export default CartDrawer;
