import React from 'react';
import { observer } from 'mobx-react';
import ourServicesourServicesStyle from './OurServices.module.scss';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useTranslation } from 'react-i18next';
import rootStores from '../../stores';
import { STORE_INFORMATION_STORE } from '../../stores/constants';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import { OurStoreServices } from '../../enums/enums';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const OurServices = observer(() => {
    const { storeInformation } = storeInformationStore;
    const { ourServices } = storeInformation;
    const { t } = useTranslation();

    const renderIconByType = (type: string) => {
        switch (type) {
            case OurStoreServices.STORE:
                return <LocalGroceryStoreIcon className={ourServicesourServicesStyle.ourServicesIcons} />;
            case OurStoreServices.SERVICE:
                return <HeadsetMicIcon className={ourServicesourServicesStyle.ourServicesIcons} />;
            case OurStoreServices.DELIVERY:
                return <DeliveryDiningIcon className={ourServicesourServicesStyle.ourServicesIcons} />;
            default:
                return null;
        }
    };

    return (
        <div className={ourServicesourServicesStyle.ourServicesContainer}>
            <div className={ourServicesourServicesStyle.ourServicesTitleContainer}>
                <span>{t('ourServices.ourServices')}</span>
            </div>
            <div className={`${ourServicesourServicesStyle.ourServicesContextContainer} row`}>
                {ourServices?.map((service, i) => (
                    <div key={i} className={`${ourServicesourServicesStyle.ourServicesColumnContainer} col-12 col-md-4`}>
                        <div className={ourServicesourServicesStyle.iconContainer}>{renderIconByType(service?.type)}</div>
                        <span className={ourServicesourServicesStyle.ourServicesTitleText}>{service?.name}</span>
                        <span className={ourServicesourServicesStyle.ourServicesDescriptionText}>{service?.description}</span>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default OurServices;
