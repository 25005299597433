const loginForm = {
    login: 'התחברות',
    invalid: 'שם משתמש או סיסמא שגויים',
    email: 'אימייל',
    password: 'סיסמא',
    signin: 'כניסה',
    forgotPassword: 'שכחת סיסמא?',
    requiredField: 'שדה חובה',
    invalidEmail: 'כתובת מייל אינה תקינה',
};

export default loginForm;
