import React from 'react';
// import sortProductOptionsStyle from './SortProductOptions.module.scss';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../components/commonComponents/dropdown/Dropdown';
import { ISelectOption } from '../../interfaces/interfaces';

interface IProps {
    changeSortByPriceHandler: (option: ISelectOption) => void;
    selectedKey: number | null;
}

const SortProductOptions = (props: IProps) => {
    const { changeSortByPriceHandler, selectedKey } = props;

    const { t } = useTranslation();

    const sortOptions = [
        {
            key: 1,
            label: t('sortProductOptions.priceLowToHigh'),
        },
        {
            key: -1,
            label: t('sortProductOptions.priceHighToLow'),
        },
    ];

    return (
        <Dropdown
            placeholder={t('sortProductOptions.orderBy')}
            options={sortOptions}
            selectedKey={selectedKey}
            onSelect={changeSortByPriceHandler}
            disabled={false}
        />
    );
};

export default SortProductOptions;
