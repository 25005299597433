import deliveryPolicyStyle from './DeliveryPolicy.module.scss';
import { useEffect } from 'react';
import rootStores from '../../stores';
import { SYSTEM_STORE } from '../../stores/constants';
import SystemStore from '../../stores/system/System.store';
import NotFound from '../notFound/NotFound';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const DeliveryPolicy = () => {
    const { getDeliveryPolicyPage, deliveryPolicyPage } = systemStore;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getDeliveryPolicyPage();
            } catch (error) {}
        };

        fetchData();
    }, [getDeliveryPolicyPage]);

    return (
        <>
            {deliveryPolicyPage ? (
                <div className={deliveryPolicyStyle.deliveryPolicyContainer}>
                    <div dangerouslySetInnerHTML={{ __html: deliveryPolicyPage }} />
                </div>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default DeliveryPolicy;
