export default class TopicModel {
    topicId!: string;

    topicName!: string;

    constructor(topic?: TopicModel) {
        if (topic) {
            this.topicId = topic.topicId;
            this.topicName = topic.topicName;
        }
    }
}
