import React from 'react';
import { observer } from 'mobx-react';
import checkoutProviderOrderStyle from './CheckoutProviderOrder.module.scss';
import { useTranslation } from 'react-i18next';

const CheckoutProviderOrder = observer(() => {
    const { t } = useTranslation();

    return (
        <div className={checkoutProviderOrderStyle.checkoutProviderOrderContainer}>
            <span>{t('checkoutProviderOrder.recaptchaConfirmation')}</span>
        </div>
    );
});

export default CheckoutProviderOrder;
