import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import CartItems from '../../components/cart/cartItems/CartItems';
import CartTotalPrice from '../../components/cart/cartTotalPrice/CartTotalPrice';
import CheckoutItemsCollapse from '../../components/checkout/checkoutItemsCollapse/CheckoutItemsCollapse';
import CheckoutStepper from '../../components/checkout/checkoutStepper/CheckoutStepper';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import rootStores from '../../stores';
import CartStore from '../../stores/cart/Cart.store';
import { CART_STORE } from '../../stores/constants';
import checkoutStyle from './Checkout.module.scss';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const Checkout = observer(() => {
    const { products, isLoading } = cartStoreStore;
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });
    const cartIsEmpty = products.length === 0;

    return (
        <div className={`${checkoutStyle.checkoutContainer} ${isLoading ? 'blur-background' : ''} row`}>
            <div className={`${checkoutStyle.checkoutStepperContainer} col-12 col-md-6`}>
                <CheckoutStepper />
            </div>
            {!cartIsEmpty && (
                <div className={`${checkoutStyle.checkoutSummaryContainer} col-12 col-md-6`}>
                    <CartTotalPrice />
                    {isBigScreen ? (
                        <div className={checkoutStyle.cartItemsContainer}>
                            <CartItems products={products} isViewOnly={true} />
                        </div>
                    ) : (
                        <div className={checkoutStyle.collapseContainer}>
                            <CheckoutItemsCollapse products={products} />
                        </div>
                    )}
                </div>
            )}
            {isLoading && <Spinner />}
        </div>
    );
});

export default Checkout;
