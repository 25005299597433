import React from 'react';
import { observer } from 'mobx-react';
import informationStyle from './Information.module.scss';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { useTranslation } from 'react-i18next';
import rootStores from '../../stores';
import CartStore from '../../stores/cart/Cart.store';
import { CART_STORE } from '../../stores/constants';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const Information = observer(() => {
    const { t } = useTranslation();
    const { minAmountForFreeShipping } = cartStoreStore;

    const information = [
        {
            icon: <LockIcon className={informationStyle.icon} />,
            title: t('information.securePurchaseTitle'),
            context: t('information.securePurchaseContext'),
        },
        {
            icon: <LocalShippingIcon className={informationStyle.icon} />,
            title: t('information.freeShippingTitle'),
            context: t('information.freeShippingContext', { min: minAmountForFreeShipping }),
        },
        {
            icon: <AssignmentReturnIcon className={informationStyle.icon} />,
            title: t('information.cancelationPolicyTitle'),
            context: t('information.cancelationPolicyContext'),
        },
    ];

    return (
        <div className={informationStyle.informationContainer}>
            <div className={`row`}>
                {information?.map((info, i) => (
                    <div key={i} className={`${informationStyle.informationColumnContainer} col-12 col-md-4`}>
                        <div className={`${informationStyle.informationRow} row`}>{info.icon}</div>
                        <div className={`${informationStyle.informationRow} row`}>
                            <span className={informationStyle.title}>{info.title}</span>
                        </div>
                        <div className={`${informationStyle.informationRow} row`}>
                            <span className={informationStyle.context}>{info.context}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Information;
