import React, { useEffect, useState } from 'react';
import resetPasswordStyle from './ResetPassword.module.scss';
import Reset from '../../../components/auth/reset/Reset';
import SendEmail from '../../../components/auth/sendEmail/SendEmail';

const ResetPassword = () => {
    const [token, setToken] = useState<string | null>(null);
    useEffect(() => {
        const getTokenQueryParam = async (tokenParam: string) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const tokenValue = urlSearchParams.get(tokenParam);
            return tokenValue;
        };

        const fetchToken = async () => {
            setToken(await getTokenQueryParam('token'));
        };

        fetchToken();
    }, []);

    return <div className={resetPasswordStyle.resetPasswordContainer}>{token ? <Reset token={token} /> : <SendEmail />}</div>;
};

export default ResetPassword;
