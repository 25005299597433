import Divider from '@mui/material/Divider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CartItems from '../../components/cart/cartItems/CartItems';
import CartTitle from '../../components/cart/cartTitle/CartTitle';
import CartTotalPrice from '../../components/cart/cartTotalPrice/CartTotalPrice';
import DeliveryOptions from '../../components/cart/deliveryOptions/DeliveryOptions';
import { RoutesUrl } from '../../router/RoutesUrls';
import rootStores from '../../stores';
import CartStore from '../../stores/cart/Cart.store';
import { CART_STORE } from '../../stores/constants';
import cartStyle from './Cart.module.scss';
import commonStyle from '../../styles/commonStyles.module.scss';
import IButton from '../../components/commonComponents/button/IButton';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const Cart = observer(() => {
    const { products, setDeliveryOption, deliveryOption } = cartStoreStore;
    const continueDisabled = deliveryOption ? false : true;
    const cartIsEmpty = products.length === 0;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const onDeliveryOptionClick = (option: string) => {
        setDeliveryOption(option);
    };

    const checkoutNavigationHandler = () => {
        navigate(RoutesUrl.checkout);
    };

    return (
        <>
            <CartTitle />
            <div className={`${cartStyle.cartContextContainer} row`}>
                <div className='col-12 col-md-6'>
                    <CartItems products={products} isViewOnly={false} />
                </div>

                {!cartIsEmpty && (
                    <div className={`${cartStyle.checkOutColumnContainer} col-12 col-md-6`}>
                        <div className={`${cartStyle.checkOutBox}`}>
                            <DeliveryOptions onDeliveryOptionClick={onDeliveryOptionClick} chosenDelivery={deliveryOption} />

                            <Divider className={cartStyle.divider} />

                            <CartTotalPrice />

                            <div className={`${cartStyle.checkoutButtonContainer} row`}>
                                <IButton
                                    onClick={checkoutNavigationHandler}
                                    title={t('cart.continueShopping')}
                                    className={`${commonStyle.defaultButton} ${cartStyle.cartButton} ${
                                        continueDisabled ? cartStyle.cartButtonDisabled : ''
                                    } `}
                                    disabled={continueDisabled}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});

export default Cart;
