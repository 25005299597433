import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface IProps {
    captchaRef: ReCAPTCHA | null;
}

const Recaptcha = (props: IProps) => {
    const { captchaRef } = props;

    return <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY} hl='he' />;
};

export default Recaptcha;
