import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import SectionsFetcher from '../../fetchers/Sections.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import SectionModel from '../../models/topics/Section.model';
import FilterProductsParamModel from '../../models/filters/FilterProductsParam.model';
import { CErrors } from '../../constants/constants';
import TopicModel from '../../models/topics/Topic.model';

class SectionsStore {
    @observable
    sections: SectionModel[] = [];

    @observable
    selectedSection: SectionModel = new SectionModel();

    @observable
    selectedTopic: TopicModel = new TopicModel();

    @observable
    filterProductsParam: FilterProductsParamModel = new FilterProductsParamModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, { name: 'SectionsStore', properties: ['selectedSection', 'sections', 'selectedTopic'], storage: window.localStorage });
    }

    @action
    setSections = (sections: SectionModel[]) => (this.sections = sections);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setSelectedTopic = (selectedTopic: TopicModel) => {
        this.filterProductsParam = {
            ...this.filterProductsParam,
            topicId: selectedTopic.topicId,
            page: 1,
            sortByPrice: null,
            selectedFilters: {},
        };
        this.selectedTopic = selectedTopic;
    };

    @action
    setSelectedSection = (selectedSection: SectionModel) => {
        this.selectedSection = selectedSection;
    };

    @action
    setSelectedFilters = (selectedFilter: { [key: string]: boolean }) => {
        const selectedFilterKey = Object.keys(selectedFilter)[0];
        const selectedFilterValue = selectedFilter[selectedFilterKey];

        const newSelectedFilters = { ...this.filterProductsParam.selectedFilters };
        selectedFilter[selectedFilterKey]
            ? (newSelectedFilters[selectedFilterKey] = selectedFilterValue)
            : delete newSelectedFilters[selectedFilterKey];

        this.filterProductsParam = {
            ...this.filterProductsParam,
            page: 1,
            selectedFilters: newSelectedFilters,
        };
    };

    @action
    setNextPage = () => {
        this.filterProductsParam = {
            ...this.filterProductsParam,
            page: this.filterProductsParam.page + 1,
        };
    };

    @action
    setSortByPrice = (sortByPriceParam: number | null) => {
        this.filterProductsParam = {
            ...this.filterProductsParam,
            sortByPrice: sortByPriceParam,
        };
    };

    @action
    getSections = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SectionsFetcher.getSections();
            this.setSections(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get sectionsSelectionOption() {
        return this.sections.map((s) => ({
            label: s.sectionName,
            value: s.sectionId,
        }));
    }
}

export default SectionsStore;
