import ClientConfig from '../../../config/index';
import { observer } from 'mobx-react';
import cartTotalPriceStyle from './CartTotalPrice.module.scss';
import { useTranslation } from 'react-i18next';
import rootStores from '../../../stores';
import { CART_STORE } from '../../../stores/constants';
import CartStore from '../../../stores/cart/Cart.store';
import { Currency } from '../../../enums/enums';
import Coupon from '../../coupon/Coupon';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const CartTotalPrice = observer(() => {
    const { cartAmount } = cartStoreStore;
    const { t } = useTranslation();
    return !ClientConfig.isProvider ? (
        <div>
            <div className={`${cartTotalPriceStyle.subTotalContainer} row`}>
                <div className={`${cartTotalPriceStyle.subTotalColumn} col-8`}>
                    <span>{t('cartTotalPrice.productsAmount')}</span>
                </div>
                <div className={`${cartTotalPriceStyle.totalPriceColumn} col-4`}>
                    <span>
                        {Currency.SHEKEL}
                        {cartAmount.productsAmount.toLocaleString()}
                    </span>
                </div>
            </div>
            <div className={`${cartTotalPriceStyle.subTotalContainer} row`}>
                <div className={`${cartTotalPriceStyle.subTotalColumn} col-8`}>
                    <span>{t('cartTotalPrice.shipping')}</span>
                </div>
                <div className={`${cartTotalPriceStyle.totalPriceColumn} col-4`}>
                    <span>
                        {Currency.SHEKEL}
                        {cartAmount.shippingAmount}
                    </span>
                </div>
            </div>
            <div className={`${cartTotalPriceStyle.subTotalContainer} row`}>
                <div className={`${cartTotalPriceStyle.subTotalColumn} col-8`}>
                    <span>{t('cartTotalPrice.total')}</span>
                </div>
                <div className={`${cartTotalPriceStyle.totalPriceColumn} col-4`}>
                    <span>
                        {Currency.SHEKEL}
                        {cartAmount.totalAmount.toLocaleString()}
                    </span>
                </div>
            </div>
            <div className={cartTotalPriceStyle.couponContainer}>
                <Coupon />
            </div>
        </div>
    ) : (
        <></>
    );
});

export default CartTotalPrice;
