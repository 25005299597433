import { observer } from 'mobx-react';
import commonStyle from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import editUserStyle from './EditUser.module.scss';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IEditUserPayload } from '../../../interfaces/interfaces';

import getPickedDateWithoutOffset from '../../../actions/GetPickedDateWithoutOffset';
import rootStores from '../../../stores';
import SystemStore from '../../../stores/system/System.store';
import UserStore from '../../../stores/user/User.store';
import { SYSTEM_STORE, USER_STORE } from '../../../stores/constants';

import dayjs from 'dayjs';
import { RoutesUrl } from '../../../router/RoutesUrls';

import IDatePicker from '../../commonComponents/datePicker/IDatePicker';
import Spinner from '../../commonComponents/spinner/Spinner';
import ITextInput from '../../commonComponents/textInput/TextInput';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;
const userStore = rootStores[USER_STORE] as UserStore;

interface IProps {
    editUserHandler: (payload: IEditUserPayload) => Promise<any>;
    setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditUserForm = observer((props: IProps) => {
    const { editUserHandler, setIsOnEditMode } = props;
    const { user, isLoading } = userStore;

    const {
        system: {
            legalcy: { minAgeForRegistration },
        },
    } = systemStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('editUserForm.invalidEmail')).required(t('editUserForm.requiredField')),
        firstName: yup.string().required(t('editUserForm.requiredField')),
        lastName: yup.string().required(t('editUserForm.requiredField')),
        phoneNumber: yup
            .string()
            .required(t('editUserForm.requiredField'))
            .matches(/^(05\d{8}|9725\d{8}|97205\d{8})$/, { message: t('editUserForm.invalidPhoneNumber'), excludeEmptyString: true }),
        dateOfBirth: yup
            .date()
            .nullable()
            .required(t('editUserForm.requiredField'))
            .test(function (value) {
                const currentDate = dayjs();
                const userBirthDate = dayjs(value);
                const age = currentDate.diff(userBirthDate, 'years');
                return (
                    age > minAgeForRegistration ||
                    (age === minAgeForRegistration && currentDate.isSame(userBirthDate, 'day') && currentDate.isAfter(userBirthDate, 'day'))
                );
            }),
    });

    const formik = useFormik({
        initialValues: {
            email: user.email,
            phoneNumber: user.phoneNumber,
            firstName: user.firstName,
            lastName: user.lastName,
            dateOfBirth: dayjs(user.dateOfBirth),
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let dateOfBirth = '';
            if (values.dateOfBirth) dateOfBirth = getPickedDateWithoutOffset(values.dateOfBirth.toString());

            const isSuccess = await editUserHandler({
                userId: user.id,
                email: values.email,
                phoneNumber: values.phoneNumber,
                firstName: values.firstName,
                lastName: values.lastName,
                dateOfBirth,
            });
            if (isSuccess) {
                navigate(RoutesUrl.profile);
            }
        },
    });

    return (
        <div className={editUserStyle.editFormContainer}>
            <div className={editUserStyle.editFormContext}>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <div className={editUserStyle.editFormTitle}>
                            <p>{t('editUserForm.editAccount')}</p>
                        </div>

                        <div className={editUserStyle.editFormInputContainer}>
                            <div className={editUserStyle.editFormInputFiled}>
                                <ITextInput
                                    size='small'
                                    inputLabel={t('editUserForm.firstName')}
                                    value={formik.values.firstName}
                                    handleOnChange={formik.handleChange('firstName')}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    isFullWidth={true}
                                    variant='outlined'
                                />
                            </div>
                        </div>

                        <div className={editUserStyle.editFormInputContainer}>
                            <div className={editUserStyle.editFormInputFiled}>
                                <ITextInput
                                    size='small'
                                    inputLabel={t('editUserForm.lastName')}
                                    value={formik.values.lastName}
                                    handleOnChange={formik.handleChange('lastName')}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    isFullWidth={true}
                                    variant='outlined'
                                />
                            </div>
                        </div>

                        <div className={editUserStyle.editFormInputContainer}>
                            <div className={editUserStyle.editFormInputFiled}>
                                <ITextInput
                                    size='small'
                                    inputLabel={t('editUserForm.email')}
                                    value={formik.values.email}
                                    handleOnChange={formik.handleChange('email')}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    isFullWidth={true}
                                    variant='outlined'
                                />
                            </div>
                        </div>

                        <div className={editUserStyle.editFormInputContainer}>
                            <div className={editUserStyle.editFormInputFiled}>
                                <ITextInput
                                    type='phoneNumber'
                                    size='small'
                                    inputLabel={t('editUserForm.phoneNumber')}
                                    value={formik.values.phoneNumber}
                                    handleOnChange={formik.handleChange('phoneNumber')}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    isFullWidth={true}
                                    variant='outlined'
                                />
                            </div>
                        </div>

                        <div className={editUserStyle.editFormInputContainer}>
                            <div className={`${editUserStyle.editFormInputFiled}`}>
                                <IDatePicker
                                    inputLabel={t('editUserForm.dateOfBirth')}
                                    disableFuture
                                    value={formik.values.dateOfBirth}
                                    handleOnChange={(value) => formik.setFieldValue('dateOfBirth', value)}
                                    helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth ? t('editUserForm.dateError') : ''}
                                />
                            </div>
                        </div>
                        <div className={editUserStyle.editFormSignIn}>
                            <IButton className={`${commonStyle.defaultButton}`} title={t('editUserForm.update')} type={'submit'} />
                        </div>

                        <div className={editUserStyle.editFormSignIn}>
                            <IButton
                                className={`${commonStyle.textButton}`}
                                title={t('editUserForm.goBack')}
                                onClick={() => setIsOnEditMode(false)}
                            />
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
});

export default EditUserForm;
