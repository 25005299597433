export default class LoginCredentials {
    email: string = '';
    password: string = '';

    constructor(loginCredentials?: LoginCredentials) {
        if (loginCredentials) {
            this.email = loginCredentials.email;
            this.password = loginCredentials.password;
        }
    }
}
