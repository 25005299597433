export enum DevliveryOptions {
    DELIVERY = 'delivery',
    SELF_PICKUP = 'selfPickup',
}

export enum PaymentOptions {
    STORE = 'store',
    ONLINE = 'online',
}

export enum Currency {
    SHEKEL = '₪',
}

export enum Discount {
    FIXED = '₪',
    PERCENTAGE = '%',
}

export enum PaymentMethod {
    CARD = 'card',
    CASH = 'cash',
    BIT = 'bit',
}

export enum OurStoreServices {
    STORE = 'store',
    SERVICE = 'service',
    DELIVERY = 'delivery',
    ADDRESS = 'address',
}
