import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';

const systemPrefix = 'systemManagement';

class SystemFetchers extends BaseHttpFetcher {
    async getSystemSettings(): Promise<any> {
        return await this.get(`${systemPrefix}/settings`);
    }

    async getPrivacyPolicyPage(): Promise<any> {
        return await this.get(`${systemPrefix}/privacyPolicy`);
    }

    async getSiteUsagePolicyPage(): Promise<any> {
        return await this.get(`${systemPrefix}/siteUsagePolicy`);
    }

    async getDeliveryPolicyPage(): Promise<any> {
        return await this.get(`${systemPrefix}/deliveryPolicy`);
    }
}

const systemFetcherInstance = new SystemFetchers('/', ClientConfig.apiBaseHost);
export default systemFetcherInstance;
