export default class ShippingDetailsModel {
    address: string = '';

    deliveryMethod: string = '';

    constructor(sdm?: ShippingDetailsModel) {
        if (sdm) {
            this.address = sdm.address;
            this.deliveryMethod = sdm.deliveryMethod;
        }
    }
}
