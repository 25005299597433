import { observer } from 'mobx-react';
import { useState } from 'react';
import ClientConfig from '../../config/index';
import { useTranslation } from 'react-i18next';
import LoginForm from '../../components/auth/login/LoginForm';
import RegistrationForm from '../../components/auth/registration/RegistrationForm';
import IButton from '../../components/commonComponents/button/IButton';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import rootStores from '../../stores';
import AuthStore from '../../stores/auth/Auth.store';
import { AUTH_STORE } from '../../stores/constants';
import commonStyle from '../../styles/commonStyles.module.scss';
import authStyle from './Auth.module.scss';

const authStore = rootStores[AUTH_STORE] as AuthStore;

const Auth = observer(() => {
    const { login, registration, isLoading } = authStore;
    const [isLoginComponent, setIsLoginComponent] = useState(true);

    const { t } = useTranslation();

    const switchComponent = () => {
        setIsLoginComponent(!isLoginComponent);
    };

    return (
        <>
            <div className={`${authStyle.authContainer} ${isLoading ? 'blur-background' : ''} `}>
                {isLoginComponent ? (
                    <LoginForm loginHandler={login} />
                ) : !ClientConfig?.isProvider ? (
                    <RegistrationForm registrationHandler={registration} />
                ) : (
                    <div className={authStyle.providerContainer}>
                        <span>{t('auth.contactProvider')}</span>
                    </div>
                )}
                <div className={authStyle.authSwitchComponentContainer}>
                    <IButton
                        className={`${commonStyle.textButton}`}
                        onClick={switchComponent}
                        title={isLoginComponent ? t('auth.createAccount') : t('auth.login')}
                        disabled={false}
                    />
                </div>
            </div>
            {isLoading && <Spinner />}
        </>
    );
});

export default Auth;
