import { Route, Routes } from 'react-router-dom';
import Addresses from '../components/myProfile/addresses/Addresses';
import OrdersHistory from '../components/myProfile/ordersHistory/OrdersHistory';
import Auth from '../screens/auth/Auth';
import ResetPassword from '../screens/auth/restPassword/ResetPassword';
import Cart from '../screens/cart/Cart';
import Checkout from '../screens/checkout/Checkout';
import Home from '../screens/home/Home';
import ProductPage from '../screens/productPage/ProductPage';
import ProductsPage from '../screens/productsPage/ProductsPage';
import Profile from '../screens/profile/Profile';
import RegistrationConfirmation from '../screens/registrationConfirmation/RegistrationConfirmation';
import PrivateRoute from './PrivateRoute';
import { RoutesUrl } from './RoutesUrls';
import PrivacyPolicy from '../screens/privacyPolicy/PrivacyPolicy';
import DeliveryPolicy from '../screens/deliveryPolicy/DeliveryPolicy';
import UsagePolicy from '../screens/usagePolicy/UsagePolicy';
import NotFound from '../screens/notFound/NotFound';
import CategoriesSelection from '../screens/categoriesSelection/CategoriesSelection';

const Router = () => {
    return (
        <Routes>
            <Route path={RoutesUrl.welcome} element={<Home />}></Route>
            <Route path={RoutesUrl.auth} element={<Auth />}></Route>
            <Route path={RoutesUrl.cart} element={<Cart />}></Route>
            <Route path={RoutesUrl.restPassword} element={<ResetPassword />}></Route>
            <Route path={RoutesUrl.registrationConfirmation} element={<RegistrationConfirmation />}></Route>
            <Route path={RoutesUrl.profile} element={<PrivateRoute element={<Profile />} />} />
            <Route path={RoutesUrl.addresses} element={<PrivateRoute element={<Addresses />} />} />
            <Route path={RoutesUrl.orders} element={<PrivateRoute element={<OrdersHistory />} />} />
            <Route path={RoutesUrl.checkout} element={<PrivateRoute element={<Checkout />} />} />
            <Route path={RoutesUrl.productsPage} element={<ProductsPage />}></Route>
            <Route path={RoutesUrl.categories} element={<CategoriesSelection />}></Route>
            <Route path={RoutesUrl.productDetails} element={<ProductPage />}></Route>
            <Route path={RoutesUrl.privacyPolicy} element={<PrivacyPolicy />}></Route>
            <Route path={RoutesUrl.deliveryPolicy} element={<DeliveryPolicy />}></Route>
            <Route path={RoutesUrl.usagePolicy} element={<UsagePolicy />}></Route>
            <Route path='*' element={<NotFound />}></Route>
        </Routes>
    );
};

export default Router;
