import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';

const couponsPrefix = 'couponsManagement';

class CouponsFetchers extends BaseHttpFetcher {
    async getCouponByCode(userId: string, couponCode: string): Promise<any> {
        return await this.get(`${couponsPrefix}/coupons/${couponCode}/${userId}`);
    }
}

const couponsFetchersInstance = new CouponsFetchers('/', ClientConfig.apiBaseHost);
export default couponsFetchersInstance;
