import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PruchaseIcon from '../../../assets/icons/purchase.png';
import { DevliveryOptions, PaymentMethod, PaymentOptions } from '../../../enums/enums';
import CouponModel from '../../../models/order/CouponModel.model';
import CreateOrder from '../../../models/order/CreateOrder.model';
import OrdersUser from '../../../models/order/OrdersUser.model';
import PaymentAmountModel from '../../../models/order/PaymentAmountModel.model';
import PaymentInformation from '../../../models/order/PaymentInformation.model';
import ProductInOrderModel from '../../../models/order/ProductInOrder';
import ShippingDetailsModel from '../../../models/order/ShippingDetailsModel.model';
import { RoutesUrl } from '../../../router/RoutesUrls';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import { CART_STORE, USER_STORE } from '../../../stores/constants';
import UserStore from '../../../stores/user/User.store';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import checkoutOrderConfirmationStyle from './CheckoutOrderConfirmation.module.scss';

const cartStore = rootStores[CART_STORE] as CartStore;
const userStore = rootStores[USER_STORE] as UserStore;

interface IProps {
    captchaV2Token: string;
    handleBack: () => void;
}

const CheckoutOrderConfirmation = observer((props: IProps) => {
    const { captchaV2Token, handleBack } = props;
    const [orderId, setOrderId] = useState(undefined);
    const { createOrder, deliveryOption, products, selectedShippingAddress, selectedPickupStore, cartAmount, selectedPayment, cartCoupon } =
        cartStore;
    const { user } = userStore;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const profileNavigationHandler = () => {
        navigate(RoutesUrl.profile);
    };

    const paymentMadeSuccessfullyHandler = async () => {
        const paymentAmount = cartAmount;
        const orderDetails: CreateOrder = new CreateOrder({
            user: new OrdersUser({
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                email: user.email,
            }),
            shippingDetails: new ShippingDetailsModel({
                address: deliveryOption === DevliveryOptions.SELF_PICKUP ? selectedPickupStore.label : selectedShippingAddress.label,
                deliveryMethod: deliveryOption,
            }),
            paymentInformation: new PaymentInformation({
                lastDigits: '5839',
                selectedPayment: selectedPayment,
                isCharged: false,
                isInvoiced: false,
                paymentMethod: selectedPayment === PaymentOptions.ONLINE ? PaymentMethod.CARD : '',
            }),
            paymentAmount: new PaymentAmountModel(paymentAmount),
            products: products?.map((product) => new ProductInOrderModel(product)),
            coupon: cartCoupon.isCouponValidForCart ? new CouponModel(cartCoupon.coupon) : new CouponModel(),
            captchaV2Token,
        });
        const orderIdRes = await createOrder(orderDetails);
        setOrderId(orderIdRes);
    };

    useEffect(() => {
        paymentMadeSuccessfullyHandler();
    }, []);

    return orderId === undefined ? (
        <div>
            <div>שגיאה - נא לסמן אני לא רובוט.</div>
            <IButton className={`${commonStyles.defaultButton}`} disabled={false} onClick={handleBack} title={t('checkoutStepper.prev')} />
        </div>
    ) : (
        <div className={checkoutOrderConfirmationStyle.orderConfirmationContainer}>
            <div className={`${checkoutOrderConfirmationStyle.titleContainer} row`}>
                <span>{t('checkoutOrderConfirmation.approved')}</span>
            </div>
            <div className={`${checkoutOrderConfirmationStyle.orderNumberContainer} row`}>
                <span>{`${t('checkoutOrderConfirmation.orderNumber')}${orderId}`}</span>
            </div>
            <div className={`${checkoutOrderConfirmationStyle.imageContainer} row`}>
                <img className={checkoutOrderConfirmationStyle.icon} src={PruchaseIcon} alt='' />
            </div>
            <div className={`${checkoutOrderConfirmationStyle.summaryContainer}`}>
                <div className={`${checkoutOrderConfirmationStyle.textContainer} row`}>
                    <span>{t('checkoutOrderConfirmation.emailMessage')}</span>
                </div>
                <div className={`${checkoutOrderConfirmationStyle.textContainer} row`}>
                    <span>{t('checkoutOrderConfirmation.viewOrder')}</span>
                    <span onClick={profileNavigationHandler} className={checkoutOrderConfirmationStyle.myProfile}>
                        {t('checkoutOrderConfirmation.myProfile')}
                    </span>
                </div>
            </div>
        </div>
    );
});

export default CheckoutOrderConfirmation;
