import ClientConfig from '../config/index';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const productsPrefix = 'productsManagement';

class ProductsFetchers extends BaseHttpFetcher {
    async getProductsByFilters(params: any): Promise<any> {
        return await this.get(`${productsPrefix}/products`, {}, params);
    }

    async getHotProducts(): Promise<any> {
        return await this.get(`${productsPrefix}/hotProducts`);
    }

    async getProductsOnSale(): Promise<any> {
        return await this.get(`${productsPrefix}/onSale`);
    }
}

const productsFetcherInstance = new ProductsFetchers('/', ClientConfig.apiBaseHost);
export default productsFetcherInstance;
