import Category from './Category.model';

export default class SectionModel {
    sectionId!: string;

    sectionName!: string;

    categories?: Category[];

    displaySectionCard?: boolean;

    image?: string;

    disabled?: boolean;

    constructor(section?: SectionModel) {
        if (section) {
            this.sectionId = section.sectionId;
            this.sectionName = section.sectionName;
            this.categories = section.categories;
            this.displaySectionCard = section.displaySectionCard;
            this.image = section.image;
            this.disabled = section.disabled;
        }
    }
}
