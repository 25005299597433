import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import OrdersFetcher from '../../fetchers/Orders.fetcher';
import OrderModel from '../../models/order/Order.model';
import ToastUtil from '../../utils/ToastUtils';
import { CErrors } from '../../constants/constants';

class OrdersStore {
    @observable
    orders: OrderModel[] = [];

    @observable
    offset: number = 0;

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'OrdersStore',
            properties: ['orders'],
            storage: window.localStorage,
        });
    }

    @action
    setOrders = (orders: OrderModel[]) => (this.orders = orders);

    @action
    setOffset = (offset: number) => (this.offset = offset);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    getOrdersByUserId = async (userId: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getsOrdersByUserId(userId);
            this.setOrders(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default OrdersStore;
