import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import filterStyle from './Filter.module.scss';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import rootStores from '../../stores';
import { FILTERS_STORE } from '../../stores/constants';
import FilterStore from '../../stores/filters/Filters.store';
import { IFilterOptions, IFilters } from '../../interfaces/interfaces';

const filterStore = rootStores[FILTERS_STORE] as FilterStore;

interface Iprops {
    sectionId: string;
    changeFiltersHandler: (selectedFilter: { [key: string]: boolean }) => void;
}

const Filter = observer((props: Iprops) => {
    const { sectionId, changeFiltersHandler } = props;
    const { getFiltersByParentId, filters } = filterStore;
    const [openCollapse, setOpenCollapse] = useState<{ [key: string]: boolean }>({});

    const handleToggleCollapse = (filterId: string) => {
        setOpenCollapse((prev) => ({
            ...prev,
            [filterId]: !prev[filterId],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                sectionId && (await getFiltersByParentId(sectionId));
                setOpenCollapse({});
            } catch (error) {}
        };

        fetchData();
    }, [sectionId]);

    return (
        <>
            {filters?.map((filter: IFilters) => {
                const filterId = filter.filterId;
                const isOpen = openCollapse[filterId];

                return (
                    <div key={filterId}>
                        <ListItemButton
                            style={{ textAlign: 'right' }}
                            onClick={() => {
                                handleToggleCollapse(filterId);
                            }}
                        >
                            <ListItemText primary={filter?.filterLabel} />
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isOpen} timeout='auto' unmountOnExit>
                            {filter?.filterOptions?.map((option: IFilterOptions, i) => {
                                return (
                                    <div key={i} className={filterStyle.checkboxContainer}>
                                        <input
                                            className={filterStyle.filterCheckbox}
                                            type='checkbox'
                                            onChange={(e) => {
                                                const selectedFilter = {
                                                    [option.name]: e.target.checked,
                                                };
                                                changeFiltersHandler(selectedFilter);
                                            }}
                                            id={option?.name}
                                        />
                                        <label className={filterStyle.filterLabel} htmlFor={option?.name}>
                                            {option?.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </Collapse>
                    </div>
                );
            })}
        </>
    );
});

export default Filter;
