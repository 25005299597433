import { InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import datePickerStyle from './IDatePicker.module.scss';

interface IProps {
    inputLabel: string;
    disableFuture?: boolean;
    value: Dayjs | null;
    helperText: string;
    handleOnChange: any;
}

const IDatePicker = (props: IProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={datePickerStyle.inputContainer}>
                <InputLabel className={datePickerStyle.datePickerInputContainer}>{props.inputLabel}</InputLabel>
                <DatePicker
                    disableFuture={props.disableFuture}
                    value={props.value}
                    slotProps={{
                        textField: {
                            helperText: props.helperText,
                            error: Boolean(props.helperText),
                        },
                    }}
                    format='DD-MM-YYYY'
                    onChange={props.handleOnChange}
                    sx={{
                        '& .MuiInputBase-root': {
                            '& input': {
                                height: '5px',
                            },
                            '& p': {
                                border: '1px solid green',
                            },
                        },
                        '& .MuiButtonBase-root': {
                            width: '30px',
                            height: '30px',
                        },
                        '& .MuiFormHelperText-root': {
                            textAlign: 'right',
                            color: '#D32F2F',
                        },
                    }}
                />
            </div>
        </LocalizationProvider>
    );
};

export default IDatePicker;
