import React from 'react';
import deliveryOptionsStyle from './DeliveryOptions.module.scss';
import { ReactComponent as DeliveryTruckIcon } from '../../../assets/icons/truck-icon.svg';
import { ReactComponent as ShopIcon } from '../../../assets/icons/shop-icon.svg';
import { useTranslation } from 'react-i18next';
import OptionCard from '../../commonComponents/optionCard/OptionCard';
import { DevliveryOptions } from '../../../enums/enums';

interface IProps {
    onDeliveryOptionClick: (value: string) => void;
    chosenDelivery: string;
}

const DeliveryOptions = (props: IProps) => {
    const { onDeliveryOptionClick, chosenDelivery } = props;
    const { t } = useTranslation();

    const isSelfPickup = chosenDelivery === DevliveryOptions.SELF_PICKUP;
    const isDelivery = chosenDelivery === DevliveryOptions.DELIVERY;

    return (
        <div className={`${deliveryOptionsStyle.deliveryOptionContainer}`}>
            <div className='row'>
                <div className={`col-6`}>
                    <OptionCard
                        onDeliveryOptionClick={onDeliveryOptionClick}
                        value={DevliveryOptions.SELF_PICKUP}
                        label={t('deliveryOptions.pickup')}
                        isSelected={isSelfPickup}
                        icon={ShopIcon}
                    />
                </div>
                <div className={`col-6`}>
                    <OptionCard
                        onDeliveryOptionClick={onDeliveryOptionClick}
                        value={DevliveryOptions.DELIVERY}
                        label={t('deliveryOptions.delivery')}
                        isSelected={isDelivery}
                        icon={DeliveryTruckIcon}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeliveryOptions;
