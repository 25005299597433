export const CErrors = {
    SYSTEM: 'שגיאת מערכת',
    BCRYPT: 'BCrypt salt is undefined',
    HASH: 'Error hashing password',
};

export const CDiscount = {
    FIXED: 'fixed',
    PERCENTAGE: 'percentage',
};

export const CCoupon = {
    INVALID_MINIMUM: 'קופון זה תקף במינימום הזמנה של ',
    INVALID_PRODUCT: 'קופון זה לא תקף על אחד המוצרים או יותר',
    LOGIN_REQUIRED: 'לקבלת קופון יש להתחבר למערכת',
};
