import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import commonStyle from '../../../styles/commonStyles.module.scss';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { City } from '../../../interfaces/interfaces';
import ITextInput from '../../commonComponents/textInput/TextInput';
import addressesStyle from '../addresses/Addresses.module.scss';

import jsonCities from '../../../assets/jsons/cities.json';
import IButton from '../../commonComponents/button/IButton';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import rootStores from '../../../stores';
import UserStore from '../../../stores/user/User.store';
import { USER_STORE } from '../../../stores/constants';

const userStore = rootStores[USER_STORE] as UserStore;

const PopperMy = function (props) {
    return <Popper {...props} style={{ width: '27.5%', textAlign: 'right' }} placement='bottom-start' />;
};

interface IProps {
    initialValues?: { [key: string]: string };
    setIsFormOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAddress?: any;
}

const AddAddressForm = observer((props: IProps) => {
    const { addAddress, updateAddress } = userStore;
    const { user } = userStore;

    const { initialValues, setIsFormOpen, setSelectedAddress } = props;

    const { t } = useTranslation();

    let _cities: City[] = jsonCities;
    let cities = _cities.map((city) => city.name);

    const validationSchema = yup.object().shape({
        address: yup.string().required(t('myProfile.inputs.errors.address')),
        address2: yup.string(),
        city: yup.string().required(t('myProfile.inputs.errors.city')),
        zipCode: yup
            .string()
            .required(t('myProfile.inputs.errors.zipCode'))
            .matches(/^[0-9]+$/, t('myProfile.inputs.errors.onlyNumbers')), // Allows only numbers
    });

    const formik = useFormik({
        initialValues: {
            address: initialValues?.address || '',
            address2: initialValues?.address2 || '',
            city: initialValues?.city || '',
            zipCode: initialValues?.zipCode || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            initialValues
                ? await updateAddress(user, initialValues.address, values.address, values.address2, values.city, values.zipCode)
                : await addAddress(user, values.address, values.address2, values.city, values.zipCode);
            formik.resetForm();
            if (setIsFormOpen) {
                setIsFormOpen(false);
            }
            if (setSelectedAddress) {
                setSelectedAddress(`${values.address}, ${values.city}, ${values.zipCode}`);
            }
        },
    });

    return (
        <div className={addressesStyle.formInputsContainer}>
            <div className={addressesStyle.addAddressTitleContainer}></div>
            <form onSubmit={formik.handleSubmit}>
                <div className={addressesStyle.formRawInputs}>
                    <ITextInput
                        inputLabel={t('myProfile.inputs.address')}
                        size={'small'}
                        variant={'outlined'}
                        value={formik.values.address}
                        isFullWidth={true}
                        handleOnChange={formik.handleChange('address')}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                    />
                    <ITextInput
                        inputLabel={t('myProfile.inputs.address2')}
                        size={'small'}
                        variant={'outlined'}
                        value={formik.values.address2}
                        isFullWidth={true}
                        handleOnChange={formik.handleChange('address2')}
                        error={formik.touched.address2 && Boolean(formik.errors.address2)}
                        helperText={formik.touched.address2 && formik.errors.address2}
                    />
                </div>
                <div className={addressesStyle.formRawInputs}>
                    <Autocomplete
                        options={cities}
                        forcePopupIcon={false}
                        sx={{ width: '100%' }}
                        style={{ textAlign: 'right' }}
                        value={formik.values.city}
                        noOptionsText={t('myProfile.inputs.noOptions')}
                        PopperComponent={PopperMy}
                        onChange={(event, value) => {
                            formik.handleChange('city')(value || '');
                        }}
                        renderInput={(params) => (
                            <>
                                <InputLabel className={addressesStyle.labelTitle}>{t('myProfile.inputs.city')}</InputLabel>

                                <TextField
                                    {...params}
                                    sx={{ width: '100%' }}
                                    size={'small'}
                                    variant={'outlined'}
                                    fullWidth
                                    InputLabelProps={{
                                        style: { width: '100%' },
                                        shrink: false,
                                    }}
                                    value={formik.values.city}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </>
                        )}
                    />

                    <ITextInput
                        inputLabel={t('myProfile.inputs.zipCode')}
                        size={'small'}
                        variant={'outlined'}
                        value={formik.values.zipCode}
                        inputProps={{ maxLength: 7 }}
                        isFullWidth={true}
                        handleOnChange={formik.handleChange('zipCode')}
                        error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                        helperText={formik.touched.zipCode && formik.errors.zipCode}
                    />
                </div>

                <div className={addressesStyle.actionsContainer}>
                    <IButton
                        className={`${commonStyle.defaultButton}`}
                        title={t(initialValues ? 'myProfile.buttons.updateAddress' : 'myProfile.buttons.saveAddress')}
                        type={'submit'}
                    />
                </div>
            </form>
        </div>
    );
});

export default AddAddressForm;
