import CategoryModel from '../../models/topics/Category.model';
import TopicModel from '../../models/topics/Topic.model';
import categoryCardStyle from './CategoryCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface IProps {
    category: CategoryModel;
    setSelectedTopic: any;
}

const CategoryCard = (props: IProps) => {
    const {
        category: { categoryId, categoryName },
        setSelectedTopic,
    } = props;

    const navigate = useNavigate();

    const onClickHandler = () => {
        setSelectedTopic(
            new TopicModel({
                topicId: categoryId,
                topicName: categoryName,
            })
        );
        navigate(`/products-page/${categoryName}`);
    };

    return (
        <div
            className={categoryCardStyle.cardContainer}
            onClick={() => {
                onClickHandler();
            }}
        >
            <span>{categoryName}</span>
        </div>
    );
};

export default CategoryCard;
