const editUserForm = {
    editAccount: 'עדכון פרטי חשבון',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    email: 'אימייל',
    phoneNumber: 'מספר טלפון',
    create: 'יצירה',
    dateOfBirth: 'תאריך לידה',
    goBack: 'חזור',
    update: 'עדכן',
    exist: 'קיים במערכת',
    requiredField: 'שדה חובה',
    invalidEmail: 'כתובת מייל אינה תקינה',
    invalidPhoneNumber: 'טלפון אינו תקין',
    minAge: 'הרשמה לאתר מגיל-',
    dateError: 'אינך בגיל התואם למדיניות השימוש',
    required: 'שדה חובה',
};

export default editUserForm;
