import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { RoutesUrl } from '../../../router/RoutesUrls';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import loginStyle from './LoginForm.module.scss';
import LoginCredentials from '../../../models/auth/LoginCredentials.model';
import { hashPassword } from '../../../actions/hashPassword';

interface IProps {
    loginHandler: (payload: LoginCredentials) => Promise<boolean>;
}

const LoginForm = (props: IProps) => {
    const { loginHandler } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('loginForm.invalidEmail')).required(t('loginForm.requiredField')),
        password: yup.string().required(t('loginForm.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const isSuccess = await loginHandler(
                new LoginCredentials({
                    email: values.email,
                    password: await hashPassword(values.password),
                })
            );
            if (isSuccess && window.location.pathname === RoutesUrl.auth) {
                navigate(RoutesUrl.welcome);
            }
        },
    });

    const forgetPasswordHandler = () => {
        navigate(RoutesUrl.restPassword);
    };

    return (
        <div className={loginStyle.loginFormContainer}>
            <div className={loginStyle.loginFormContext}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={loginStyle.loginFormTitle}>
                        <p>{t('loginForm.login')}</p>
                    </div>

                    <div className={loginStyle.loginFormInputContainer}>
                        <div className={loginStyle.loginFormInputTitle}>
                            <p>{t('loginForm.email')}</p>
                        </div>
                        <div className={loginStyle.loginFormInputFiled}>
                            <TextField
                                size='small'
                                onChange={formik.handleChange('email')}
                                fullWidth={true}
                                variant='outlined'
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                autoComplete='username'
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </div>
                    </div>

                    <div className={loginStyle.loginFormInputContainer}>
                        <div className={loginStyle.loginFormPasswordRow}>
                            <div>
                                <p>{t('loginForm.password')}</p>
                            </div>
                            <div className={loginStyle.loginFormForgotPassword}>
                                <IButton
                                    className={`${commonStyles.textButton}`}
                                    disabled={false}
                                    onClick={forgetPasswordHandler}
                                    title={t('loginForm.forgotPassword')}
                                />
                            </div>
                        </div>
                        <div className={loginStyle.loginFormInputFiled}>
                            <TextField
                                type='password'
                                onChange={formik.handleChange('password')}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                size='small'
                                inputProps={{ maxLength: 15 }}
                                fullWidth={true}
                                variant='outlined'
                                helperText={formik.touched.password && formik.errors.password}
                                autoComplete='current-password'
                            />
                        </div>
                    </div>

                    <div className={loginStyle.loginFormSignIn}>
                        <IButton
                            className={`${commonStyles.defaultButton} ${loginStyle.loginFormSignInButton}`}
                            title={t('loginForm.signin')}
                            type={'submit'}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
