import React from 'react';
import notFoundMessageStyle from './NotFoundMessage.module.scss';
import { useTranslation } from 'react-i18next';

const NotFoundMessage = () => {
    const { t } = useTranslation();

    return (
        <div className={notFoundMessageStyle.notFoundMessageContainer}>
            <h1>{t('notFoundMessage.oops')}</h1>
            <p>{t('notFoundMessage.errorPath')}</p>
        </div>
    );
};

export default NotFoundMessage;
