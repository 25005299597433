export default class OrdersUser {
    userId: string = '';
    firstName: string = '';
    lastName: string = '';
    phoneNumber: string = '';
    email: string = '';

    constructor(user?: OrdersUser) {
        if (user) {
            this.userId = user.userId;
            this.email = user.email;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.phoneNumber = user.phoneNumber;
        }
    }
}
