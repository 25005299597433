import React from 'react';
import Auth from '../screens/auth/Auth';
import { observer } from 'mobx-react';

import rootStores from '../stores';
import { AUTH_STORE } from '../stores/constants';
import AuthStore from '../stores/auth/Auth.store';

interface IProps {
    element: any;
}

const authStore = rootStores[AUTH_STORE] as AuthStore;

const PrivateRoute = observer((props: IProps) => {
    const { element } = props;
    const { isLoggedIn } = authStore;

    if (!isLoggedIn) {
        return <Auth />;
    }

    return element;
});

export default PrivateRoute;
