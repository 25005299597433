import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import './TextInput.customization.css';
import textInputStyle from './TextInput.module.scss';

interface IProps {
    size: 'small' | 'medium';
    type?: string;
    isFullWidth: boolean;
    variant: 'outlined' | 'standard' | 'filled';
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    inputLabel?: string;
    error: boolean | undefined;
    helperText: string | false | undefined;
    inputProps?: any;
}

const ITextInput = observer((props: IProps) => {
    const { size, handleOnChange, isFullWidth, variant, inputProps, value, error, helperText, type } = props;
    return (
        <div className={`${textInputStyle.textInputContainer} text-input-customization`}>
            {props.inputLabel && <InputLabel className={textInputStyle.textInputLabel}>{props.inputLabel}</InputLabel>}
            <TextField
                size={size}
                onChange={handleOnChange}
                fullWidth={isFullWidth}
                variant={variant}
                inputProps={inputProps}
                value={value}
                error={error}
                helperText={helperText}
                type={type}
            />
        </div>
    );
});

export default ITextInput;
