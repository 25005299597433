import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import commonStyle from '../../styles/commonStyles.module.scss';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import couponStyle from './Coupon.module.scss';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import ITextInput from '../commonComponents/textInput/TextInput';
import IButton from '../commonComponents/button/IButton';
import rootStores from '../../stores';
import CartStore from '../../stores/cart/Cart.store';
import UserStore from '../../stores/user/User.store';
import { CART_STORE, USER_STORE } from '../../stores/constants';
import Spinner from '../commonComponents/spinner/Spinner';
import { Discount } from '../../enums/enums';
import { CDiscount } from '../../constants/constants';
import DeleteIcon from '@mui/icons-material/Delete';

const userStore = rootStores[USER_STORE] as UserStore;
const cartStore = rootStores[CART_STORE] as CartStore;

const Coupon = observer(() => {
    const { user } = userStore;
    const {
        getCouponByCode,
        isLoading,
        removeCoupon,
        cartCoupon: { coupon, invalidReason, isCouponValidForCart },
    } = cartStore;

    const [couponModalIsOpen, setCouponModalIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        code: yup.string().required(t('coupon.require')),
    });

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await getCouponByCode(user?.id, values.code);
            setCouponModalIsOpen(false);
            formik.resetForm();
        },
    });

    return (
        <div className={couponStyle.couponContainer}>
            <Modal
                open={couponModalIsOpen}
                onClose={() => {
                    setCouponModalIsOpen(false);
                    formik.resetForm();
                }}
            >
                <>
                    <div className={`${couponStyle.couponModalContainer} ${isLoading ? 'blur-background' : ''}`}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={`${couponStyle.couponTitleContainer} row`}>
                                <div className='col-12'>
                                    <span>{t('coupon.code')}</span>
                                </div>
                            </div>
                            <div className={`${couponStyle.couponInputContainer} row`}>
                                <div className={`${couponStyle.couponColumnContainer} col-8`}>
                                    <ITextInput
                                        size={'small'}
                                        variant={'outlined'}
                                        value={formik.values.code}
                                        inputProps={{ maxLength: 10 }}
                                        isFullWidth={true}
                                        handleOnChange={formik.handleChange('code')}
                                        error={formik.touched.code && Boolean(formik.errors.code)}
                                        helperText={formik.touched.code && formik.errors.code}
                                    />
                                </div>
                                <div className={`${couponStyle.couponColumnContainer} col-4`}>
                                    <IButton className={`${commonStyle.defaultButton}`} title={t('coupon.apply')} type={'submit'} />
                                </div>
                            </div>
                        </form>
                    </div>
                    {isLoading && <Spinner />}
                </>
            </Modal>
            <div className={couponStyle.couponRowContainer}>
                <div className={`${couponStyle.couponButtonContainer} row`} onClick={() => setCouponModalIsOpen(!couponModalIsOpen)}>
                    <div className={`${couponStyle.couponColumn} ${couponStyle.iconColumn} col-3`}>
                        <LocalOfferSharpIcon className={couponStyle.icon} />
                    </div>
                    <div className={`${couponStyle.couponColumn} ${couponStyle.textColumn} col-9`}>
                        <span>{t('coupon.apply')}</span>
                    </div>
                </div>
                {coupon?.code && isCouponValidForCart && (
                    <>
                        <span>{coupon?.code}</span>
                        <span>{`${coupon?.discount?.type === CDiscount.FIXED ? Discount.FIXED : Discount.PERCENTAGE}${
                            coupon?.discount?.amount
                        }`}</span>
                        <DeleteIcon
                            className={couponStyle.deleteIcon}
                            onClick={() => {
                                removeCoupon();
                            }}
                        />
                    </>
                )}
            </div>

            {!isCouponValidForCart && coupon?.code && (
                <div className={`${couponStyle.couponDetails}`}>
                    <span>{invalidReason}</span>
                </div>
            )}
        </div>
    );
});

export default Coupon;
