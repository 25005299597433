import React from 'react';
import { observer } from 'mobx-react';
import checkoutPaymentStyle from './CheckoutPayment.module.scss';
import { ReactComponent as CreditCard } from '../../../assets/icons/credit-cards.svg';
import { ReactComponent as Cash } from '../../../assets/icons/cash-machine.svg';
import { useTranslation } from 'react-i18next';
import PaymentIntegration from './paymentIntegration/PaymentIntegration';
import OptionCard from '../../commonComponents/optionCard/OptionCard';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import { CART_STORE } from '../../../stores/constants';
import { PaymentOptions, DevliveryOptions } from '../../../enums/enums';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const CheckoutPayment = observer(() => {
    const { t } = useTranslation();
    const { selectedPayment, setSelectedPayment, deliveryOption } = cartStoreStore;

    const isPayInStore = selectedPayment === PaymentOptions.STORE;
    const isPayOnline = selectedPayment === PaymentOptions.ONLINE;

    const onChange = (value) => {
        setSelectedPayment(value);
    };

    return (
        <div className={checkoutPaymentStyle.checkoutPaymentContainer}>
            <div className={`${checkoutPaymentStyle.titleContainer} row`}>
                <span>{t('checkoutPayment.title')}</span>
            </div>
            <div className={`${checkoutPaymentStyle.paymentOptionsContainer} row`}>
                <div className={`col-6`}>
                    <OptionCard
                        onDeliveryOptionClick={onChange}
                        value={PaymentOptions.ONLINE}
                        label={t('checkoutPayment.creditCard')}
                        isSelected={isPayOnline}
                        icon={CreditCard}
                    />
                </div>
                <div className={`col-6`}>
                    {deliveryOption === DevliveryOptions.SELF_PICKUP && (
                        <OptionCard
                            onDeliveryOptionClick={onChange}
                            value={PaymentOptions.STORE}
                            label={t('checkoutPayment.payInStore')}
                            isSelected={isPayInStore}
                            icon={Cash}
                        />
                    )}
                </div>
            </div>
            <div className={`row`}>{isPayOnline && <PaymentIntegration />}</div>
        </div>
    );
});

export default CheckoutPayment;
