const checkoutCustomerInformation = {
    customerInformation: 'פרטי לקוח',
    chooseAddress: 'בחר כתובת',
    chooseStore: 'בחר חנות',
    deliveryMethod: 'שיטת משלוח',
    delivery: 'משלוח',
    selfPickup: 'איסוף מהחנות',
    notFound: 'חזור לעגלה ובחר',
};

export default checkoutCustomerInformation;
