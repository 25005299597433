import { BallTriangle } from 'react-loader-spinner';
import spinnerStyle from './Spinner.module.scss';
import ClientConfig from '../../../config/index';

const Spinner = () => {
    return (
        <div className={spinnerStyle.spinnerContainer}>
            <BallTriangle height={100} width={100} radius={5} color={ClientConfig.palette.primaryColor} visible={true} />
        </div>
    );
};

export default Spinner;
