export default class RegistrationDetails {
    email: string = '';
    password: string = '';
    firstName: string = '';
    phoneNumber: string = '';
    dateOfBirth: string = '';
    captchaV2Token: string = '';

    constructor(registrationDetails?: RegistrationDetails) {
        if (registrationDetails) {
            this.email = registrationDetails.email;
            this.password = registrationDetails.password;
            this.firstName = registrationDetails.firstName;
            this.phoneNumber = registrationDetails.phoneNumber;
            this.dateOfBirth = registrationDetails.dateOfBirth;
            this.captchaV2Token = registrationDetails.captchaV2Token;
        }
    }
}
