import moment from 'moment';

export const formatDateOnly = (dateString: string): string => {
    const date = moment(dateString, 'YYYY-MM-DDTHH:mm:ss A.SSSZ');
    return moment(date).format('DD/MM/YYYY');
};

export const formatDateAndTime = (dateString: string): string => {
    const dateObj = moment(dateString, 'YYYY-MM-DD[T]h:mm:ss A.SSS[Z]');

    const formattedDate = dateObj.format('DD-MM-YYYY HH:mm');

    return formattedDate;
};
