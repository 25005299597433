import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import accountDetailsStyle from './AccountDetails.module.scss';

import { observer } from 'mobx-react';
import { RoutesUrl } from '../../../router/RoutesUrls';
import UserStore from '../../../stores/user/User.store';
import { USER_STORE } from '../../../stores/constants';
import rootStores from '../../../stores/index';

const userStore = rootStores[USER_STORE] as UserStore;

const AccountDetails = observer(() => {
    const { user } = userStore;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOnViewAddressesClick = () => navigate(RoutesUrl.addresses);

    return (
        <div className={accountDetailsStyle.accountDetailsContainer}>
            <div className={`${accountDetailsStyle.accountDetailsTitleContainer} row`}>
                <span>{t('myProfile.accountDetails')}</span>
            </div>
            <div className={accountDetailsStyle.accountDetails}>
                <span>{user?.firstName + ' ' + user?.lastName}</span>
                <span>{user?.shippingAddresses[0]?.address}</span>
                <span>{user?.shippingAddresses[0]?.city}</span>
                <span className={accountDetailsStyle.viewAddressesText} onClick={handleOnViewAddressesClick}>
                    {t('myProfile.viewAddresses') + ` (${user?.shippingAddresses.length})`}
                </span>
            </div>
        </div>
    );
});

export default AccountDetails;
