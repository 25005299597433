export default class CartAmount {
    productsAmount: number = 0;
    shippingAmount: number = 0;
    totalAmount: number = 0;

    constructor(cartAmount?: CartAmount) {
        if (cartAmount) {
            this.productsAmount = cartAmount.productsAmount;
            this.shippingAmount = cartAmount.shippingAmount;
            this.totalAmount = cartAmount.totalAmount;
        }
    }
}
