export enum EnvKeys {
    REACT_APP_ENV = 'REACT_APP_ENV',
    REACT_APP_IS_PROVIDER = 'REACT_APP_IS_PROVIDER',
    REACT_APP_HOVER_COLOR = 'REACT_APP_HOVER_COLOR',
    REACT_APP_PROD_SERVER = 'REACT_APP_PROD_SERVER',
    REACT_APP_PRIMARY_COLOR = 'REACT_APP_PRIMARY_COLOR',
    REACT_APP_VERSION = 'REACT_APP_VERSION',
    REACT_APP_BCRYPT_SALT = 'REACT_APP_BCRYPT_SALT',
    FavIcon = 'REACT_APP_FAVICON',
    ____CUSTOM_KEY____ = '____CUSTOM_KEY____',
}

export default class EnvUtils {
    public static getEnvVariable = (key: string): any => process.env[key];
}
