import React, { useState } from 'react';
import resetStyle from './Reset.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/button/IButton';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { RoutesUrl } from '../../../router/RoutesUrls';
import rootStores from '../../../stores';
import AuthStore from '../../../stores/auth/Auth.store';
import { AUTH_STORE } from '../../../stores/constants';
import Spinner from '../../commonComponents/spinner/Spinner';
import PasswordChangedIcon from '../../../assets/icons/password-changed.png';
import { hashPassword } from '../../../actions/hashPassword';

const authStore = rootStores[AUTH_STORE] as AuthStore;

interface IProps {
    token: string;
}

const Reset = (props: IProps) => {
    const { resetPassword, isLoading } = authStore;
    const { token } = props;
    const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState<boolean>(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required(t('reset.requiredField'))
            .min(8, t('registrationForm.minDigits', { length: 8 }))
            .matches(/[a-zA-Z]/, t('registrationForm.oneEnglishLetter')),
        rePassword: yup
            .string()
            .required(t('reset.requiredField'))
            .oneOf([yup.ref('password')], t('reset.invalidRePassword')),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            rePassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formik.resetForm();
            setPasswordResetSuccessfully(
                await resetPassword({
                    password: await hashPassword(values.password),
                    token,
                })
            );
        },
    });

    const profileNavigationHandler = () => {
        navigate(RoutesUrl.auth);
    };

    return (
        <div className={`${resetStyle.resetContainer}`}>
            {passwordResetSuccessfully ? (
                <>
                    <div className={`${resetStyle.iconContainer} row`}>
                        <img src={PasswordChangedIcon} alt='' />
                    </div>
                    <div className={`${resetStyle.confirmationMessageContainer} row`}>
                        <span>{t('reset.passwordResetSuccessfully')}</span>
                    </div>
                    <div className={`${resetStyle.textContainer} row`}>
                        <span>{t('reset.navigateTo')}</span>
                        <span onClick={profileNavigationHandler} className={resetStyle.login}>
                            {t('reset.login')}
                        </span>
                    </div>
                </>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className={`${resetStyle.titleContainer} row`}>
                        <span>{t('reset.resetTitle')}</span>
                    </div>
                    <div className={`${resetStyle.contextContainer} row`}>
                        <span>{t('reset.resetExplanation')}</span>
                    </div>
                    <div className={`${resetStyle.passwordInputContainer} row`}>
                        <TextField
                            size='small'
                            type='password'
                            value={formik.values.password}
                            onChange={formik.handleChange('password')}
                            placeholder={t('reset.enterPassword')}
                            variant='outlined'
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </div>
                    <div className={`${resetStyle.passwordInputContainer} row`}>
                        <TextField
                            size='small'
                            type='password'
                            value={formik.values.rePassword}
                            onChange={formik.handleChange('rePassword')}
                            placeholder={t('reset.reEnterPassword')}
                            variant='outlined'
                            error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
                            helperText={formik.touched.rePassword && formik.errors.rePassword}
                        />
                    </div>

                    <div className={`${resetStyle.submitButtonContainer} row`}>
                        <IButton className={`${commonStyles.defaultButton}`} title={t('reset.changePassword')} type={'submit'} />
                    </div>
                </form>
            )}
            {isLoading && <Spinner />}
        </div>
    );
};

export default Reset;
