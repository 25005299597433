import { useTranslation } from 'react-i18next';
import AddressModel from '../../../models/user/Address.model';
import UserModel from '../../../models/user/User.model';
import IButton from '../../commonComponents/button/IButton';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react';
import { useState } from 'react';
import rootStores from '../../../stores';
import UserStore from '../../../stores/user/User.store';
import { USER_STORE } from '../../../stores/constants';
import commonStyle from '../../../styles/commonStyles.module.scss';
import addressesStyle from '../addresses/Addresses.module.scss';
import AddAddressForm from './AddAddressForm';

const userStore = rootStores[USER_STORE] as UserStore;

interface IProps {
    user: UserModel;
    address: AddressModel;
    key?: string;
}

const Address = observer((props: IProps) => {
    const [deleteAddressModalIsOpen, setDeleteAddressModalIsOpen] = useState<boolean>(false);
    const [isUpdateFormOpen, setIsUpdateFormOpen] = useState<boolean>(false);
    const { user, address, key } = props;

    const { deleteAddress } = userStore;

    const { t } = useTranslation();

    const onEditClick = () => setIsUpdateFormOpen(!isUpdateFormOpen);
    const onDeleteClick = () => deleteAddress(user, address.address, address.city);

    return (
        <div key={key} className={addressesStyle.detailsContainer}>
            <Modal
                open={deleteAddressModalIsOpen}
                onClose={() => {
                    setDeleteAddressModalIsOpen(false);
                }}
            >
                <div className={addressesStyle.deleteModalContainer}>
                    <div className={`${addressesStyle.titleContainer} row`}>
                        <span>{t('myProfile.inputs.deleteAddressValidate')}</span>
                    </div>
                    <div className={`row`}>
                        <div className={`${addressesStyle.actionsColumnContainer} col-6`}>
                            <IButton
                                className={`${commonStyle.defaultButton} ${addressesStyle.modalDeleteButton}`}
                                title={t('myProfile.buttons.delete')}
                                onClick={onDeleteClick}
                            />
                        </div>
                        <div className={`${addressesStyle.actionsColumnContainer} col-6`}>
                            <IButton
                                className={`${commonStyle.textButton}`}
                                title={t('myProfile.buttons.cancel')}
                                onClick={() => {
                                    setDeleteAddressModalIsOpen(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <span className={addressesStyle.details}>{address?.address}</span>
            <span className={addressesStyle.details}>{address?.zipCode}</span>
            <span className={addressesStyle.details}>{address?.city}</span>

            <div className={addressesStyle.buttonsContainer}>
                <IButton className={`${commonStyle.defaultButton}`} title={t('myProfile.buttons.edit')} onClick={onEditClick} />
                <IButton
                    className={`${commonStyle.textButton} ${addressesStyle.deleteButton}`}
                    title={t('myProfile.buttons.delete')}
                    onClick={() => {
                        setDeleteAddressModalIsOpen(true);
                    }}
                />
            </div>
            {isUpdateFormOpen && (
                <div key={key} className={addressesStyle.addFormContainer}>
                    <AddAddressForm
                        initialValues={{ address: address.address, address2: address.address2, city: address.city, zipCode: address.zipCode }}
                        setIsFormOpen={setIsUpdateFormOpen}
                    />
                </div>
            )}
        </div>
    );
});

export default Address;
