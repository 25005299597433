import React from 'react';
import titleStyle from './Title.module.scss';

interface IProps {
    logo: string;
    storeName: string;
    onClick: () => void;
}
const Title = (props: IProps) => {
    const { logo, onClick, storeName } = props;

    return (
        <span onClick={onClick} className={titleStyle.titleContainer}>
            <img className={titleStyle.logo} src={logo} alt={storeName} />
        </span>
    );
};

export default Title;
