import bcrypt from 'bcryptjs';
import ToastUtil from '../utils/ToastUtils';
import { CErrors } from '../constants/constants';

export const hashPassword = async (password: string): Promise<string> => {
    try {
        if (!process.env.REACT_APP_BCRYPT_SALT) {
            ToastUtil.error(CErrors.SYSTEM);
            throw new Error(CErrors.BCRYPT);
        }

        const hash = await bcrypt.hash(password, process.env.REACT_APP_BCRYPT_SALT);
        return hash;
    } catch (error) {
        ToastUtil.error(CErrors.SYSTEM);
        throw new Error(CErrors.HASH);
    }
};
