import AddressModel from './Address.model';
import BillingInformationModel from './BillingInformation.model';

export default class UserModel {
    id: string = '';
    billingInformation: BillingInformationModel[] = [];
    dateOfBirth: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    phoneNumber: string = '';
    shippingAddresses: AddressModel[] = [];

    constructor(user?: UserModel) {
        if (user) {
            this.id = user.id;
            this.billingInformation = user.billingInformation;
            this.dateOfBirth = user.dateOfBirth;
            this.email = user.email;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.phoneNumber = user.phoneNumber;
            this.shippingAddresses = user.shippingAddresses;
        }
    }
}
