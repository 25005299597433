import { action, makeObservable, observable, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Product from '../../models/product/Product.model';
import ToastUtil from '../../utils/ToastUtils';
import OrdersFetcher from '../../fetchers/Orders.fetcher';
import CouponsFetcher from '../../fetchers/Coupons.fetcher';
import { ISelectOption } from '../../interfaces/interfaces';
import CartAmount from '../../models/cart/CartAmount.model';
import ProductInOrderModel from '../../models/order/ProductInOrder';
import { Currency, DevliveryOptions, PaymentOptions } from '../../enums/enums';
import CreateOrder from '../../models/order/CreateOrder.model';
import { CCoupon, CDiscount, CErrors } from '../../constants/constants';
import CartCouponModel from '../../models/cart/CartCouponModel.model';

class CartStore {
    @observable
    products: ProductInOrderModel[] = [];

    @observable
    isOpen: boolean = false;

    @observable
    deliveryOption: string = 'delivery';

    @observable
    selectedPayment: string = PaymentOptions.ONLINE;

    @observable
    selectedShippingAddress: ISelectOption = {
        key: -2,
        label: '',
    };

    @observable
    selectedPickupStore: ISelectOption = {
        key: -2,
        label: '',
    };

    @observable
    shippingFee: number = 10;

    @observable
    maxDeliveryDays: number = 2;

    @observable
    minAmountForFreeShipping: number = 10;

    @observable
    cartAmount: CartAmount = new CartAmount();

    @observable
    cartCoupon: CartCouponModel = new CartCouponModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'CartStore',
            properties: ['products', 'deliveryOption', 'selectedPayment', 'maxDeliveryDays', 'cartAmount', 'minAmountForFreeShipping', 'cartCoupon'],
            storage: window.localStorage,
        });
        reaction(
            () => this.products.map((product) => product.product),
            () => {
                if (this.cartCoupon?.coupon?.code) {
                    this.validateCouponForCart();
                }
            }
        );
    }

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);

    @action
    setCartCoupon = (cartCoupon: CartCouponModel) => (this.cartCoupon = cartCoupon);

    @action
    setMaxDeliveryDays = (maxDeliveryDays: number) => (this.maxDeliveryDays = maxDeliveryDays);

    @action
    setSelectedPayment = (selectedPayment: string) => (this.selectedPayment = selectedPayment);

    @action
    setShippingFee = (shippingFee: number) => (this.shippingFee = shippingFee);

    @action
    setCartAmount = (cartAmount: CartAmount) => (this.cartAmount = cartAmount);

    @action
    setProducts = (products: ProductInOrderModel[]) => (this.products = products);

    @action
    setCartIsOpen = (isOpen: boolean) => (this.isOpen = isOpen);

    @action
    setShippingAddress = (shippingAddress: ISelectOption) => (this.selectedShippingAddress = shippingAddress);

    @action
    setPickupStore = (pickupStore: ISelectOption) => (this.selectedPickupStore = pickupStore);

    @action
    setMinAmountForFreeShipping = (minAmountForFreeShipping: number) => (this.minAmountForFreeShipping = minAmountForFreeShipping);

    updateCouponAmountToCart = () => {
        const coupon = this.cartCoupon.coupon;
        const cartTotalAmount = this.cartAmount.totalAmount;
        let newTotalAmount: number = 0;

        if (coupon.discount.type === CDiscount.FIXED) {
            newTotalAmount = cartTotalAmount - coupon.discount.amount;
        } else if (coupon.discount.type === CDiscount.PERCENTAGE) {
            const discountAmount = (coupon.discount.amount / 100) * cartTotalAmount;
            newTotalAmount = cartTotalAmount - discountAmount;
        }

        this.cartAmount = {
            ...this.cartAmount,
            totalAmount: newTotalAmount,
        };
    };

    calculateProductsAmount = () => {
        return parseFloat(
            this.products
                .reduce((sum, product) => {
                    const extraPriceFromVariants = parseFloat(
                        product.product.variants
                            .reduce((extraSum, variant) => {
                                return extraSum + variant.options[0].extraPrice;
                            }, 0)
                            .toFixed(2)
                    );
                    return sum + product.product.updatedPrice * product.quantity + extraPriceFromVariants;
                }, 0)
                .toFixed(2)
        );
    };

    validateCouponForCart = () => {
        const coupon = this.cartCoupon.coupon;
        let isCouponValidForCart = true;
        this.updateCartAmount(this.cartAmount.productsAmount);

        const isProductBlocked = this.products.some((product) => coupon.blockedProducts.includes(product.product._id));
        const isSectionBlocked = this.products.some((product) =>
            product.product.sections.some((section) => coupon.blockedSections.includes(section.sectionId))
        );
        const isCategoriesBlocked = this.products.some((product) =>
            product.product.categories.some((category) => coupon.blockedCategories.includes(category.categoryId))
        );
        const isSubCategoriesBlocked = this.products.some((product) =>
            product.product.subCategories.some((subCategory) => coupon.blockedSubCategories.includes(subCategory.subCategoryId))
        );

        if (this.cartAmount.totalAmount < coupon.minAmount) {
            this.setCartCoupon(
                new CartCouponModel({
                    ...this.cartCoupon,
                    isCouponValidForCart: false,
                    invalidReason: `${CCoupon.INVALID_MINIMUM} ${Currency.SHEKEL}${coupon.minAmount}`,
                })
            );
            isCouponValidForCart = false;
        } else if (isProductBlocked || isSectionBlocked || isCategoriesBlocked || isSubCategoriesBlocked) {
            this.setCartCoupon(
                new CartCouponModel({
                    ...this.cartCoupon,
                    isCouponValidForCart: false,
                    invalidReason: CCoupon.INVALID_PRODUCT,
                })
            );
            isCouponValidForCart = false;
        }

        if (isCouponValidForCart) {
            this.setCartCoupon(
                new CartCouponModel({
                    ...this.cartCoupon,
                    isCouponValidForCart: true,
                    invalidReason: '',
                })
            );
            this.updateCouponAmountToCart();
        }
    };

    updateCartAmount = (newProductsAmount: number) => {
        const isFreeShipping = newProductsAmount >= this.minAmountForFreeShipping || this.deliveryOption === DevliveryOptions.SELF_PICKUP;
        this.cartAmount = {
            ...this.cartAmount,
            productsAmount: newProductsAmount,
            shippingAmount: isFreeShipping ? 0 : this.shippingFee,
            totalAmount: isFreeShipping ? newProductsAmount : parseFloat((newProductsAmount + this.shippingFee).toFixed(2)),
        };
    };

    @action
    getCouponByCode = async (userId: string, couponCode: string) => {
        try {
            this.setIsLoading(true);
            if (!userId) {
                ToastUtil.error(CCoupon.LOGIN_REQUIRED);
                return;
            }
            const { data } = await CouponsFetcher.getCouponByCode(userId, couponCode);
            this.setCartCoupon(
                new CartCouponModel({
                    coupon: data,
                    isCouponValidForCart: false,
                    invalidReason: '',
                })
            );
            this.validateCouponForCart();
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    addProduct = (newProduct: Product, shouldOpenCart: boolean) => {
        // Check if both _id and variants match to avoid double identify products
        const existingProductIndex = this.products.findIndex((product) => {
            return product.product._id === newProduct._id && JSON.stringify(product.product.variants) === JSON.stringify(newProduct.variants);
        });

        if (existingProductIndex === -1) {
            this.products = [
                ...this.products,
                {
                    product: new Product(newProduct),
                    quantity: 1,
                },
            ];
        } else {
            this.products = this.products?.map((product, index) =>
                index === existingProductIndex ? { ...product, quantity: product.quantity + 1 } : product
            );
        }

        const newProductsAmount = this.calculateProductsAmount();
        this.updateCartAmount(newProductsAmount);

        if (shouldOpenCart) {
            this.setCartIsOpen(true);
        }
    };

    @action
    removeProduct = (productToRemove: Product) => {
        // Check if both _id and variants match
        const existingProductIndex = this.products.findIndex((product) => {
            return (
                product.product._id === productToRemove._id && JSON.stringify(product.product.variants) === JSON.stringify(productToRemove.variants)
            );
        });

        if (existingProductIndex !== -1) {
            const currentQuantity = this.products[existingProductIndex].quantity;

            if (currentQuantity > 1) {
                this.products = this.products?.map((product, index) =>
                    index === existingProductIndex ? { ...product, quantity: product.quantity - 1 } : product
                );
            } else {
                this.products = this.products.filter((product, index) => index !== existingProductIndex);
            }
            const newProductsAmount = this.calculateProductsAmount();

            this.updateCartAmount(newProductsAmount);
        }
    };

    @action
    removeCoupon = () => {
        const isCouponValidForCart = this.cartCoupon.isCouponValidForCart;

        if (isCouponValidForCart) {
            this.updateCartAmount(this.cartAmount.productsAmount);
        }

        this.setCartCoupon(new CartCouponModel());
    };

    @action
    resetCart = () => {
        this.selectedPayment = PaymentOptions.ONLINE;
        this.products = [];
        this.setCartCoupon(new CartCouponModel());
        this.selectedShippingAddress = {
            key: -2,
            label: '',
        };
        this.selectedPickupStore = {
            key: -2,
            label: '',
        };
    };

    @action
    createOrder = async (orderDetails: CreateOrder) => {
        try {
            this.setIsLoading(true);
            const { data } = await OrdersFetcher.createOrder(orderDetails);
            this.resetCart();
            ToastUtil.success(data.message);
            return data.orderId;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    setDeliveryOption = (deliveryOption: string) => {
        const isSelfPickup = deliveryOption === DevliveryOptions.SELF_PICKUP;
        const isFreeShipping = this.cartAmount.productsAmount >= this.minAmountForFreeShipping;
        const { productsAmount } = this.cartAmount;

        this.cartAmount = {
            ...this.cartAmount,
            shippingAmount: isSelfPickup ? 0 : isFreeShipping ? 0 : this.shippingFee,
            totalAmount: isSelfPickup ? productsAmount : isFreeShipping ? productsAmount : parseFloat((productsAmount + this.shippingFee).toFixed(2)),
        };
        this.deliveryOption = deliveryOption;
    };
}

export default CartStore;
