import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import StoreInformationStore from '../../../stores/storeInformation/StoreInformation.store';
import { STORE_INFORMATION_STORE } from '../../../stores/constants';
import mainBannerStyle from './MainBanner.module.scss';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const MainBanner = observer(() => {
    const { storeInformation } = storeInformationStore;
    const { mainBannerUrl } = storeInformation;

    if (!mainBannerUrl) return null;

    return (
        <div className={mainBannerStyle.container}>
            <img src={mainBannerUrl} alt={'main-banner'} className={mainBannerStyle.banner} />
        </div>
    );
});

export default MainBanner;
