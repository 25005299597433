import React from 'react';
import optionCardStyle from './OptionCard.module.scss';

interface IProps {
    onDeliveryOptionClick: (value: string) => void;
    value: string;
    label: string;
    isSelected: boolean;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const OptionCard = (props: IProps) => {
    const { onDeliveryOptionClick, label, isSelected, value, icon: Icon } = props;

    return (
        <div
            className={`${optionCardStyle.optionsContainer} ${isSelected ? optionCardStyle.selectedOption : ''}`}
            onClick={() => onDeliveryOptionClick(value)}
        >
            <div className={`${optionCardStyle.iconContainer} row`}>
                <Icon className={`${optionCardStyle.optionsIcon} ${isSelected ? optionCardStyle.activeOptionsIcon : ''}`} />
            </div>
            <div className={`${optionCardStyle.labelContainer} row`}>
                <span>{label}</span>
            </div>
        </div>
    );
};

export default OptionCard;
