import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ClientConfig from '../../config/index';
import { useTranslation } from 'react-i18next';
import MainBanner from '../../components/banners/mainBanner/MainBanner';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import OurServices from '../../components/ourServices/OurServices';
import PromotionProducts from '../../components/product/promotionProducts/PromotionProducts';
import rootStores from '../../stores';
import ProductsStore from '../../stores/products/Products.store';
import SectionsStore from '../../stores/sections/Sections.store';
import { PRODUCTS_STORE, SECTIONS_STORE } from '../../stores/constants';
import homeStyle from './Home.module.scss';
import SectionsCard from '../../components/sectionsCard/SectionsCard';

import {} from '../../stores/constants';

const productsStore = rootStores[PRODUCTS_STORE] as ProductsStore;
const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;

const Home = observer(() => {
    const { hotProducts, onSaleProducts, getHotProducts, getProductsOnSale, isLoading } = productsStore;
    const { sections, setSelectedSection, setSelectedTopic } = sectionsStore;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getHotProducts();
            } catch (error) {}

            try {
                await getProductsOnSale();
            } catch (error) {}
        };

        fetchData();
    }, [getHotProducts, getProductsOnSale]);

    return (
        <>
            <div className={`${homeStyle.homePageContainer} ${isLoading ? 'blur-background' : ''}`}>
                <MainBanner />
                <div className={`${homeStyle.homePageSectionsCardsContainer}`}>
                    <div className={`${homeStyle.sectionsCardsRow} row`}>
                        {sections.map(
                            (section) =>
                                section.displaySectionCard && (
                                    <div key={section.sectionId} className={`${homeStyle.sectionCardColumn} col-6 col-xl-3`}>
                                        <SectionsCard section={section} setSelectedSection={setSelectedSection} setSelectedTopic={setSelectedTopic} />
                                    </div>
                                )
                        )}
                    </div>
                </div>
                <div className={homeStyle.homePageHotProductsContainer}>
                    <PromotionProducts products={hotProducts} title={t('home.hotProducts')} />
                </div>
                {!ClientConfig.isProvider && (
                    <div className={homeStyle.homePageSaleProductsContainer}>
                        <PromotionProducts products={onSaleProducts} title={t('home.onSaleProducts')} />
                    </div>
                )}
                <OurServices />
            </div>
            {isLoading && <Spinner />}
        </>
    );
});

export default Home;
