export default class PaymentAmountModel {
    shippingAmount: number = 0;

    productsAmount: number = 0;

    totalAmount: number = 0;

    constructor(psm?: PaymentAmountModel) {
        if (psm) {
            this.shippingAmount = psm.shippingAmount;
            this.productsAmount = psm.productsAmount;
            this.totalAmount = psm.totalAmount;
        }
    }
}
