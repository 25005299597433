import ClientConfig from '../../config/index';
import { observer } from 'mobx-react';
import footerStyle from './Footer.module.scss';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { SocialIcon } from 'react-social-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import rootStores from '../../stores';
import { STORE_INFORMATION_STORE } from '../../stores/constants';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import { RoutesUrl } from '../../router/RoutesUrls';
import Information from '../information/Information';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const Footer = observer(() => {
    const { storeInformation } = storeInformationStore;
    const { aboutText, mediaLinks, information, openingHours } = storeInformation;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const renderIconByType = (type: string) => {
        switch (type) {
            case 'address':
                return <HomeOutlinedIcon className={footerStyle.footerIcon} />;
            case 'phone':
                return <LocalPhoneOutlinedIcon className={footerStyle.footerIcon} />;
            case 'email':
                return <EmailOutlinedIcon className={footerStyle.footerIcon} />;
            default:
                return null;
        }
    };

    return (
        <div className={footerStyle.footerContainer}>
            {!ClientConfig.isProvider && <Information />}
            <div className={`${footerStyle.footerInformationContainer}`}>
                <div className={`${footerStyle.footerInformationRow} row`}>
                    <div className={`${footerStyle.footerColumn} col-12 col-md-4`}>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span className={footerStyle.title}>{t('footer.aboutUs')}</span>
                        </div>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span className={footerStyle.contentText}>{aboutText}</span>
                        </div>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span
                                className={`${footerStyle.contentText} ${footerStyle.referenceLink}`}
                                onClick={() => {
                                    navigate(RoutesUrl.privacyPolicy);
                                }}
                            >
                                {t('footer.privacyPolicy')}
                            </span>
                        </div>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span
                                className={`${footerStyle.contentText} ${footerStyle.referenceLink}`}
                                onClick={() => {
                                    navigate(RoutesUrl.deliveryPolicy);
                                }}
                            >
                                {t('footer.deliveryPolicy')}
                            </span>
                        </div>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span
                                className={`${footerStyle.contentText} ${footerStyle.referenceLink}`}
                                onClick={() => {
                                    navigate(RoutesUrl.usagePolicy);
                                }}
                            >
                                {t('footer.usagePolicy')}
                            </span>
                        </div>
                        <div className={`${footerStyle.rowInformationContainer} ${footerStyle.iconContainer} row`}>
                            {mediaLinks?.map((media, index) => (
                                <SocialIcon key={index} className={footerStyle.footerSocialIcon} url={media.url} />
                            ))}
                        </div>
                    </div>
                    <div className={`${footerStyle.footerColumn} col-12 col-md-4`}>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span className={footerStyle.title}>{t('footer.contactUs')}</span>
                        </div>
                        {information?.map((element, index) => (
                            <div className={`${footerStyle.rowInformationContainer} row`} key={index}>
                                <span className={footerStyle.footerIconSpan}>{renderIconByType(element.type)}</span>
                                <span className={footerStyle.contentText}>{element.value}</span>
                            </div>
                        ))}
                    </div>
                    <div className={`${footerStyle.footerColumn} col-12 col-md-4`}>
                        <div className={`${footerStyle.rowInformationContainer} row`}>
                            <span className={footerStyle.title}>{t('footer.openingHours')}</span>
                        </div>
                        {openingHours?.map((info, index) => (
                            <div className={`${footerStyle.rowInformationContainer} row`} key={index}>
                                <span className={footerStyle.contentText}>
                                    {info.days}: {info.hours}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`${footerStyle.footerCopyrightContainer} row`}>
                    <a className={`${footerStyle.contentText} ${footerStyle.referenceLink}`} href='https://www.mtdigital.co.il/'>
                        {t('footer.mtdigital')}
                    </a>
                    <span className={footerStyle.contentText}>{t('footer.copyright')}</span>
                </div>
            </div>
        </div>
    );
});

export default Footer;
