import { useEffect } from 'react';
import privacyPolicyStyle from './PrivacyPolicy.module.scss';
import rootStores from '../../stores';
import { SYSTEM_STORE } from '../../stores/constants';
import SystemStore from '../../stores/system/System.store';
import NotFound from '../notFound/NotFound';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const PrivacyPolicy = () => {
    const { getPrivacyPolicyPage, privacyPolicyPage } = systemStore;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getPrivacyPolicyPage();
            } catch (error) {}
        };

        fetchData();
    }, [getPrivacyPolicyPage]);

    return (
        <>
            {privacyPolicyPage ? (
                <div className={privacyPolicyStyle.privacyPolicyContainer}>
                    <div dangerouslySetInnerHTML={{ __html: privacyPolicyPage }} />
                </div>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default PrivacyPolicy;
