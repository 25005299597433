import OrderingModel from '../order/OrderingPolicy.model';
import LegalcyModel from './Legalcy.model';

export default class SystemModel {
    ordering: OrderingModel = new OrderingModel();

    legalcy: LegalcyModel = new LegalcyModel();

    constructor(system?: SystemModel) {
        if (system) {
            this.ordering = system.ordering;
            this.legalcy = system.legalcy;
        }
    }
}
