import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ClientConfig from '../../../config/index';
import Stepper from '@mui/material/Stepper';
import { observer } from 'mobx-react';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../../router/RoutesUrls';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import { CART_STORE } from '../../../stores/constants';
import IButton from '../../commonComponents/button/IButton';
import CheckoutCustomerInformation from '../checkoutCustomerInformation/CheckoutCustomerInformation';
import CheckoutDeliveryOptions from '../checkoutDeliveryOptions/CheckoutDeliveryOptions';
import CheckoutOrderConfirmation from '../checkoutOrderConfirmation/CheckoutOrderConfirmation';
import CheckoutPayment from '../checkoutPayment/CheckoutPayment';
import './CheckoutStepper.customization.css';
import checkoutStepperStyle from './CheckoutStepper.module.scss';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { DevliveryOptions } from '../../../enums/enums';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha from '../../commonComponents/recaptcha/Recaptcha';
import CheckoutProviderOrder from '../checkoutProviderOrder/CheckoutProviderOrder';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

const CheckoutStepper = observer(() => {
    const { deliveryOption, selectedShippingAddress, selectedPickupStore, products } = cartStoreStore;

    const { t } = useTranslation();
    const captchaRef = useRef<ReCAPTCHA | null>(null);

    const [activeStep, setActiveStep] = useState<number>(1);
    const [contextKey, setContextKey] = useState<string>(t('checkoutStepper.information'));

    const nextButtonDisabled =
        (deliveryOption === DevliveryOptions.DELIVERY && selectedShippingAddress.label === '') ||
        (deliveryOption === DevliveryOptions.SELF_PICKUP && selectedPickupStore.label === '') ||
        products.length === 0 ||
        deliveryOption === '';

    const navigate = useNavigate();

    const steps = [
        {
            key: t('checkoutStepper.cart'),
            value: t('checkoutStepper.cart'),
        },
        {
            key: t('checkoutStepper.information'),
            value: t('checkoutStepper.information'),
        },
        {
            key: t('checkoutStepper.shipping'),
            value: t('checkoutStepper.shipping'),
        },
        {
            key: !ClientConfig.isProvider ? t('checkoutStepper.payment') : t('checkoutStepper.order'),
            value: !ClientConfig.isProvider ? t('checkoutStepper.payment') : t('checkoutStepper.order'),
        },
        {
            key: t('checkoutStepper.confirmation'),
            value: t('checkoutStepper.confirmation'),
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep !== steps.length - 1) {
            setContextKey(steps[activeStep + 1].key);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setContextKey(steps[activeStep - 1].key);
        if (activeStep === 1) {
            navigate(RoutesUrl.cart);
        }
    };

    const stepContexts = {
        [t('checkoutStepper.information')]: <CheckoutCustomerInformation />,
        [t('checkoutStepper.shipping')]: <CheckoutDeliveryOptions handleChangeAddress={handleBack} />,
        [t('checkoutStepper.payment')]: <CheckoutPayment />,
        [t('checkoutStepper.order')]: <CheckoutProviderOrder />,
        [t('checkoutStepper.confirmation')]: <CheckoutOrderConfirmation captchaV2Token={captchaRef.current?.getValue()} handleBack={handleBack} />,
    };

    return (
        <div className={checkoutStepperStyle.checkoutStepperContainer}>
            <div className={`checkout-stepper-container`}>
                <Stepper activeStep={activeStep}>
                    {steps.map((step) => {
                        return (
                            <Step key={step.key}>
                                <StepLabel>{step.value}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>

            <div className={checkoutStepperStyle.bodyStepperContainer}>
                <div className={`${checkoutStepperStyle.contextContainer}`}>{stepContexts[contextKey]}</div>
                {activeStep === steps.length - 2 && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '2em' }}>
                        <Recaptcha captchaRef={captchaRef} />
                    </div>
                )}
                {steps.length - 1 !== activeStep && (
                    <div className={`${checkoutStepperStyle.buttonsContainer}`}>
                        <IButton
                            className={`${commonStyles.defaultButton}`}
                            disabled={false}
                            onClick={handleBack}
                            title={t('checkoutStepper.prev')}
                        />
                        <IButton
                            className={`${commonStyles.defaultButton}`}
                            disabled={nextButtonDisabled}
                            onClick={handleNext}
                            title={activeStep === steps.length - 2 ? t('checkoutStepper.payNow') : t('checkoutStepper.next')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default CheckoutStepper;
