const information = {
    freeShippingTitle: 'משלוח חינם',
    freeShippingContext: 'משלוח חינם לכל המוצרים מעל קניה של {{min}} ש"ח',
    cancelationPolicyTitle: 'מדיניות ביטולים',
    cancelationPolicyContext: 'ניתן להחזיר כל מוצר בהתאם למדיניות הביטולים',
    securePurchaseTitle: 'קניה מאובטחת',
    securePurchaseContext: 'עמידה בכל התקנים של חברות האשראי',
};

export default information;
