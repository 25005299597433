import DiscountModel from './DiscountModel.model';

export default class CouponModel {
    code: string = '';
    minAmount: number = 0;
    blockedSections: string[] = [];
    blockedCategories: string[] = [];
    blockedSubCategories: string[] = [];
    blockedProducts: string[] = [];
    discount: DiscountModel = new DiscountModel();

    constructor(coupon?: CouponModel) {
        if (coupon) {
            this.code = coupon.code;
            this.minAmount = coupon.minAmount;
            this.blockedSections = coupon.blockedSections;
            this.blockedCategories = coupon.blockedCategories;
            this.blockedSubCategories = coupon.blockedSubCategories;
            this.blockedProducts = coupon.blockedProducts;
            this.discount = coupon.discount;
        }
    }
}
