import ClientConfig from '../config/index';
import { IResetPasswordPayload, ISendEmailPayload } from '../interfaces/interfaces';
import LoginCredentials from '../models/auth/LoginCredentials.model';
import RegistrationDetails from '../models/auth/RegistrationDetails.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const storeInformationPrefix = 'authManagement';

class AuthFetcher extends BaseHttpFetcher {
    async login(payload: LoginCredentials): Promise<any> {
        return await this.post(`${storeInformationPrefix}/login`, payload);
    }

    async registration(payload: RegistrationDetails): Promise<any> {
        return await this.post(`${storeInformationPrefix}/registration`, payload);
    }

    async sendEmailforgotPassword(payload: ISendEmailPayload): Promise<any> {
        return await this.post(`${storeInformationPrefix}/forgot-password`, payload);
    }

    async resetPassword(payload: IResetPasswordPayload): Promise<any> {
        return await this.post(`${storeInformationPrefix}/reset-password`, { password: payload.password }, {}, { token: payload.token });
    }
}

const loginFetcherInstance = new AuthFetcher('/', ClientConfig.apiBaseHost);
export default loginFetcherInstance;
