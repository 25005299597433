export default class OrderingPolicyModel {
    shippingFee: number = 0;

    minAmountForFreeShipping: number = 0;

    maxDeliveryDays: number = 0;

    constructor(ordering?: OrderingPolicyModel) {
        if (ordering) {
            this.shippingFee = ordering.shippingFee;
            this.minAmountForFreeShipping = ordering.minAmountForFreeShipping;
            this.maxDeliveryDays = ordering.maxDeliveryDays;
        }
    }
}
