import React, { useState } from 'react';
import sideFilterstyle from './SideFilters.module.scss';
import { Drawer } from 'antd';
import Filter from '../filter/Filter';
import { useTranslation } from 'react-i18next';

interface Iprops {
    sectionId: string;
    changeFiltersHandler: (selectedFilter: { [key: string]: boolean }) => void;
}

const SideFilters = (props: Iprops) => {
    const { sectionId, changeFiltersHandler } = props;
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const { t } = useTranslation();

    const onFiltersClickHandler = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };

    const onChangeFiltersHandler = (selectedFilter: { [key: string]: boolean }) => {
        onFiltersClickHandler();
        changeFiltersHandler(selectedFilter);
    };

    return (
        <>
            <div className={`${sideFilterstyle.filterContainer} row`} onClick={onFiltersClickHandler}>
                <span>{t('sideFilters.filter')}</span>
            </div>
            <Drawer placement='right' onClose={onFiltersClickHandler} open={isFiltersOpen}>
                <Filter sectionId={sectionId} changeFiltersHandler={onChangeFiltersHandler} />
            </Drawer>
        </>
    );
};

export default SideFilters;
