import loginForm from '../components/auth/login/LoginForm.i18n.he';
import registrationForm from '../components/auth/registration/RegistrationForm.i18n.he';
import reset from '../components/auth/reset/Reset.i18n.he';
import sendEmail from '../components/auth/sendEmail/SendEmail.i18n.he';
import cartItems from '../components/cart/cartItems/CartItems.i18n.he';
import cartTitle from '../components/cart/cartTitle/CartTitle.i18n.he';
import cartTotalPrice from '../components/cart/cartTotalPrice/CartTotalPrice.i18n.he';
import deliveryOptions from '../components/cart/deliveryOptions/DeliveryOptions.i18n.he';
import cartDrawer from '../components/cartDrawer/CartDrawer.i18n.he';
import checkoutCustomerInformation from '../components/checkout/checkoutCustomerInformation/CheckoutCustomerInformation.i18n.he';
import checkoutDeliveryOptions from '../components/checkout/checkoutDeliveryOptions/CheckoutDeliveryOptions.i18n.he';
import checkoutItemsCollapse from '../components/checkout/checkoutItemsCollapse/CheckoutItemsCollapse.i18n.he';
import checkoutOrderConfirmation from '../components/checkout/checkoutOrderConfirmation/CheckoutOrderConfirmation.i18n.he';
import checkoutStepper from '../components/checkout/checkoutStepper/CheckoutStepper.i18n.he';
import footer from '../components/footer/Footer.i18n.he';
import myProfile from '../components/myProfile/MyProfile.i18n.he';
import editUserForm from '../components/myProfile/editUser/EditUserForm.i18n.he';
import notFoundMessage from '../components/notFoundMessage/NotFoundMessage.i18n.he';
import ourServices from '../components/ourServices/OurServices.i18n.he';
import productInformation from '../components/product/productInformation/ProductInformation.i18n.he';
import productMiniCard from '../components/product/productMiniCard/ProductMiniCard.i18n.he';
import sideFilters from '../components/sideFilters/SideFilters.i18n.he';
import sortProductOptions from '../components/sortProductOptions/SortProductOptions.i18n.he';
import termsOfUseModal from '../components/termsOfUseModal/TermsOfUseModal.i18n.he';
import auth from '../screens/auth/Auth.i18n.he';
import cart from '../screens/cart/Cart.i18n.he';
import home from '../screens/home/Home.i18n.he';
import productsPage from '../screens/productsPage/ProductPage.i18n.he';
import registrationConfirmation from '../screens/registrationConfirmation/RegistrationConfirmation.i18n.he';
import checkoutPayment from '../components/checkout/checkoutPayment/CheckoutPayment.i18n.he';
import information from '../components/information/Information.i18n.he';
import coupon from '../components/coupon/Coupon.i18n.he';
import categoriesSelection from '../screens/categoriesSelection/CategoriesSelection.i18n.he';
import checkoutProviderOrder from '../components/checkout/checkoutProviderOrder/CheckoutProviderOrder.i18n.he';
import sideMenu from '../components/header/sideMenu/SideMenu.i18n.he';

export const heDictionary = {
    ourServices,
    loginForm,
    registrationForm,
    editUserForm,
    cartItems,
    cartTitle,
    cartTotalPrice,
    deliveryOptions,
    cartDrawer,
    footer,
    myProfile,
    notFoundMessage,
    home,
    cart,
    auth,
    sideFilters,
    productMiniCard,
    productInformation,
    productsPage,
    checkoutItemsCollapse,
    checkoutStepper,
    checkoutDeliveryOptions,
    checkoutCustomerInformation,
    checkoutOrderConfirmation,
    sortProductOptions,
    registrationConfirmation,
    reset,
    sendEmail,
    termsOfUseModal,
    checkoutPayment,
    information,
    coupon,
    categoriesSelection,
    checkoutProviderOrder,
    sideMenu,
};
