import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Column, RowData } from '../../interfaces/interfaces';
import { ITableRow } from './ITableRow';

import collapsibleTableStyle from './CollapsibleTable.module.scss';

interface TableProps {
    columns: Column[];
    rows: RowData[];
    collapsible?: boolean;
}

const ICollapsibleTable = (props: TableProps) => {
    const { columns, rows, collapsible } = props;

    return (
        <TableContainer className={collapsibleTableStyle.tableContainer} component={Paper}>
            <Table className={collapsibleTableStyle.table} aria-label='generic table'>
                <TableHead className={collapsibleTableStyle.columnsContainer}>
                    <TableRow className={collapsibleTableStyle.headerRowContainer}>
                        {columns.map((column, index) => (
                            <TableCell key={`${column.id}_${index}`} className={collapsibleTableStyle.headerTableCell} align={column.align || 'left'}>
                                {column.label}
                            </TableCell>
                        ))}
                        {collapsible && <TableCell className={collapsibleTableStyle.checkboxHeaderTableCell} />}
                    </TableRow>
                </TableHead>
                <TableBody className={collapsibleTableStyle.tableBody}>
                    {rows.map((row) => (
                        <ITableRow key={row._id} row={row} columns={columns} collapsible={collapsible} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ICollapsibleTable;
