import React from 'react';
import cartStyle from './CartTitle.module.scss';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CartTitle = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className={cartStyle.cartHeaderContainer}>
            <span>{t('cartTitle.cart')}</span>
            <span
                onClick={() => navigate(`/`)}
                className={`${cartStyle.continueShopping} ${commonStyles.animatedBottomBorder} ${commonStyles.fromRight} ${commonStyles.textAnimated}`}
            >
                {t('cartTitle.continueShopping')}
            </span>
        </div>
    );
};

export default CartTitle;
