import ClientConfig from '../config/index';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const storeInformationPrefix = 'ordersManagement';

class OrdersFetcher extends BaseHttpFetcher {
    async getsOrdersByUserId(userId: string): Promise<any> {
        return await this.get(`${storeInformationPrefix}/${userId}`);
    }

    async createOrder(orderDetails: object): Promise<any> {
        return await this.post(`${storeInformationPrefix}/createOrder`, {
            orderDetails,
        });
    }
}

const ordersFetcherInstance = new OrdersFetcher('/', ClientConfig.apiBaseHost);
export default ordersFetcherInstance;
