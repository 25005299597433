import { useState } from 'react';
import variantRadioGroupStyle from './VariantRadioGroup.module.scss';
import VariantsOption from '../../../models/product/VariantsOption.model';
import ProductsVariant from '../../../models/product/ProductVariant.model';
import { Currency } from '../../../enums/enums';

interface IProps {
    variant: ProductsVariant;
    onSelectVariantOptionHandler: (selectedVariants: ProductsVariant) => void;
}

const VariantRadioGroup = (props: IProps) => {
    const { variant, onSelectVariantOptionHandler } = props;
    const [selected, setSelected] = useState<string>('');

    const onSelectHandler = (option: VariantsOption) => {
        selected === option.value ? setSelected('') : setSelected(option.value);
        const selectedVariantsOption = new ProductsVariant(variant);
        selectedVariantsOption.options = [option];
        onSelectVariantOptionHandler(selectedVariantsOption);
    };

    return (
        <div className={variantRadioGroupStyle.radioGroupContainer}>
            {variant.options.map((option: VariantsOption, i) => (
                <div
                    key={i}
                    onClick={option.inStock ? () => onSelectHandler(option) : () => {}}
                    className={`${variantRadioGroupStyle.optionContainer} ${!option.inStock ? variantRadioGroupStyle.outOfStock : ''} ${
                        selected === option.value ? variantRadioGroupStyle.selected : ''
                    }`}
                >
                    <div>
                        <div className={`${variantRadioGroupStyle.informationRow} row`}>
                            <span>{option.value}</span>
                        </div>
                        {option.extraPrice !== 0 && (
                            <div className={`${variantRadioGroupStyle.informationRow} row`}>
                                <span className={variantRadioGroupStyle.extraPrice}>
                                    +{Currency.SHEKEL}
                                    {option.extraPrice.toLocaleString()}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VariantRadioGroup;
