import { useTranslation } from 'react-i18next';
import commonStyle from '../../../styles/commonStyles.module.scss';
import addressesStyle from './Addresses.module.scss';

import IButton from '../../commonComponents/button/IButton';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react';
import { useState } from 'react';
import UserStore from '../../../stores/user/User.store';
import { USER_STORE } from '../../../stores/constants';
import rootStores from '../../../stores/index';
import IDivider from '../../commonComponents/divider/IDivider';
import Spinner from '../../commonComponents/spinner/Spinner';
import AddAddressForm from './AddAddressForm';
import Address from './Address';
import { RoutesUrl } from '../../../router/RoutesUrls';

const userStore = rootStores[USER_STORE] as UserStore;

const Addresses = observer(() => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const { user, isLoading } = userStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onAddAddressClick = () => setIsFormOpen(!isFormOpen);

    return (
        <div className={addressesStyle.addressesPageContainer}>
            <div className={addressesStyle.addressesTitleContainer}>
                <span className={addressesStyle.addressesTitle}>{t('myProfile.addressesTitle')}</span>
            </div>
            <div className={addressesStyle.backButtonContainer}>
                <IButton className={`${commonStyle.textButton}`} title={t('editUserForm.goBack')} onClick={() => navigate(RoutesUrl.profile)} />
            </div>
            <IButton className={`${commonStyle.defaultButton}`} title={t('myProfile.addAddress')} onClick={onAddAddressClick} />
            {isFormOpen && (
                <div className={addressesStyle.addFormContainer}>
                    <AddAddressForm setIsFormOpen={setIsFormOpen} />
                </div>
            )}

            {user.shippingAddresses.length !== 0 && (
                <div className={addressesStyle.addressesContainer}>
                    {user.shippingAddresses.length !== 0 && isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <span className={addressesStyle.defaultTitle}>{t('myProfile.defaultTitle')}</span>
                            <Address user={user} address={user.shippingAddresses[0]} />

                            <div className={addressesStyle.dividerContainer}>
                                <IDivider className={commonStyle.addressesDivider} />
                            </div>

                            {user.shippingAddresses.slice(1).map((address) => (
                                <>
                                    <Address key={address.address} user={user} address={address} />
                                    <div key={`div_${address.address}`} className={addressesStyle.dividerContainer}>
                                        <IDivider key={`divider_${address.address}`} className={commonStyle.addressesDivider} />
                                    </div>
                                </>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );
});

export default Addresses;
