import React from 'react';
import dropdownStyle from './Dropdown.module.scss';
import { ISelectOption } from '../../../interfaces/interfaces';

interface Iprops {
    placeholder: string;
    options: ISelectOption[];
    onSelect: (address: ISelectOption) => void;
    disabled?: boolean;
    selectedKey: number | null;
}

const Dropdown = (props: Iprops) => {
    const { placeholder, options, selectedKey, disabled, onSelect } = props;

    return (
        <select
            disabled={disabled}
            className={dropdownStyle.dropdownCustom}
            value={selectedKey ? selectedKey : -2}
            onChange={(e) => {
                const selected = e.target.value;
                const selectedOption = options.find((option: ISelectOption) => option.key === Number(selected));
                onSelect(selectedOption ? selectedOption : { key: -1, label: '' });
            }}
        >
            <option value={-2} disabled>
                {placeholder}
            </option>
            {options.map((option: ISelectOption) => (
                <option key={option.key} value={option.key}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Dropdown;
