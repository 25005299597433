import ClientConfig from '../config/index';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const productsPrefix = 'productManagement';

class ProductsFetchers extends BaseHttpFetcher {
    async getProductById(productId: string): Promise<any> {
        return await this.get(`${productsPrefix}/product/${productId}`);
    }
}

const productsFetcherInstance = new ProductsFetchers('/', ClientConfig.apiBaseHost);
export default productsFetcherInstance;
