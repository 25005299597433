import CategoryModel from '../../../models/topics/Category.model';
import SectionModel from '../../../models/topics/Section.model';
import headerStyle from '../Header.module.scss';
import Category from './Category';

interface IProps {
    section: SectionModel;
    transitionRef: React.RefObject<HTMLDivElement>;
    onCategoryClick: (sectionId: string, sectionName: string) => void;
    categories: CategoryModel[];
}

const Categories = (props: IProps) => {
    const { section, transitionRef, categories, onCategoryClick } = props;
    return (
        <div key={`${section.sectionId}_categories`} ref={transitionRef} className={headerStyle.popupContainer}>
            {categories.map((category) => {
                return (
                    !category.disabled && (
                        <div key={category.categoryId} className={headerStyle.categoryContainer}>
                            <span
                                className={`${headerStyle.categoryTitleName} ${headerStyle.textAnimated}`}
                                onClick={() => {
                                    onCategoryClick(category.categoryId, category.categoryName);
                                }}
                            >
                                {category.categoryName}
                            </span>

                            {category.subCategories?.map((subCategory) => {
                                return (
                                    !subCategory.disabled && (
                                        <Category key={subCategory.categoryId} subCategory={subCategory} onCategoryClick={onCategoryClick} />
                                    )
                                );
                            })}
                        </div>
                    )
                );
            })}
        </div>
    );
};

export default Categories;
