import rootStores from '../../../stores';
import UserStore from '../../../stores/user/User.store';
import { USER_STORE } from '../../../stores/constants';
import EditUserForm from './EditUserForm';

const userStore = rootStores[USER_STORE] as UserStore;

interface IProps {
    setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditUser = (props: IProps) => {
    const { setIsOnEditMode } = props;
    const { updateUserDetails } = userStore;

    return <EditUserForm editUserHandler={updateUserDetails} setIsOnEditMode={setIsOnEditMode} />;
};

export default EditUser;
