import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react';
import ProductInformation from '../productInformation/ProductInformation';
import productModalStyle from './ProductModal.module.scss';

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}

const ProductModal = observer((props: IProps) => {
    const { isOpen, handleClose } = props;

    return (
        <div>
            <Modal open={isOpen} onClose={handleClose}>
                <div className={productModalStyle.productModalContainer}>
                    <span onClick={handleClose} className={productModalStyle.productModalCloseButtonContainer}>
                        <CloseIcon />
                    </span>
                    <ProductInformation handleClose={handleClose} />
                </div>
            </Modal>
        </div>
    );
});

export default ProductModal;
