import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';

const sectionsPrefix = 'sectionsManagement';

class SectionsFetcher extends BaseHttpFetcher {
    async getSections(): Promise<any> {
        return await this.get(`${sectionsPrefix}/sections`);
    }
}

const sectionsFetcherInstance = new SectionsFetcher('/', ClientConfig.apiBaseHost);
export default sectionsFetcherInstance;
