import VariantsOption from './VariantsOption.model';

export default class ProductsVariant {
    type: string = '';

    label: string = '';

    options: VariantsOption[] = [];

    constructor(productsVariant?: ProductsVariant) {
        if (productsVariant) {
            this.type = productsVariant.type;
            this.label = productsVariant.label;
            this.options = productsVariant.options;
        }
    }
}
