import usagePolicyStyle from './UsagePolicy.module.scss';
import { useEffect } from 'react';
import rootStores from '../../stores';
import { SYSTEM_STORE } from '../../stores/constants';
import SystemStore from '../../stores/system/System.store';
import NotFound from '../notFound/NotFound';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const UsagePolicy = () => {
    const { getSiteUsagePolicyPage, siteUsagePolicyPage } = systemStore;

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getSiteUsagePolicyPage();
            } catch (error) {}
        };

        fetchData();
    }, [getSiteUsagePolicyPage]);

    return (
        <>
            {siteUsagePolicyPage ? (
                <div className={usagePolicyStyle.usagePolicyContainer}>
                    <div dangerouslySetInnerHTML={{ __html: siteUsagePolicyPage }} />
                </div>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default UsagePolicy;
