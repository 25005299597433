export const RoutesParams = {
    id: 'id',
    token: 'token',
    user: 'user',
    username: 'username',
    password: 'password',
    sectionName: 'sectionName',
    categoryName: 'categoryName',
    productId: 'productId',
};

export const RoutesUrl = {
    welcome: '/',
    auth: '/auth',
    profile: '/profile',
    addresses: '/profile/addresses',
    cart: '/cart',
    checkout: '/checkout',
    registrationConfirmation: '/registration-confirmation',
    restPassword: '/auth/reset-password',
    productsPage: `/products-page/:${RoutesParams.sectionName}`,
    productDetails: `/product-details/:${RoutesParams.productId}`,
    orders: `/profile/orders`,
    privacyPolicy: '/privacy-policy',
    deliveryPolicy: '/delivery-policy',
    usagePolicy: '/usage-policy',
    categories: '/categories',
};
