import { useEffect } from 'react';
import { observer } from 'mobx-react';
import ClientConfig from './config';
import { BrowserRouter } from 'react-router-dom';
import ToastComponent from './components/commonComponents/toastComponent/ToastComponent';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Router from './router/Router';
import rootStores from './stores';
import { SYSTEM_STORE, STORE_INFORMATION_STORE, SECTIONS_STORE } from './stores/constants';
import SystemStore from './stores/system/System.store';
import SectionsStore from './stores/sections/Sections.store';
import StoreInformationStore from './stores/storeInformation/StoreInformation.store';
import Spinner from './components/commonComponents/spinner/Spinner';
import TermsOfUseModal from './components/termsOfUseModal/TermsOfUseModal';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;
const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;
const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;

document.documentElement.style.setProperty('--primary-color', ClientConfig.palette.primaryColor);
document.documentElement.style.setProperty('--hover-primary-color', ClientConfig.palette.hoverColor);

const useSetTitle = (title: string) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

const App = observer(() => {
    const { system, userAcceptTermsOfUse, getSystemSettings, isLoading: systemIsLoading } = systemStore;
    const {
        getStoreInformation,
        isLoading: storeInformationIsLoading,
        storeInformation: { storeName },
    } = storeInformationStore;
    const { getSections, isLoading: sectionsIsLoading } = sectionsStore;
    const isLoading = systemIsLoading || storeInformationIsLoading || sectionsIsLoading;

    useSetTitle(storeName);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getSystemSettings();
            } catch (error) {}
            try {
                await getStoreInformation();
            } catch (error) {}
            try {
                await getSections();
            } catch (error) {}
        };

        fetchData();
    }, [getSystemSettings, getStoreInformation, getSections]);

    return (
        <div className={isLoading ? 'blur-background' : ''}>
            <BrowserRouter>
                <Header />
                <Router />
                <Footer />
                <ToastComponent />
            </BrowserRouter>
            {isLoading && <Spinner />}
            {system.legalcy.termsOfUse.useTermsOfUseModal && !userAcceptTermsOfUse && <TermsOfUseModal />}
        </div>
    );
});

export default App;
