import { IDiscount } from '../../interfaces/interfaces';
import ProductVariant from './ProductVariant.model';
import ProductsCategoryModel from './ProductsCategory.model';
import ProductsSectionModel from './ProductsSection.model';
import ProductsSubCategoryModel from './ProductsSubCategory.model';

export default class ProductModel {
    _id: string = '';

    serialNumber = '';

    name: string = '';

    description: string = '';

    sections: ProductsSectionModel[] = [];

    categories: ProductsCategoryModel[] = [];

    subCategories: ProductsSubCategoryModel[] = [];

    images: string[] = [];

    inStock: boolean = true;

    price: number = 0;

    updatedPrice: number = 0;

    variants: ProductVariant[] = [];

    discount: IDiscount = { amount: 0, type: '' };

    hot: boolean = false;

    constructor(product?: ProductModel) {
        if (product) {
            this._id = product._id;
            this.name = product.name;
            this.sections = product.sections;
            this.categories = product.categories;
            this.subCategories = product.subCategories;
            this.serialNumber = product.serialNumber;
            this.description = product.description;
            this.images = product.images;
            this.inStock = product.inStock;
            this.price = product.price;
            this.updatedPrice = product.updatedPrice;
            this.variants = product.variants;
            this.discount = product.discount;
            this.hot = product.hot;
        }
    }
}
