const sendEmail = {
    resetTitle: 'אפס את הסיסמא לחשבונך',
    emailExplanation: 'נשלח אליך מייל עם קישור לאיפוס הסיסמא. אנא בדוק גם בתקיית הספאם',
    backToLogin: 'חזרה לדף התחברות',
    requiredField: 'שדה חובה',
    invalidEmail: 'כתובת מייל אינה תקינה',
    send: 'שליחה',
    youtEmail: 'כתובת מייל',
    emailSentSuccessfully: 'קישור לאיפוס סיסמא נשלח בהצלחה',
};
export default sendEmail;
