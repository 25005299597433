export default class PaymentInformation {
    lastDigits: string = '';

    selectedPayment: string = '';

    isCharged: boolean = false;

    isInvoiced: boolean = false;

    paymentMethod: string = '';

    constructor(paymentInformation?: PaymentInformation) {
        if (paymentInformation) {
            this.lastDigits = paymentInformation.lastDigits;
            this.selectedPayment = paymentInformation.selectedPayment;
            this.isCharged = paymentInformation.isCharged;
            this.isInvoiced = paymentInformation.isInvoiced;
            this.paymentMethod = paymentInformation.paymentMethod;
        }
    }
}
