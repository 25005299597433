import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import ToastUtil from '../../utils/ToastUtils';
import StoreInformationFetcher from '../../fetchers/StoreInformation.fetcher';
import StoreInformationModel from '../../models/store/StoreInformation.model';
import { ISelectOption } from '../../interfaces/interfaces';
import { CErrors } from '../../constants/constants';

class StoreInformationStore {
    @observable
    storeInformation: StoreInformationModel = new StoreInformationModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, { name: 'StoreInformationStore', properties: ['storeInformation'], storage: window.localStorage });
    }

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setStoreInformation = (storeInformation: StoreInformationModel) => (this.storeInformation = storeInformation);

    @action
    getStoreInformation = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await StoreInformationFetcher.getStoreInformation();
            this.setStoreInformation(new StoreInformationModel(data));
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get getStoresISelecet(): ISelectOption[] {
        return this.storeInformation?.information
            ?.filter((info) => info.type === 'address')
            .map((address, i) => ({
                key: i + 1,
                label: address.value,
            }));
    }
}

export default StoreInformationStore;
