import Radio from '@mui/material/Radio';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import rootStores from '../../../stores';
import CartStore from '../../../stores/cart/Cart.store';
import { CART_STORE } from '../../../stores/constants';
import IButton from '../../commonComponents/button/IButton';
import ClientConfig from '../../../config/index';
import './CheckoutDeliveryOptions.customization.css';
import checkoutDeliveryOptionsStyle from './CheckoutDeliveryOptions.module.scss';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { Currency, DevliveryOptions } from '../../../enums/enums';

const cartStoreStore = rootStores[CART_STORE] as CartStore;

interface IProps {
    handleChangeAddress: () => void;
}

const CheckoutDeliveryOptions = observer((props: IProps) => {
    const { handleChangeAddress } = props;
    const {
        deliveryOption,
        setDeliveryOption,
        selectedShippingAddress,
        selectedPickupStore,
        shippingFee,
        maxDeliveryDays,
        minAmountForFreeShipping,
        cartAmount,
    } = cartStoreStore;

    const { t } = useTranslation();

    const isDeliveryOption = deliveryOption === DevliveryOptions.DELIVERY;
    const isPickupOption = deliveryOption === DevliveryOptions.SELF_PICKUP;

    const changeMethodHandler = (value: string) => {
        setDeliveryOption(value);
    };

    return (
        <div className={checkoutDeliveryOptionsStyle.checkoutDeliveryOptionsContainer}>
            <div
                onClick={() => changeMethodHandler(DevliveryOptions.SELF_PICKUP)}
                className={`${checkoutDeliveryOptionsStyle.deliveryOptionContainer} row`}
            >
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} custom-radio-container col-2`}>
                    <Radio checked={isPickupOption} name='radio-buttons' />
                </div>
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-8`}>
                    <span>{t('checkoutDeliveryOptions.selfPickup')}</span>
                </div>
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-2`}>
                    {!ClientConfig.isProvider && <span>0 {Currency.SHEKEL}</span>}
                </div>
            </div>
            <div
                onClick={() => changeMethodHandler(DevliveryOptions.DELIVERY)}
                className={`${checkoutDeliveryOptionsStyle.deliveryOptionContainer} row`}
            >
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} custom-radio-container col-2`}>
                    <Radio checked={isDeliveryOption} name='radio-buttons' />
                </div>
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-8`}>
                    <span>
                        {!ClientConfig.isProvider
                            ? t('checkoutDeliveryOptions.delivery', { days: maxDeliveryDays })
                            : t('checkoutDeliveryOptions.shipping')}
                    </span>
                </div>
                <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-2`}>
                    {!ClientConfig.isProvider && (
                        <span>
                            {cartAmount.productsAmount >= minAmountForFreeShipping ? 0 : shippingFee} {Currency.SHEKEL}
                        </span>
                    )}
                </div>
            </div>
            <div className={`${checkoutDeliveryOptionsStyle.deliveryAddressBox}`}>
                <div className={`${checkoutDeliveryOptionsStyle.deliveryAddressContainer} row`}>
                    <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} custom-radio-container col-3`}>
                        <span className={checkoutDeliveryOptionsStyle.shippingTitle}>
                            {isDeliveryOption ? t('checkoutDeliveryOptions.shipping') : isPickupOption ? t('checkoutDeliveryOptions.selfPickup') : ''}
                            :
                        </span>
                    </div>
                    <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-8`}>
                        <span className={checkoutDeliveryOptionsStyle.address}>
                            {isDeliveryOption
                                ? selectedShippingAddress.label !== ''
                                    ? selectedShippingAddress.label
                                    : t('checkoutDeliveryOptions.addressNotFound')
                                : isPickupOption
                                ? selectedPickupStore.label !== ''
                                    ? selectedPickupStore.label
                                    : t('checkoutDeliveryOptions.storeNotFound')
                                : ''}
                        </span>
                    </div>
                    <div className={`${checkoutDeliveryOptionsStyle.deliveryOptionColumnContainer} col-1`}>
                        <IButton
                            className={`${commonStyles.textButton}`}
                            disabled={false}
                            onClick={handleChangeAddress}
                            title={
                                isDeliveryOption
                                    ? selectedShippingAddress.label !== ''
                                        ? t('checkoutDeliveryOptions.change')
                                        : t('checkoutDeliveryOptions.pick')
                                    : isPickupOption
                                    ? selectedPickupStore.label !== ''
                                        ? t('checkoutDeliveryOptions.change')
                                        : t('checkoutDeliveryOptions.pick')
                                    : ''
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CheckoutDeliveryOptions;
