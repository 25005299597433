import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { heDictionary } from './locales/he';

const resources = {
    he: {
        translation: heDictionary,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'he', // Default language
    interpolation: {
        escapeValue: false, //Allow you to include variables in translations
    },
});

export default i18n;
