import ProductModel from '../product/Product.model';

export default class ProductInOrderModel {
    product: ProductModel = new ProductModel();

    quantity: number = 0;

    constructor(product?: ProductInOrderModel) {
        if (product) {
            this.product = product.product;
            this.quantity = product.quantity;
        }
    }
}
