import { action, makeObservable, observable } from 'mobx';
import SystemFetcher from '../../fetchers/System.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import SystemModel from '../../models/system/System.model';
import CartStore from '../cart/Cart.store';
import { CErrors } from '../../constants/constants';

class SystemStore {
    @observable
    system: SystemModel = new SystemModel();

    @observable
    userAcceptTermsOfUse: boolean = false;

    @observable
    privacyPolicyPage: string = '';

    @observable
    siteUsagePolicyPage: string = '';

    @observable
    deliveryPolicyPage: string = '';

    private cartStore: CartStore;

    @observable
    isLoading: boolean = false;

    constructor(cartStore: CartStore) {
        makeObservable(this);
        this.cartStore = cartStore;
    }

    @action
    setSystem = (system: SystemModel) => (this.system = system);

    @action
    setUserAcceptTermsOfUse = (userAcceptTermsOfUse: boolean) => (this.userAcceptTermsOfUse = userAcceptTermsOfUse);

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);

    @action
    setPrivacyPolicyPage = (privacyPolicyPage: string) => (this.privacyPolicyPage = privacyPolicyPage);

    @action
    setSiteUsagePolicyPage = (siteUsagePolicyPage: string) => (this.siteUsagePolicyPage = siteUsagePolicyPage);

    @action
    setDeliveryPolicyPage = (deliveryPolicyPage: string) => (this.deliveryPolicyPage = deliveryPolicyPage);

    @action
    getSystemSettings = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.getSystemSettings();
            this.setSystem(data);
            this.cartStore.setShippingFee(data?.ordering?.shippingFee);
            this.cartStore.setMinAmountForFreeShipping(data?.ordering?.minAmountForFreeShipping);
            this.cartStore.setMaxDeliveryDays(data?.ordering?.maxDeliveryDays);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getPrivacyPolicyPage = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.getPrivacyPolicyPage();
            this.setPrivacyPolicyPage(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getSiteUsagePolicyPage = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.getSiteUsagePolicyPage();
            this.setSiteUsagePolicyPage(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getDeliveryPolicyPage = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.getDeliveryPolicyPage();
            this.setDeliveryPolicyPage(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default SystemStore;
