import commonStyles from '../../../styles/commonStyles.module.scss';

interface IProps {
    onClick?: any;
    className: string;
    title: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    disabled?: boolean;
}

const IButton = (props: IProps) => {
    const { title, onClick, className, type, disabled } = props;

    return (
        <button
            className={`${disabled ? commonStyles.disabledButton : ''} ${className}`}
            onClick={onClick}
            type={type || 'button'}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

export default IButton;
