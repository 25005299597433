import { useTranslation } from 'react-i18next';
import myProfileStyle from './MyProfile.module.scss';
import AccountDetails from './accountDetails/AccountDetails';

import rootStores from '../../stores';
import commonStyle from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/button/IButton';

import { observer } from 'mobx-react';
import AuthStore from '../../stores/auth/Auth.store';
import CartStore from '../../stores/cart/Cart.store';
import { AUTH_STORE, CART_STORE } from '../../stores/constants';
import EditUser from './editUser/EditUser';
import OrdersDetails from './ordersDetails/OrdersDetails';
import { useState } from 'react';

const authStore = rootStores[AUTH_STORE] as AuthStore;
const cartStore = rootStores[CART_STORE] as CartStore;

const MyProfile = observer(() => {
    const { logout } = authStore;
    const { setShippingAddress } = cartStore;
    const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);
    const { t } = useTranslation();

    const logoutHandler = () => {
        logout();
        setShippingAddress({
            key: -2,
            label: '',
        });
    };

    return (
        <div className={myProfileStyle.myProfileContainer}>
            <div className={`${myProfileStyle.myProfileTitleContainer}`}>
                <span>{t('myProfile.myAccount')}</span>
            </div>
            {isOnEditMode ? (
                <EditUser setIsOnEditMode={setIsOnEditMode} />
            ) : (
                <>
                    <div className={`${myProfileStyle.myProfileLogoutContainer}`}>
                        <IButton className={`${commonStyle.defaultButton}`} onClick={logoutHandler} title={t('myProfile.logout')} disabled={false} />
                        <IButton
                            className={`${commonStyle.textButton}`}
                            onClick={() => setIsOnEditMode(!isOnEditMode)}
                            title={t('myProfile.editUser')}
                            disabled={false}
                        />
                    </div>
                    <div className={`${myProfileStyle.myProfileInformationContainer}`}>
                        <AccountDetails />
                        <OrdersDetails />
                    </div>
                </>
            )}
        </div>
    );
});

export default MyProfile;
