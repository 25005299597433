import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import sectionsCardstyle from './SectionsCard.module.scss';
import { ICardImageStyle } from '../../interfaces/interfaces';
import SectionModel from '../../models/topics/Section.model';
import { useMediaQuery } from 'react-responsive';
import { RoutesUrl } from '../../router/RoutesUrls';
import TopicModel from '../../models/topics/Topic.model';

interface IProps {
    setSelectedSection: (selectedSection: SectionModel) => void;
    setSelectedTopic: (selectedTopic: TopicModel) => void;
    section: SectionModel;
}

const SectionsCard = observer((props: IProps) => {
    const { setSelectedSection, setSelectedTopic, section } = props;

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const navigate = useNavigate();
    const cardImageStyle: ICardImageStyle = {
        '--background-image': `url(${section.image})`,
    };

    const onClickHandler = (section: SectionModel) => {
        setSelectedSection(section);
        setSelectedTopic(
            new TopicModel({
                topicId: section.sectionId,
                topicName: section.sectionName,
            })
        );
        isMobileScreen ? navigate(RoutesUrl.categories) : navigate(`products-page/${section.sectionName}`);
    };

    return (
        <div
            onClick={() => {
                onClickHandler(section);
            }}
            className={sectionsCardstyle.sectionCardContainer}
            style={cardImageStyle}
        >
            <span className={sectionsCardstyle.overlayText}>{section.sectionName}</span>
        </div>
    );
});

export default SectionsCard;
