import React, { useState } from 'react';
import ClientConfig from '../../../config/index';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import ProductModal from '../../../components/product/productModal/ProductModal';
import Product from '../../../models/product/Product.model';
import productMiniCardStyle from './ProductMiniCard.module.scss';

import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import ProductStore from '../../../stores/product/Product.store';
import { PRODUCT_STORE } from '../../../stores/constants';
import { ICardImageStyle } from '../../../interfaces/interfaces';
import { Currency } from '../../../enums/enums';
const productStore = rootStores[PRODUCT_STORE] as ProductStore;

interface IProps {
    product: Product;
}

const ProductMiniCard = observer((props: IProps) => {
    const { setProduct } = productStore;
    const { product } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handlePreviewClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setProduct(product);
        setModalIsOpen(true);
    };

    const handleClose = () => setModalIsOpen(false);

    const onProductClick = (product: Product) => {
        setProduct(product);

        navigate(`/product-details/${product._id}`);
    };

    const cardImageStyle: ICardImageStyle = {
        '--background-image': `url(${product?.images?.[0]})`,
    };

    return (
        <>
            <ProductModal handleClose={handleClose} isOpen={modalIsOpen} />
            <div className={`${productMiniCardStyle.productMiniCardContainer}`} onClick={() => onProductClick(product)}>
                {product.hot && (
                    <div className={productMiniCardStyle.hotProductSticker}>
                        <span>מוצר חם</span>
                    </div>
                )}
                <div className={`${productMiniCardStyle.productMiniCardImage} row`} style={cardImageStyle}>
                    {isBigScreen && (
                        <div className={productMiniCardStyle.productMiniButtonContainer}>
                            <button onClick={handlePreviewClick} className={productMiniCardStyle.productMiniButton}>
                                <span>{t('productMiniCard.quickView')}</span>
                            </button>
                        </div>
                    )}
                </div>
                <div className={`${productMiniCardStyle.productMiniCardProductDetailsContainer}`}>
                    <div className={`${productMiniCardStyle.productMiniCardProductDetailsRow} row`}>
                        <span className={productMiniCardStyle.productName}>{product.name}</span>
                    </div>
                    {!ClientConfig.isProvider && (
                        <div className={`${productMiniCardStyle.productMiniCardProductDetailsRow} row`}>
                            <span className={productMiniCardStyle.productPrice}>
                                {Currency.SHEKEL}
                                {product.updatedPrice.toLocaleString()}
                            </span>
                            {product.updatedPrice !== product.price && (
                                <span className={`${productMiniCardStyle.productPrice} ${productMiniCardStyle.strikePrice}`}>
                                    {Currency.SHEKEL}
                                    {product.price.toLocaleString()}
                                </span>
                            )}
                        </div>
                    )}
                </div>
                {!product.inStock && (
                    <div className={productMiniCardStyle.outOfStockSticker}>
                        <span>אזל מהמלאי</span>
                    </div>
                )}
            </div>
        </>
    );
});

export default ProductMiniCard;
