export const SECTIONS_STORE: string = 'SECTIONS_STORE';
export const FILTERS_STORE: string = 'FILTERS_STORE';
export const STORE_INFORMATION_STORE: string = 'STORE_INFORMATION_STORE';
export const AUTH_STORE: string = 'AUTH_STORE';
export const PRODUCT_STORE: string = 'PRODUCT_STORE';
export const PRODUCTS_STORE: string = 'PRODUCTS_STORE';
export const CART_STORE: string = 'CART_STORE';
export const USER_STORE: string = 'USER_STORE';
export const SYSTEM_STORE: string = 'SYSTEM_STORE';
export const ORDERS_STORE: string = 'ORDERS_STORE';
