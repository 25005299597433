import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import UserFetcher from '../../fetchers/User.fetcher';
import { IEditUserPayload, ISelectOption } from '../../interfaces/interfaces';
import UserModel from '../../models/user/User.model';
import ToastUtil from '../../utils/ToastUtils';
import { CErrors } from '../../constants/constants';

class UserStore {
    @observable
    isLoading: boolean = false;

    @observable
    user: UserModel = new UserModel();

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'UserStore',
            properties: ['user'],
            storage: window.localStorage,
        });
    }

    @action
    setUser = (user: UserModel) => (this.user = user);

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);

    @action
    addAddress = async (_user: UserModel, address: string, address2: string, city: string, zipCode: string) => {
        try {
            this.setIsLoading(true);
            const { data } = await UserFetcher.addAddress(_user, address, address2, city, zipCode);
            this.setUser({ ...data.user, shippingAddresses: data.user.shippingAddresses });

            ToastUtil.success(data.message);
        } catch (err: any) {
            console.error(err?.message);
            ToastUtil.error(err?.message);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateAddress = async (_user: UserModel, oldAddress: string, address: string, address2: string, city: string, zipCode: string) => {
        try {
            this.setIsLoading(true);
            const { data } = await UserFetcher.updateAddress(_user, oldAddress, address, address2, city, zipCode);
            this.setUser({ ...data.user, shippingAddresses: data.user.shippingAddresses });

            ToastUtil.success(data.message);
        } catch (err: any) {
            console.error(err?.message);
            ToastUtil.error(err?.message);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    deleteAddress = async (user: UserModel, address: string, city: string) => {
        try {
            this.setIsLoading(true);
            const { data } = await UserFetcher.deleteAddress(user, address, city);
            this.setUser({ ...data.user, shippingAddresses: data.user.shippingAddresses });

            ToastUtil.success(data.message);
        } catch (err: any) {
            console.error(err?.message);
            ToastUtil.error(err?.message);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get getUserAddressISelecet(): ISelectOption[] {
        return this.user.shippingAddresses
            .map((address, i) => ({
                key: i + 1,
                label: `${address.address}, ${address.city}, ${address.zipCode}`,
            }))
            .concat({
                key: -1,
                label: `הוסף כתובת חדשה`,
            });
    }

    @action
    updateUserDetails = async (payload: IEditUserPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await UserFetcher.updateUserDetails(payload);
            this.setUser(data.user);
            ToastUtil.success(data.message);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default UserStore;
